import { getCookie } from "../../Utils/commonUtils";
import strapiUrls from "./urls";

import store from "../../store";
import actions from "../../Actions";

async function fetchAPIData(url, method, headers, body) {
  try {
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

// this func is responsible to create strapi token
export const createStrapiToken = async () => {
  const loginData = {
    identifier: process.env.REACT_APP_STRP_PUB,
    password: process.env.REACT_APP_STRP_PRI,
  };
  const headersPost = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const login = await fetchAPIData(
    strapiUrls.STRAPI_CREATE_TOKEN,
    "POST",
    headersPost,
    JSON.stringify(loginData)
  );
  const loginResponseData = await login.json();
  document.cookie = `strapiToken=${loginResponseData?.jwt}; path=/`;
};

const handleFetchError = async (error, path, fetchStrapiDataFunc) => {
  const errorCode = JSON.parse(error?.message)?.errStatus;
  store.dispatch(
    actions.ErrorAction(`strapi-${errorCode}`, {
      status: errorCode,
    })
  );
  if (errorCode === 401) {
    document.cookie = "strapiToken=null; path=/";
    await createStrapiToken();
    return fetchStrapiDataFunc(path);
  }
  if (errorCode === 500) {
    const errorState = {
      name: "strapi",
    };
    return Promise.reject(errorState);
  }
  return {};
};

const structureStrapiData = (data) => {
  if (data === null) {
    return {};
  }

  if (Array.isArray(data)) {
    const [{ attributes }] = data;
    return attributes;
  }

  const { attributes } = data;
  return attributes;
};

const handleFetchStrapiData = async (path) => {
  try {
    const strapiToken = getCookie("strapiToken");
    const headersGet = {
      Authorization: `Bearer ${strapiToken}`,
    };
    const strapiData = await fetchAPIData(path, "GET", headersGet, null);
    store.dispatch(actions.ErrorAction("", { status: "", errorMessage: "Success" }));
    if (!strapiData?.ok) {
      throw strapiData?.status;
    }
    const { data } = await strapiData.json();

    /**
     * checking if data came from strapi is null or not
     * in case of null, default value can't be assigned
     * to a variable, because of doing below code.
     */

    return structureStrapiData(data);
  } catch (err) {
    throw new Error(JSON.stringify({ errStatus: err }));
  }
};

const fetchStrapiData = async (path) => {
  try {
    return await handleFetchStrapiData(path);
  } catch (error) {
    return handleFetchError(error, path, fetchStrapiData);
  }
};

export default fetchStrapiData;
