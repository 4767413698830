import React from "react";
import "./style.scss";
import {
  numberWithCommasandDecimal,
  numberWithCommas,
  getCurrencySymbol,
} from "../../../../Utils/commonUtils";
import {
  filterZeroData,
  heatMapColorGeneratorUtility,
  getHeaderContent,
  tableData,
} from "./utilHTML";

export default function SingleLevelTable(props) {
  const {
    headings,
    footer,
    data,
    sortHandler,
    sortedIcon = true,
    usageFooter,
    GraphQuantity = "",
    message,
    heatMap,
    currencyRequired = true,
    showMinColumns = false,
    extraMinCells = "",
    className = "",
    selectedDropdownValue = "",
    lensVersion = false,
  } = props;

  const heatMapColorGenerator = (value = 0) => {
    const {
      heatMap: { minValue, maxValue },
    } = props;

    return heatMapColorGeneratorUtility(minValue, maxValue, value);
  };

  const getHeatMapStyle = (itemKey, row) => {
    if (!heatMap) {
      return {};
    }

    if (["hour"].includes(itemKey)) {
      return {};
    }

    if (typeof row[itemKey] === "undefined") {
      return {
        backgroundColor: "#DADADA",
        color: "#828282",
      };
    }

    return heatMapColorGenerator(row[itemKey]);
  };

  return (
    <div className={`table_wrapper cost_explorer ${className} ${lensVersion ? "lens_table" : ""}`}>
      {data?.length ? (
        <table>
          <thead>
            {headings &&
              headings.map((heading) =>
                showMinColumns && !heading.key ? (
                  <th key={heading}>{heading}</th>
                ) : (
                  <th className={`select-none ${heading?.headerClass || "!bg-[#f8f8f8]"}`}>
                    {getHeaderContent({ sortHandler, heading, data, GraphQuantity, sortedIcon })}
                  </th>
                )
              )}
          </thead>
          <tbody>
            {!!usageFooter && selectedDropdownValue !== "quantity" && (
              <tr className="usageFooter">
                {!!footer &&
                  headings &&
                  headings.map((heading) => (
                    <td>
                      {typeof footer[heading.key] !== "number"
                        ? "Total Cost"
                        : `${getCurrencySymbol()}${numberWithCommas(
                            footer[heading.key].toFixed(2)
                          )}`}
                    </td>
                  ))}
              </tr>
            )}
            {!!usageFooter && selectedDropdownValue !== "cost" && (
              <tr className="usageFooter">
                {headings &&
                  headings.map((heading) => (
                    <td>
                      {heading.key === "groupValue" ? (
                        <p>Total Usage</p>
                      ) : (
                        `${numberWithCommas(
                          usageFooter[heading.key] ? usageFooter[heading.key].toFixed(2) : "0.00"
                        )} ${GraphQuantity}`
                      )}
                    </td>
                  ))}
              </tr>
            )}
            {!!data &&
              filterZeroData(data).map((row) => (
                <tr>
                  {headings.map((item, index) => {
                    const rowItem = tableData(row, item, item.currencyRequired ?? currencyRequired);
                    return showMinColumns && !item.key ? (
                      <td key={item} className={extraMinCells}>
                        {item}
                      </td>
                    ) : (
                      <td
                        key={item?.key}
                        id={`${item?.key}`}
                        className={item?.bodyClass || ""}
                        style={getHeatMapStyle(item?.key, row)}
                        title={index === 0 ? rowItem : ""}
                      >
                        {rowItem}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
          {!usageFooter && (
            <tfoot className="explorer_footer">
              {!!footer &&
                headings &&
                headings.map((heading) => (
                  <td className={heading?.footerClass || ""}>
                    {showMinColumns && !heading?.key
                      ? heading
                      : numberWithCommasandDecimal(
                          footer[heading?.key],
                          2,
                          heading.currencyRequired ?? currencyRequired
                        )}
                  </td>
                ))}
            </tfoot>
          )}
        </table>
      ) : (
        <div>
          <h5 className="no_data">{message}</h5>
        </div>
      )}
    </div>
  );
}
