import React from "react";
import { Tooltip } from "@mui/material";
import "./multiHeaderFormStyles.scss";
import InfoBox from "../../../CK-Lens/Common/InfoBox";

function MultiHeaderForm(props) {
  const {
    formElement,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    hideSeprator = true,
    infoIconEnabled = false,
    infoIcon = {},
  } = props;

  const { showFieldset = false, legendText = "" } = formElement || {};

  const formContent = (
    <div className={`!w-full header-component-div ${formElement?.wrapperClass}`}>
      {formElement?.heading?.name && (
        <div className="header-comp-div px-[12px] py-[9px]">
          <div className="flex items-center">
            <div className={formElement?.heading?.class}>
              <h3>{formElement?.heading?.name}</h3>

              {infoIconEnabled ? <InfoBox message={infoIcon.text} /> : null}
            </div>
            <div className="!pl-[15px]">
              <Tooltip
                className="multi-header-tooltip-class"
                title={formElement?.heading?.tooltip?.text}
                arrow
                placement="bottom"
              >
                {formElement?.heading?.tooltip?.icon}
              </Tooltip>
            </div>
          </div>
          {formElement?.heading?.description && (
            <div className={formElement?.heading?.descriptionClass}>
              {formElement?.heading?.description}
            </div>
          )}
        </div>
      )}
      <div className="!py-[10px] !px-[12px] form_ele_wrapper">
        {formElement?.config?.map((item) => {
          if (item.hidden) return null;

          return (
            <>
              <item.component
                key={item.name}
                {...item}
                value={values[item.name]}
                handleChange={(handleParams) => handleChange(handleParams, item)}
                error={errors[item.name]}
                touched={touched[item.name]}
                onBlurHandler={(blurParams) => handleBlur(blurParams, item)}
                {...(item.type === "checkbox" ? { checked: values[item.name] } : {})}
              />
              {item?.description?.name && (
                <div className={item?.description?.class}>{item?.description?.name}</div>
              )}
              {hideSeprator &&
                formElement?.config?.length > 1 &&
                formElement?.config[formElement.config.length - 1]?.name !== item?.name &&
                !item?.hideBottomBorder && <div className="form-border-seperator" />}
            </>
          );
        })}
      </div>
    </div>
  );

  return showFieldset ? (
    <fieldset>
      <legend>{legendText}</legend>
      {formContent}
    </fieldset>
  ) : (
    formContent
  );
}

export default MultiHeaderForm;
