import React from "react";
import { Link } from "react-router-dom";
import { TabV2, TabsV2 } from "../../../../Common/TabsV2";
import "./style.scss";

export default function TabWrapper(props) {
  const {
    isLoading,
    tabs,
    tabClicked,
    selected,
    pathName,
    openFrom,
    tooltip = [],
    isRoundedCorner = false,
    lensVersion = false,
  } = props;
  const tabList = tabs.map((item) => item);
  const data = new URLSearchParams(window.location.search);
  const query = `month=${data.get("month")}&year=${data.get("year")}`;
  return (
    <TabsV2 disabled={isLoading} isRoundedCorner={isRoundedCorner} lensVersion={lensVersion}>
      {tabs.map((item, index) => (
        <Link
          to={{
            pathname: pathName,
            search: openFrom === "reservation" ? `selected=${item}` : `${query}&selected=${item}`,
          }}
        >
          <TabV2
            onClick={(e) => {
              tabClicked(item, index, e);
            }}
            selectedTab={selected}
            tab={item}
            index={index}
            tabs={tabList}
            customClass="!h-[36px] flex items-center"
            tooltip={tooltip?.length ? tooltip?.[index] || "" : ""}
          >
            <div className="flex !h-[36px]!p-[9px 14px] justify-center items-center gap-2">
              <div className="">{item}</div>
            </div>
          </TabV2>
        </Link>
      ))}
    </TabsV2>
  );
}

/** 
 * this is the sample usage of this component for costbreakup dashboards
 <TabWrapper
        isLoading={isLoading}
        tabs={tabs}
        tabClicked={this.tabClicked}
        pathName={Routes.COST_BREAKUP_DATA_TRANSFER}
        selected={data.get("selected")}
/> 
*/
