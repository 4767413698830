import React from "react";
import "./style.scss";

function PublicFooter({
  show,
  leftText = false,
  allRightsFooter,
  isCkAuto,
  strapiData,
  isAssessment,
}) {
  return (
    show && (
      <div
        className={`login-footer ${!leftText && !isAssessment ? "single-content" : ""} ${
          isCkAuto ? "auto-module-footer" : "non-auto-module-footer"
        } ${isAssessment ? "!absolute !bg-[#F5F5F5] newUI-footer-bg" : ""}`}
      >
        {(leftText || isAssessment) && (
          <div className="login-left">
            {strapiData?.pretext} <a href={strapiData?.href}>{strapiData?.hypertext}</a>
          </div>
        )}
        <div className="login-right">{allRightsFooter}</div>
      </div>
    )
  );
}

export default PublicFooter;
