import { useEffect, useState } from "react";
import moment from "moment";
import commonService from "../../../../../Services/commonService";
import getEventHistoryHeader from "../config/tableHeaders";
import { filterArrayOfObjects } from "../../../../Dashboard/ConfigDiscounts/util";
import { setIndexDbColumnSelector } from "../../../../Billdesk/ExtraDiscount/Service/helper";

const Eventhistory = (searchTerm) => {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState({
    headers: [...getEventHistoryHeader()],
    data: [],
  });
  const [listSearched, setListSearched] = useState({
    headers: [...getEventHistoryHeader()],
    data: [],
  });
  const [selectedTab, setSelectedTab] = useState("Scheduler");

  const onDateChangeHandler = async (start, end) => {
    try {
      setIsLoading(true);
      const startDate = new Date(moment(start).startOf("day")).getTime();
      const endDate = new Date(moment(end).endOf("day")).getTime();
      const eventHistory = await commonService.tuner.getEventHistory(startDate, endDate);
      setList({ ...list, data: eventHistory?.data?.data });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerTypeHistory = async () => {
    try {
      setIsLoading(true);
      const defaultMaxDate = moment().endOf("day");

      const eventHistory = await commonService.tuner.getEventHistory(
        new Date(moment(moment(defaultMaxDate)).subtract(1, "days").startOf("day")).getTime(),
        new Date(moment(defaultMaxDate)).getTime()
      );
      setList({ ...list, data: eventHistory?.data?.data });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIndexDbColumnSelector("event-history", [...getEventHistoryHeader()]);
    fetchCustomerTypeHistory();
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearchHandler = debounce(() => {
    const updatedList = { ...list };
    const headers = updatedList?.headers?.map((item) => item.accessor);
    updatedList.data = filterArrayOfObjects(updatedList?.data, searchTerm, headers);
    setListSearched({ ...updatedList });
  }, 1);

  useEffect(() => {
    debouncedSearchHandler();
  }, [searchTerm]);

  const tabClicked = (e, item) => {
    setSelectedTab(item);
  };

  return { isLoading, list, onDateChangeHandler, listSearched, tabClicked, selectedTab };
};

export default Eventhistory;
