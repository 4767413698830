const tableConfiguration = () => ({
  leftConfig: [
    {
      type: "columnManager",
      config: {
        columnSelectorKey: "event-history",
        columnSelector: true,
      },
    },
    {
      type: "reset",
      showReset: true,
      class: "reset_btn_table",
    },
  ],
  //  rightConfig: [
  //    {
  //      type: "downloadButton",
  //      url: downloadUrl,
  //      class: "!static",
  //    },
  //  ],
});

export default tableConfiguration;
