import React from "react";
import DropDown from "../DropDown";

export default function MultipleDropdown(props) {
  const { options, label, labelClass, handleChange, value, className = "", name = "" } = props;

  const removeElement = (obj) => {
    delete value?.[obj?.key];
    return value;
  };

  const onDroupdownChange = (updatedValue, item) => {
    const updatedTargetValue = updatedValue?.value
      ? { ...value, [item?.key]: updatedValue }
      : removeElement(item);

    handleChange({
      target: {
        value: {
          ...updatedTargetValue,
        },
        name,
      },
      eventType: "change",
    });
  };

  return (
    <div className={`${className} flex flex-col`}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-wrap justify-between border border-solid border-[#DBDBDB] rounded-[5px] px-[12px] py-[7px] gap-[10px]">
        {options?.map((item) => (
          <div className="flex items-center w-[calc(50%-6px)] gap-[5px]">
            <label className="font-semibold text-[12px] !font-[Inter] text-[#2B2B2B] whitespace-nowrap">
              {item?.label}
            </label>
            <DropDown
              options={item?.list}
              value={value?.[item?.key] || item?.list?.[0]}
              handleChange={(e) => onDroupdownChange(e?.target?.value, item)}
              name={item?.key}
              className="multiple-dropdown-component"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
