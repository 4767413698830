import React, { useState } from "react";
import "./style.scss";
import Header from "../HomePage/Header";
import PageTitle from "../../../Common/PageTitle";
import { isTunerIndirectCustomer } from "../../../../Utils/tunerUtils";
import FilterTable from "../../../Common/Table/filterTable";
import tableConfiguration from "./config/tableConfiguration";
import Eventhistory from "./hooks/eventHistory";
import Loader from "../../../Common/Loader";
import eventHistoryTabs from "./config/TabsConfig";
import TabsWrapper from "../../../Finops/Monthly-Reports/Tabs";
import { ReactComponent as Search } from "../../../../Assets/icons/search.svg";

function EventHistory({ displayName }) {
  const [searchTerm, setSearchTerm] = useState("");
  const { list, isLoading, onDateChangeHandler, listSearched, tabClicked, selectedTab } =
    Eventhistory(searchTerm);
  return (
    <div
      className={`tuner-specific-css tuner-event-history-wrapper ck-tuner-accounts${
        isTunerIndirectCustomer() ? " lens" : ""
      }`}
    >
      <PageTitle pageTitle={displayName} />
      <Header
        headerName={displayName}
        breadcrumsData={["Event History"]}
        isLoading={isLoading}
        onDateChangeHandler={onDateChangeHandler}
      />
      {isLoading ? (
        <Loader popup />
      ) : (
        <div className="tuner-content-wrapper">
          <TabsWrapper
            isLoading={isLoading}
            tabs={eventHistoryTabs}
            selected={selectedTab}
            wrapperClass="event_history_search"
            tabClicked={tabClicked}
          >
            <div className="search-bar flex items-center justify-between border mx-4 mt-0 py-1.5 rounded text-[10px] text-[#939393] px-2.5 border-[#DDDFE8] border-solid bg-white !mt-0 !mx-0 w-[350px] !py-2  ">
              <input
                className="prompt w-full border-none mb-[0px] "
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <Search />
            </div>
          </TabsWrapper>
          <FilterTable
            config={searchTerm?.length ? listSearched : list}
            tableStyle="!border-0"
            filterTableConfiguration={tableConfiguration("")}
            showReset={false}
            V3
            V2
            dntShowSelector
            columnSelector
            columnSelectorKey="event-history"
            maxTableHeight="calc(100vh - 335px)"
            tanstackVirtulization
          />
        </div>
      )}
    </div>
  );
}

export default EventHistory;
