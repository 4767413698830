import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import RefreshIcon from "@mui/icons-material/Refresh";

import "./dateRangePickerV2.scss";
import moment from "moment";
import Form from "../../../FormConfig/Form";
import calendarIcon from "../../../../Assets/icons/calendar1.svg";
import { startDateValidations, endDateValidations } from "../../../FormConfig";
import { getToast } from "../../../../Utils/commonUtils";

// eslint-disable-next-line max-lines-per-function
function DateRangePick(props) {
  const [toggle, setToggle] = useState(true);
  const [close, setClose] = useState(true);
  const {
    startDate,
    endDate,
    maxDate,
    minDate,
    datePickerMaxDate,
    selectedRange,
    onDateChange,
    resetSelectedDateRange,
    handleEventHandler,
    givenRange = null,
    isStartValidationChange = null,
    dateFormat = "MM/DD/YYYY",
    V2,
    disabled = false,
  } = props;

  const [inputStartDate, setInputStartDate] = useState(moment(startDate).format(dateFormat));
  const [inputEndDate, setInputEndDate] = useState(moment(endDate).format(dateFormat));
  const [isInputClicked, setIsInputClicked] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [choseLabel, setChoseLabel] = useState("");
  const customEndDateTernary = () => {
    if (
      moment(maxDate).subtract(2, "days").get("month") === moment(minDate).get("month") &&
      moment(maxDate).subtract(2, "days").get("year") === moment(minDate).get("year")
    ) {
      if (moment(maxDate).diff(moment(minDate), "days") < 2) {
        return moment(maxDate);
      }
      return moment(maxDate).subtract(2, "days");
    }
    return moment(maxDate).subtract(1, "months").endOf("month");
  };
  const customEndDate = customEndDateTernary();
  const dateHandler = (e) => {
    if (e.target.value !== "") {
      if (e?.target?.name === "startDate") {
        setInputStartDate(e?.target?.value);
        setIsInputClicked(true);
      }
      if (e?.target?.name === "endDate") {
        setInputEndDate(e?.target?.value);
        setIsInputClicked(true);
      }
    }
  };

  const range = {
    "Select Range": "",
    // "Last 1 Day": [moment(moment(datePickerMaxDate)), moment(moment(datePickerMaxDate))],
    "7D": [
      moment(moment(datePickerMaxDate)).subtract(6, "days"),
      moment(moment(datePickerMaxDate)),
    ],
    // "Last 14 Days": [
    //   moment(moment(datePickerMaxDate)).subtract(13, "days"),
    //   moment(moment(datePickerMaxDate)),
    // ],
    "3M": [moment(customEndDate).startOf("month").subtract(2, "months"), moment(customEndDate)],
    "6M": [
      moment(customEndDate).startOf("month").subtract(5, "months"),
      moment(customEndDate).endOf("month"),
    ],
    "1Y": [moment(customEndDate).subtract(1, "year").startOf("month"), moment(customEndDate)],
    MTD: [moment(datePickerMaxDate).startOf("month"), moment(datePickerMaxDate)],
    YTD: [moment().startOf("year"), moment(datePickerMaxDate)],
  };

  const getChosenLabel = () => {
    if (isStartValidationChange) {
      return "30D";
    }
    if (moment(inputEndDate).isSame(moment(datePickerMaxDate).format(dateFormat))) {
      if (
        moment(inputStartDate).isSame(
          moment(datePickerMaxDate).subtract(6, "days").format(dateFormat)
        )
      ) {
        return "7D";
      }
      if (
        moment(inputStartDate).isSame(moment(datePickerMaxDate).startOf("month").format(dateFormat))
      ) {
        return "MTD";
      }
      if (moment(inputStartDate).isSame(moment().startOf("year").format(dateFormat))) {
        return "YTD";
      }
      return "Custom";
    }
    if (moment(inputEndDate).isSame(moment(customEndDate).format(dateFormat))) {
      if (
        moment(inputStartDate).isSame(
          moment(customEndDate).startOf("month").subtract(2, "months").format(dateFormat)
        )
      ) {
        return "3M";
      }
      if (
        moment(inputStartDate).isSame(
          moment(customEndDate).startOf("month").subtract(5, "months").format(dateFormat)
        )
      ) {
        return "6M";
      }
      if (
        moment(inputStartDate).isSame(
          moment(customEndDate).subtract(1, "year").startOf("month").format(dateFormat)
        )
      ) {
        return "1Y";
      }
      return "Custom";
    }
    return "Custom";
  };

  const customLabelSelection = (event, picker) => {
    setChoseLabel(picker?.chosenLabel);
    if (event?.type === "show") {
      setClose(true);
    }
    setToggle(!toggle);
    const targetElement = document.querySelectorAll(".ranges ul li");
    for (let i = 0; i < targetElement.length; i += 1) {
      if (targetElement[i].getAttribute("data-range-key") === selectedRange) {
        targetElement[i].setAttribute("id", "customActive");
      } else {
        targetElement[i].removeAttribute("id", "customActive");
      }
    }

    if (selectedRange === "MTD") {
      if (moment(minDate).isBefore(moment(maxDate), "month")) {
        document?.getElementsByClassName(" prev available")[1]?.click();
        document?.getElementsByClassName(" prev available")[0]?.click();
      }
    }
    if (selectedRange === "7D") {
      if (moment(minDate).isBefore(moment(datePickerMaxDate), "month")) {
        const maxDateMinus7Days = moment(datePickerMaxDate).subtract(6, "days");
        if (!maxDateMinus7Days.isBefore(moment(datePickerMaxDate), "month")) {
          document?.getElementsByClassName(" prev available")[1]?.click();
          document?.getElementsByClassName(" prev available")[0]?.click();
        }
      }
    }
  };

  const closeDatepicker = () => {
    setClose(!close);
    if (!close) document.getElementsByClassName("cancelBtn")[0].click();
  };

  const onCancelHandler = () => {
    setClose(!close);
  };
  const handleClearSelectionEndDate = () => {
    const accountStartDate = new Date(minDate);
    if (accountStartDate.getDate() === 1) {
      return moment(maxDate);
    }
    if (
      moment(maxDate).subtract(2, "days").get("month") === moment(minDate).get("month") &&
      moment(maxDate).subtract(2, "days").get("year") === moment(minDate).get("year")
    ) {
      if (moment(maxDate).diff(moment(minDate), "days") < 2) {
        return moment(maxDate);
      }
      return moment(maxDate).subtract(2, "days");
    }
    return moment(maxDate).subtract(1, "months").endOf("month");
  };

  const handleClearSelection = () => {
    document.getElementsByClassName("cancelBtn")[0].click();
    const start =
      moment(minDate).diff(moment().subtract(1, "months").startOf("month"), "days") > 0
        ? moment(minDate)
        : moment(maxDate).subtract(1, "months").startOf("month");
    const end = handleClearSelectionEndDate();
    onDateChange(start, end, true);
    resetSelectedDateRange();
  };
  const getMsgOfToast = (start, end, msg) => {
    let toastMsg = msg;
    if (start !== "" && end !== "") {
      toastMsg = "Start Date and End Date are Invalid";
    } else if (start !== "") {
      toastMsg = "Start Date is Invalid";
    } else if (end !== "") {
      toastMsg = "End Date is Invalid";
    }
    return toastMsg;
  };

  const checkInputDateIsValid = () => {
    let start = "";
    let end = "";
    let toastMsg = "";
    let updatedInputEndDate = inputEndDate;
    if (
      (moment(inputStartDate).isBefore(moment(minDate).format(dateFormat)) ||
        moment(inputStartDate).isAfter(moment(datePickerMaxDate).format(dateFormat))) &&
      !moment(inputStartDate).isSame(moment(minDate).format(dateFormat))
    ) {
      start =
        moment(minDate).diff(moment().subtract(1, "months").startOf("month"), "days") > 0
          ? moment(minDate)
          : moment(maxDate).subtract(1, "months").startOf("month");
    }
    if (
      (moment(inputEndDate).isBefore(moment(minDate).format(dateFormat)) ||
        moment(inputEndDate).isAfter(moment(datePickerMaxDate).format(dateFormat))) &&
      !moment(inputEndDate).isSame(moment(datePickerMaxDate).format(dateFormat))
    ) {
      end = moment(datePickerMaxDate);
    }

    if (moment(inputEndDate).isBefore(moment(inputStartDate).format(dateFormat))) {
      setInputEndDate(moment(datePickerMaxDate));
      updatedInputEndDate = moment(datePickerMaxDate);
      toastMsg = "End Date should always be greater than Start Date";
    }

    toastMsg = getMsgOfToast(start, end, toastMsg);

    if (toastMsg !== "") {
      getToast("error", toastMsg);
    }

    return {
      startDate: isStartValidationChange || start || inputStartDate,
      endDate: end || updatedInputEndDate,
    };
  };

  const handleEvent = (event, picker) => {
    if (!inputError) {
      customLabelSelection();
      if (isInputClicked && choseLabel === picker?.chosenLabel) {
        const validDates = checkInputDateIsValid();
        const chosenLabel = getChosenLabel();
        handleEventHandler(chosenLabel);
        onDateChange(validDates?.startDate, validDates?.endDate);
      } else {
        handleEventHandler(picker?.chosenLabel);
        onDateChange(picker?.startDate?._d, picker?.endDate?._d, picker?.chosenLabel);
        setChoseLabel(picker?.chosenLabel);
      }
      setIsInputClicked(false);
    } else if (inputError && !isInputClicked) {
      customLabelSelection();
      handleEventHandler(picker?.chosenLabel);
      onDateChange(picker?.startDate?._d, picker?.endDate?._d);
      setChoseLabel(picker?.chosenLabel);
      setIsInputClicked(false);
    } else getToast("error", "Please Enter valid date");
  };

  const inputErrorHandler = (value) => {
    setInputError(value);
  };
  const onEventHandler = () => {
    setIsInputClicked(false);
  };

  const start1 = moment(minDate).startOf("month").format(dateFormat);
  const end1 = moment(datePickerMaxDate).startOf("month").format(dateFormat);
  const linkedCondition = moment(start1).isSame(moment(end1));
  return (
    <DateRangePicker
      initialSettings={{
        startDate: moment(startDate).format(dateFormat),
        endDate: moment(endDate).format(dateFormat),
        showDropdowns: !linkedCondition,
        opens: "left",
        locale: {
          daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          format: dateFormat,
        },
        ranges: givenRange || range,
        minDate: moment(minDate),
        maxDate: moment(datePickerMaxDate),
        alwaysShowCalendars: true,
        linkedCalendars: linkedCondition,
        parentEl: ".utilization_banner_left_heading",
      }}
      key={startDate}
      onApply={handleEvent}
      onCancel={onCancelHandler}
      onShow={customLabelSelection}
      onHide={customLabelSelection}
      onEvent={onEventHandler}
    >
      <button
        className="dateRange_pickerBtn"
        id={toggle ? "dateRangePicker_Button" : ""}
        type="button"
        disabled={disabled}
      >
        <div className="dateText" onClick={closeDatepicker} aria-hidden="true">
          {moment(startDate).format(dateFormat || "MMM DD, YYYY")} -{" "}
          {moment(endDate).format(dateFormat || "MMM DD, YYYY")}
        </div>
        <div
          onClick={() => handleClearSelection()}
          className={toggle ? "clear_selection" : "clear_selection show"}
          aria-hidden="true"
        >
          <p>Clear Selection</p>
          {!V2 && <RefreshIcon size="small" />}
        </div>
        {V2 && (
          <>
            <div
              className={
                toggle
                  ? "clear_selection calendar_heading"
                  : "clear_selection calendar_heading show"
              }
            >
              <p>Calendar</p>
            </div>
            <div className={`drp-inputForm ${toggle ? "" : "show"}`}>
              <Form
                formConfig={[startDateValidations, endDateValidations]}
                handleSubmit={dateHandler}
                isError={(value) => inputErrorHandler(value)}
                formValues={{
                  startDate: inputStartDate,
                  endDate: inputEndDate,
                }}
                onChangeHandler={dateHandler}
              />
            </div>
          </>
        )}
        <label onClick={closeDatepicker} aria-hidden="true" className="addOn">
          <img src={calendarIcon} alt="Calendar Icon" />
        </label>
      </button>
    </DateRangePicker>
  );
}

export default DateRangePick;
