/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";

function DynamicFieldGroups(props) {
  const { config, handleChange, name, value, wrapperClass, titleConfig } = props;
  const { formTitle, formDivClass } = titleConfig;

  const [isLoading, setIsLoading] = useState(true);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (!config || !Array.isArray(config)) {
      setIsLoading(true);
      return;
    }

    if (value && Array.isArray(value) && value.length > 0) {
      setFormData(value);

      const initialFieldGroups = value.map((groupData, index) => ({
        id: index,
        fields: config?.map((field) => ({
          ...field,
          component: field?.component,
          ...cloneDeep(
            Object.fromEntries(Object.entries(field).filter(([key]) => key !== "component"))
          ),
        })),
      }));

      setFieldGroups(initialFieldGroups);
    } else {
      const defaultFieldGroups = [
        {
          id: 0,
          fields: config?.map((field) => ({
            ...field,
            component: field?.component,
            ...cloneDeep(
              Object.fromEntries(Object.entries(field).filter(([key]) => key !== "component"))
            ),
          })),
        },
      ];

      setFieldGroups(defaultFieldGroups);
      setFormData([{}]);
    }

    setIsLoading(false);
  }, [value, config]);

  const addFieldGroup = () => {
    if (!config || !Array.isArray(config)) return;

    const newId = Math.max(...fieldGroups.map((group) => group?.id), -1) + 1;

    const newFields = config.map((field) => ({
      ...field,
      component: field?.component,
      ...cloneDeep(
        Object.fromEntries(Object.entries(field).filter(([key]) => key !== "component"))
      ),
    }));

    setFieldGroups((prevGroups) => [...prevGroups, { id: newId, fields: newFields }]);

    setFormData((prevData) => [...prevData, {}]);
  };

  const removeFieldGroup = (groupId) => {
    if (fieldGroups.length > 1) {
      setFieldGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));

      setFormData((prevData) => {
        const newData = prevData?.filter((_, index) => index !== groupId);
        handleChange({ target: { name, value: newData } });
        return newData;
      });
    }
  };

  const onHandleChange = (param, item, groupId) => {
    const val = param?.target?.value;
    const fieldName = param?.target?.name;

    setFormData((prevData) => {
      const newData = prevData?.map((group, index) => {
        if (index === groupId) {
          let updatedGroup = {
            ...group,
            [fieldName]: val,
            current: true,
            targetItem: fieldName,
          };

          if (fieldName === item?.name && item?.deriveDependentFields) {
            updatedGroup = item?.deriveDependentFields(updatedGroup);
          }
          return updatedGroup;
        }

        return { ...group, current: false };
      });

      handleChange({ target: { name, value: newData } });

      return newData;
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin" />
          <p className="text-gray-600">Loading fields...</p>
        </div>
      </div>
    );
  }

  if (!config || !Array.isArray(config)) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className={`${formDivClass}`}>{formTitle}</div>
        <div>
          <button
            type="button"
            onClick={addFieldGroup}
            className="flex items-center px-4 py-2 bg-[#0A3CA2] text-white rounded-[3px] text-[12px]"
          >
            Add More
          </button>
        </div>
      </div>

      {fieldGroups?.map((group) => (
        <div
          key={group?.id}
          className={`relative border border-gray-200 rounded-lg p-4 mb-4 group-${group.id} ${wrapperClass}`}
        >
          <div className="flex flex-wrap gap-4 dynamic-field-group">
            {group?.fields?.map((item) => {
              if (!item?.component || typeof item?.component !== "function") {
                return null;
              }

              const Component = item?.component;

              return (
                <div key={`${item?.name}_${group?.id}`} className={item?.parentClass}>
                  {item?.showLabel && item?.label && (
                    <label
                      className={`block text-sm font-medium text-gray-700 mb-1 ${item?.labelClass}`}
                    >
                      {item?.label}
                    </label>
                  )}

                  {item?.topLabel && (
                    <label className={item?.topLabel?.class}>{item?.topLabel?.text}</label>
                  )}

                  <Component
                    {...item}
                    name={item?.name}
                    value={formData[group?.id]?.[item?.name] || ""}
                    handleChange={(handleParams) => onHandleChange(handleParams, item, group?.id)}
                  />
                </div>
              );
            })}
          </div>

          {fieldGroups?.length > 1 && (
            <button
              type="button"
              onClick={() => removeFieldGroup(group?.id)}
              className="absolute top-2 right-2 p-1 text-red-600 hover:text-red-800 transition-colors"
              title="Remove"
            >
              Remove
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default DynamicFieldGroups;
