import React from "react";
import "./style.scss";

function SummaryCards(props) {
  const { summaryCardsData = [], className = "", cardImageData = "" } = props;
  return (
    <div className={`ck-cudos-summary-cards-wrapper ${className}`}>
      {summaryCardsData?.map((data, index) =>
        cardImageData?.[index]?.backgroundImage !== "" ? (
          <div
            style={{
              backgroundImage: `url(${cardImageData?.[index]?.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="ck-cudos-summary-card"
          >
            {data?.value?.map((ele) => (
              <div className="m-[8px] mt-[12px] card-content">
                {cardImageData?.[index]?.icon}
                <div className="text-[12px] font-semibold text-[#0C477E] mt-[8px] mb-[0px] card-heading">
                  {ele.label}
                </div>
                <div className="text-[18px] font-normal text-[#000000] cost">
                  {ele?.value || "--"}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card-used-unused-view">
            <div className="card-label">{data?.label}</div>
            <div className="flex mx-[8px] mt-[14px] mb-[13px]">
              {data?.value?.map((ele, i) => (
                <div className={`used w-1/${data?.value?.length}`}>
                  <div
                    className={`inline-block text-[12px] mb-[8px] rounded-[2px] px-[5px] ${
                      cardImageData?.[index]?.colorClasses?.[i] || ""
                    }`}
                  >
                    {ele?.label}
                  </div>
                  <div className="text-[14px] text-[#0C477E] font-bold !font-[Inter]">
                    {ele?.value || "--"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default SummaryCards;
