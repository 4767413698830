import { subscribe } from "../../Utils/events";
import axiosInstance from "./AxiosInstance";

const tempCache = {};

const cacheChannel = new BroadcastChannel("clearAxiosCache");
cacheChannel.onmessage = (e) => {
  try {
    const message = JSON.parse(e.data);
    const { cacheKeys } = message;
    if (Array.isArray(cacheKeys) && cacheKeys.length) {
      const currentCacheKeys = Object.keys(tempCache);
      cacheKeys.forEach((key) => {
        currentCacheKeys.forEach((cacheKey) => {
          if (cacheKey.includes(key)) {
            delete tempCache[cacheKey];
          }
        });
      });
    }
  } catch (error) {
    console.error("cacheChannel.onmessage", error);
  }
};

const isCacheValid = (data, timer, burstCache) => {
  if (!data) return false;
  const cacheData = data;
  const currentTime = Date.now();

  if (burstCache) {
    return false;
  }

  const timeDifference = currentTime - cacheData.fetchTime;

  if (timeDifference > timer) {
    return false;
  }

  return true;
};

subscribe("clearAxiosCache", (e) => {
  const cacheChannelOnEvent = new BroadcastChannel("clearAxiosCache");
  cacheChannelOnEvent.postMessage(JSON.stringify(e.detail));
});

const fetchWithCache = (method, url, data, options, cache = {}) => {
  const fetchTime = Date.now();
  const { enable = false, timer = 0, burstCache = false, cacheKey = "" } = cache;
  const key = `${cacheKey}-${url}?${JSON.stringify(data || "")}${JSON.stringify(options || "")}`;

  const cached = tempCache[key];

  if (enable && cached && isCacheValid(cached, timer, burstCache)) {
    return Promise.resolve(cached?.response);
  }

  const axiosParams = method === "get" ? { params: data, ...options } : data;
  const axiosOptions = method === "get" ? undefined : options;

  return axiosInstance[method](url, axiosParams, axiosOptions).then((response) => {
    if (enable) {
      tempCache[key] = {
        response,
        fetchTime,
      };
    }
    return response;
  });
};

export const get = (url, queryParams, options, cache = {}) => {
  if (!Object.keys(cache).length) {
    return axiosInstance.get(url, { params: queryParams, ...options });
  }

  return fetchWithCache("get", url, queryParams, options, cache);
};

export const post = (url, body, params, cache = {}) => {
  if (!Object.keys(cache).length) {
    return axiosInstance.post(url, body, params);
  }

  return fetchWithCache("post", url, body, params, cache);
};

export const put = (url, body, queryParams) => axiosInstance.put(url, body, queryParams);

export const deleteAPI = (url, body, queryParams) => axiosInstance.delete(url, body, queryParams);

export const patch = (url, body, queryParams) => axiosInstance.patch(url, body, queryParams);
