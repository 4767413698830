import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import {
  getCurrencySymbol,
  noop,
  numberWithCommasandDecimal,
} from "../../../../../Utils/commonUtils";
import {
  ReturnTdClass,
  ReturnThClass,
  getIconHeader,
  renderFooter,
  renderNumber,
} from "../costbreakupUtils";
import {
  OnIncludeChange,
  applyFilters,
  cancelFilterHandler,
  createTableFiltersUpdated,
  disableHandling,
  handleFilters,
  onRangeChange,
} from "../../../../../Utils/filterUtils";
import {
  FilltersOnTable,
  MultiLevelTableFooter,
  TableHeader,
  footerV2Condition,
  TotalNumberFooter,
} from "./utils";
import TableFilter from "../../../../Common/FilterMultiselect/TableFilter";
import "./style.scss";

const renderNumberCell = (cell) => {
  if (typeof cell.value === "number") {
    return `${cell?.value?.toFixed(2)}`;
  }
  if (cell?.value?.includes(`${getCurrencySymbol()}-`)) {
    return numberWithCommasandDecimal(Number(cell.value.replace(/[,$]+/g, ""), 2));
  }
  if (cell?.column?.Cell) {
    return cell?.column?.Cell(cell);
  }
  return cell.value;
};

const renderData = (rows, prepareRow, stickyList, V2) =>
  rows.map((row, rowIndex) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell, index) => (
          <td
            {...cell.getCellProps()}
            className={ReturnTdClass(rowIndex, cell, index, stickyList, V2, rowIndex)}
          >
            {renderNumberCell(cell)}
          </td>
        ))}
      </tr>
    );
  });

const createFilters = (updatedData, setFilters, setFiltersCopy, setFilterSelected) => {
  const updatedFilters = createTableFiltersUpdated(updatedData);
  setFilters(updatedFilters);
  setFiltersCopy(updatedFilters);
  setFilterSelected(updatedFilters);
};

const cancelFilter = (selectedList, filterSelected, setFilterSelected, setFilters) => {
  const newFilterSelected = cancelFilterHandler(filterSelected, selectedList);
  setFilterSelected(newFilterSelected);
  setFilters(newFilterSelected);
};

// eslint-disable-next-line max-lines-per-function
export default function MultiLevelHeaderTable(props) {
  const {
    columns,
    data,
    footer,
    sortHandlerParent,
    target = 250,
    totalVisible = true,
    summaryTable,
    total,
    selectedSortHeader = "",
    ascending = false,
    stickyList = [],
    V2 = false,
    lastColumnSticky = false,
    customTableWrapperClass = "",
    onlyFirstColumnSticky = false,
    applyFiltersOnTable = false,
    appliedFilters,
    updateParentFilterHandler = noop,
    tableHeading = "",
    customClass = "",
    excelDownloadHandler = noop,
    excelLoading = false,
    filterToFixValue = 1,
    tableDataUpper = noop,
    tableDataCopy = [],
    setFilteredData = noop,
    mouseOverFilter = false,
    lensVersion = false,
  } = props;

  const [filters, setFilters] = useState([]);
  const [filtersCopy, setFiltersCopy] = useState([]);
  const [filterSelected, setFilterSelected] = useState([]);
  const [tableData, setTableData] = useState([]);

  const filterData = (arrayObj) => {
    const newData = applyFilters(
      tableDataCopy,
      filtersCopy,
      arrayObj || filterSelected,
      filterToFixValue
    );
    const updatedUserList = { ...data };
    updatedUserList.data = newData?.filteredData;
    setTableData(updatedUserList.data);
    setFilterSelected(newData?.filters);
    setFilters(newData?.filters);
    setFilteredData(updatedUserList.data);
    if (updateParentFilterHandler) {
      updateParentFilterHandler(newData?.filters);
    }
  };

  const onIncludeChange = (name, value) => {
    const includeChangeValue = OnIncludeChange(name, value, filterSelected, filters);
    setFilterSelected([...includeChangeValue]);
    if (updateParentFilterHandler) {
      updateParentFilterHandler([...includeChangeValue]);
    }
  };

  const onRangeChangeTable = (value, name) => {
    const arrayObj = onRangeChange(value, name, filterSelected);
    setFilterSelected(arrayObj);
    filterData(arrayObj);
  };

  useEffect(() => {
    if (applyFiltersOnTable) {
      createFilters(data, setFilters, setFiltersCopy, setFilterSelected);
      setTableData(data);
      if (appliedFilters?.length > 0) {
        const newData = applyFilters(data, appliedFilters, appliedFilters);
        const updatedUserList = { ...data };
        updatedUserList.data = newData?.filteredData;
        setTableData(updatedUserList.data);
        setFilterSelected(appliedFilters);
        setFilters(appliedFilters);
      }
    } else {
      setTableData(data);
    }
  }, [data, columns, applyFiltersOnTable]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable({ columns, data: tableData }, useSortBy);

  const resetButton = () => {
    setTableData(tableDataCopy);
    tableDataUpper(tableDataCopy);
    setFilteredData(tableDataCopy);
    createFilters(tableDataCopy, setFilters, setFiltersCopy, setFilterSelected);
    if (updateParentFilterHandler) {
      updateParentFilterHandler();
    }
  };

  const renderTableFilters = (column) => (
    <TableFilter
      filters={filters}
      heading={column?.id}
      filterType={column?.type}
      disableResetButton
      filterSelected={filterSelected}
      handleFilters={(value, name) => {
        const updatedFilterSelected = handleFilters(value, name, filterSelected, filters);
        setFilterSelected(updatedFilterSelected);
      }}
      onIncludeChange={(value, name) => {
        onIncludeChange(value, name);
      }}
      filterData={filterData}
      cancelFilter={(selectedList) => {
        cancelFilter(selectedList, filterSelected, setFilterSelected, setFilters);
      }}
      onRangeChange={(value, name) => {
        onRangeChangeTable(value, name);
      }}
      showSortIcon
      filterRefClass="filter_div"
    />
  );

  return (
    <div className={customClass}>
      <FilltersOnTable
        applyFiltersOnTable={applyFiltersOnTable}
        tableHeading={tableHeading}
        disableHandling={disableHandling}
        filterSelected={filterSelected}
        resetButton={resetButton}
        excelDownloadHandler={excelDownloadHandler}
        data={data}
        excelLoading={excelLoading}
      />
      <div
        className={`cost_breakup_wrapper  ${V2 ? "Striped-Table" : ""} ${
          lastColumnSticky ? "last-column-sticky" : ""
        } ${customTableWrapperClass || ""} ${onlyFirstColumnSticky && "only-first-column-sticky"} ${
          lensVersion ? "lens-plus-customer" : ""
        }`}
        id="cost_breakup_table"
      >
        {data?.length ? (
          <table {...getTableProps()} id="multilevel-headerTable">
            <TableHeader
              headerGroups={headerGroups}
              tableData={tableData}
              ReturnThClass={ReturnThClass}
              V2={V2}
              stickyList={stickyList}
              getIconHeader={getIconHeader}
              sortHandlerParent={sortHandlerParent}
              summaryTable={summaryTable}
              selectedSortHeader={selectedSortHeader}
              ascending={ascending}
              lastColumnSticky={lastColumnSticky}
              applyFiltersOnTable={applyFiltersOnTable}
              renderTableFilters={renderTableFilters}
              mouseOverFilter={mouseOverFilter}
            />
            <tbody {...getTableBodyProps()}>{renderData(rows, prepareRow, stickyList, V2)}</tbody>
            {footer && totalVisible ? (
              <TotalNumberFooter footer={footer} />
            ) : (
              <MultiLevelTableFooter
                lastColumnSticky={lastColumnSticky}
                totalVisible={totalVisible}
                footerGroups={footerGroups}
                tableData={tableData}
                target={target}
                rows={rows}
                renderFooter={renderFooter}
                total={total}
                footerV2Condition={footerV2Condition}
                renderNumber={renderNumber}
                V2={V2}
              />
            )}
          </table>
        ) : (
          <div className="no_table-data-found">No Data Available</div>
        )}
      </div>
    </div>
  );
}
