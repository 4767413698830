import React from "react";
import "./style.scss";
import { numberWithCommasandDecimal } from "../../../../../Dashboard/EdpDiscounts/util";

function MultiLevelHeaderTable(props) {
  const {
    sortHandlerParent,
    data,
    total: footer,
    columns: { headers = [], bodyHeaders = [] },
    noShadow = false,
  } = props;
  const sortHandler = (c) => {
    if (!c.sortingDisabled) {
      sortHandlerParent(c.accessor);
    }
  };
  const renderHeaders = () =>
    headers.map((column) => (
      <tr>
        {column.map((c) => (
          <th
            colSpan={c?.columns?.length || 1}
            rowSpan={c?.rowSpan || 1}
            className={`${c?.headerClass} `}
          >
            <span
              onClick={() => sortHandler(c)}
              aria-hidden
              className={`${!c?.sortingDisabled ? "cursor-pointer" : ""}`}
            >
              {c?.Header}
            </span>
          </th>
        ))}
      </tr>
    ));

  const renderBody = () =>
    data.map((row) => (
      <tr className="row">
        {bodyHeaders.map((header) => (
          <td
            data-unique={header.accessor + header.id}
            key={header.accessor + header.id}
            className={`text-left ${header?.headerClass}`}
          >
            {typeof row[header.accessor || ""] === "number" ? (
              <>
                {header.prefix || ""}
                {numberWithCommasandDecimal(
                  +row[header.accessor || ""],
                  header.decimalPlaces || 2,
                  false
                )}
              </>
            ) : (
              row[header.accessor || ""]
            )}
            {header.Cell && header.Cell(row)}
          </td>
        ))}
      </tr>
    ));
  const renderFooter = () => (
    <tr>
      {bodyHeaders.map((header, index) => (
        <td
          data-unique={header.accessor + header.id}
          key={header.accessor + header.id}
          className={`text-center ${header?.headerClass} ${
            footer[header?.accessor || ""] ? "left-border" : ""
          } `}
        >
          {index === 0 ? "Total" : (footer[header.accessor || ""] && header.prefix) || ""}
          {typeof footer[header.accessor || ""] === "number"
            ? numberWithCommasandDecimal(
                footer[header.accessor || ""],
                header.decimalPlaces || 2,
                false
              )
            : footer[header?.accessor || ""]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className="cudos-multi-level-table-container">
      <table>
        <thead>{renderHeaders()}</thead>
        <tbody className={`${noShadow ? "no-shadow" : ""}`}>{renderBody()}</tbody>
        <tfoot className={`${noShadow ? "no-shadow" : ""}`}>{renderFooter()}</tfoot>
      </table>
    </div>
  );
}

export default MultiLevelHeaderTable;
