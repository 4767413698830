const parseInitialValue = (value) => {
  try {
    if (!value) return "";
    if (typeof value === "string") {
      const parsed = JSON.parse(value);
      return JSON.stringify(parsed, null, 2);
    }

    return JSON.stringify(value, null, 2);
  } catch (e) {
    console.error("Error parsing initial value:", e);
    return "{}";
  }
};

export default parseInitialValue;
