// import moment from "moment";
import React from "react";
import moment from "moment";
import DatesMultiselect from "./datesMultiselect";
import NumberMultiselect from "./numberMultiSelect";
import MultipleSelect from ".";

function TableFilter(props) {
  const {
    filters,
    heading,
    disableResetButton,
    filterSelected,
    handleFilters,
    onIncludeChange,
    filterData,
    cancelFilter,
    onRangeChange,
    showSortIcon,
    filterRefClass = "filter_div",
    onDateChange,
    showClearFilterSeparately = false,
    viewportAwareDatePopover = false,
    tableRef,
    lensVersion = false,
  } = props;

  const stringTableFilters = (item) =>
    item?.values?.length > 1 && (
      <div
        id={filterRefClass}
        className={`${item?.value?.length ? "active_filter" : ""} ${
          showSortIcon ? "" : "hide-seperator"
        } `}
      >
        <MultipleSelect
          name={item.name}
          filterId={filterRefClass}
          filtersAvailable
          options={disableResetButton ? item.values || [] : item.values}
          changeHandlerFIlter={handleFilters}
          onIncludeChange={onIncludeChange}
          type={item.type}
          filterData={filterData}
          cancelFilter={cancelFilter}
          showClearFilterSeparately={showClearFilterSeparately}
          filterSelected={filterSelected.filter(
            (internalItem) =>
              (internalItem.name[0].toLowerCase() + internalItem.name.slice(1)).replace(" ", "") ===
              (item.name[0].toLowerCase() + item.name.slice(1)).replace(" ", "")
          )}
          tableRef={tableRef}
          lensVersion={lensVersion}
        />
      </div>
    );
  const dateTableFilters = (item) =>
    item.type === "dates" && (
      <div
        id="filter_div"
        className={
          !(
            moment(item.selectedDates[0]).isSame(moment(item.min)) &&
            moment(item.selectedDates[1]).isSame(moment(item.max))
          )
            ? "active_filter"
            : ""
        }
      >
        <DatesMultiselect
          name={item.name}
          type={item.type}
          max={item.max}
          min={item.min}
          selectedValue={item.selectedDates}
          onDateChange={onDateChange}
          viewportAwareDatePopover={viewportAwareDatePopover}
        />
      </div>
    );

  const activeFilterMinValue = (item) =>
    item.min ===
    filterSelected.filter((internalItem) => internalItem.name === item.name)?.[0]
      ?.selectedValue?.[0];

  const activeFilterMaxValue = (item) =>
    item.max ===
    filterSelected.filter((internalItem) => internalItem.name === item.name)?.[0]
      ?.selectedValue?.[1];

  const numberTableFilters = (item) => (
    <div
      id="filter_div"
      className={`${
        !(activeFilterMinValue(item) && activeFilterMaxValue(item)) ? "active_filter" : ""
      } ${showSortIcon ? "" : "hide-seperator"}`}
    >
      <NumberMultiselect
        name={item.name}
        type={item.type}
        value={
          filterSelected.filter((internalItem) => internalItem.name === item.name)?.[0]
            ?.selectedValue
        }
        max={item.max}
        min={item.min}
        onRangeChange={onRangeChange}
      />
    </div>
  );

  const tableFilters = () =>
    filters &&
    filters.map((item) => {
      if (item?.name?.toLowerCase() === heading?.toLowerCase()) {
        switch (item?.type) {
          case "string":
            return stringTableFilters(item);
          case "number":
            return numberTableFilters(item);
          case "dates":
            return dateTableFilters(item);
          default:
        }
      }
      return null;
    });

  return <>{tableFilters()}</>;
}

export default TableFilter;
