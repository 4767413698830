import { getModule } from "../Utils/commonUtils";
import { get, post, put, deleteAPI, patch } from "./axios/api";
import URLS from "./axios/urls";

const commonService = {
  handleLogin: (param, captchaToken) =>
    post(URLS.LOGIN, param, {
      headers: {
        "X-Captcha-Token": `${captchaToken}`,
      },
    }),
  handleLogout: (option) => get(URLS.LOGOUT, {}, option),
  handleResetPassword: (param) => post(URLS.RESET_PASSWORD, param),
  handleForgetPassword: (params) => get(URLS.FORGET_PASSWORD, params),
  handleSignup: (param) => post(URLS.SING_UP, param),
  handleResendEmailTimer: (params) => get(URLS.RESENDEMAILTIMER, params),
  handleResendEmail: (params) => get(URLS.RESENDEMAIL, params),

  getUserMetaDataDetails: (options) => get(URLS.USER_META_DATA_DETAIL, {}, options),
  getUserDashboard: (param) =>
    get(
      `${URLS.USER_DASHBOARD}${getModule(param?.moduleId, "moduleIds")}${getModule(
        param?.type,
        "restrictionType",
        "&"
      )}`
    ),
  confirmSignUp: (params) => get(URLS.CONFIRM_USER, params),
  confirmSignUpV2: (params) => post(URLS.CONFIRM_USER, params),
  getUserVerification: (param) => get(URLS.USER_VERIFICATION, param),
  getDashboardData: (params) => get(URLS.DASHBOARD_DATA.MASTER, params),
  getCurrentUserRole: (params) => get(URLS.ROLE.GET_CURRENT_ROLE, params),
  getRoleList: (params) => get(URLS.ROLE.GET_LIST, params),
  getPermissionsList: (params) => get(URLS.ROLE.GET_PERMISSION_LIST, params),
  getCustomerVerfication: (params) => get(URLS.CONFIRM_CUSTOMER, params),
  handleSwitchRole: (param, options) => post(URLS.SWITCH_ROLE, param, options),
  fetchUserMetaData: (id, params) => get(URLS.USER_META_DATA + id, params),
  fetchUserMetaDataWithParam: (params) => get(URLS.USER_META_DATA, params),
  getSwitchRoleList: (param, userType, options) =>
    get(URLS.GET_SWITCH_ROLE_DATA[userType], param, options),
  getSwitchRoleListCustomer: (param, option) =>
    get(URLS.GET_SWITCH_ROLE_DATA_CUSTOMER, param, option),
  getPartnerCustomerList: (param, options) => get(URLS.GET_PARTNER_CUSTOMER_LIST, param, options),
  getCurrentPartnerData: () => get(URLS.GET_CURRENT_PARTNER_DATA),
  getUserLIst: (params) => get(URLS.USER.GET_LIST, params),
  getUserListV2: (params) => get(URLS.USER.GET_LIST_V2, params),
  getUserLIstCloudonomic: (params) => get(URLS.USER.GET_LIST_CLOUDONOMIC, params),
  getCustomerUserList: (params) => get(URLS.USER.GET_CUSTOMER, params),
  createPartner: (payload) =>
    post(URLS.PARTNER.CREATE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  updatePartner: (payload) =>
    put(URLS.PARTNER.UPDATE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  deletePartner: (params) => deleteAPI(URLS.PARTNER.DELETE, params),
  createUser: (payload) => post(URLS.USER.CREATE, payload),
  updateUser: (payload) =>
    put(URLS.USER.UPDATE, payload, {
      headers: {
        "X-Access-Token": "add header",
      },
    }),
  toggleUser: (params) => get(URLS.USER.TOGGLE, params),
  reverifyUser: (params) => get(URLS.USER.REVERIFY, params),
  deleteUser: (params) => deleteAPI(URLS.USER.DELETE, params),
  promoteUser: (id) => put(`${URLS.USER.PROMOTE}?userId=${id}`),
  getPayerLIst: (params) => get(URLS.PAYER.GET_LIST, params),
  createPayer: (payload) => post(URLS.PAYER.CREATE, payload),
  updatePayer: (payload) => put(URLS.PAYER.UPDATE, payload),
  deletePayer: (params) => deleteAPI(URLS.PAYER.DELETE, params),
  fetchPartnerLogo: (payload) => get(URLS.PARTNER.FETCH_LOGO, payload),
  fetchPartnerFavIcon: (payload) => get(URLS.PARTNER.FETCH_FAV_ICON, payload),
  deleteCustomer: (params) => deleteAPI(URLS.CUSTOMER.DELETE, params),
  signAgreement: (params) => put(URLS.CUSTOMER.AGREEMENT, params),
  customerDataRefresh: (params) => post(URLS.CUSTOMER.DATAREFRESH, params),
  createCustomer: (payload) => post(URLS.CUSTOMER.CREATE, payload),
  updateCustomer: (payload) => put(URLS.CUSTOMER.UPDATE, payload),
  updateCustomerCkAuto: (payload) => put(URLS.CUSTOMER.UPDATE_CK_AUTO, payload),
  fetchAllCustomers: (params) => get(URLS.CUSTOMER.ALL, params),
  getAllCustomerAuto: (params) => get(URLS.CUSTOMER.DETAILS, params),
  getCustomerJourney: (params) => get(URLS.CUSTOMER.JOURNEY, params),
  fetchOrphanAccounts: (payload) => get(URLS.ACCOUNT.ORPHANS, payload),
  fetchAssociatedAccountIds: (payload) => get(URLS.ACCOUNT.ASSOCIATED, payload),
  deboardCustomer: (id) => deleteAPI(`${URLS.CUSTOMER.DEBOARD}?customerIds=${id}`),
  getComments: (payload) => post(URLS.CK_COMMENTS.FETCH_COMMENTS, payload),
  addComments: (payload) => post(URLS.CK_COMMENTS.ADD_COMMENTS, payload),
  getCommentsCount: (payload) => post(URLS.CK_COMMENTS.GET_COMMENTS_COUNT, payload),
  saveReportsSettings: (payload) =>
    post(URLS.REPORTS.SETTINGS.SAVE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),

  updateReportsSettings: (payload) =>
    put(URLS.REPORTS.SETTINGS.UPDATE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  fetchReportsSettings: (payload) => get(URLS.REPORTS.SETTINGS.GET, payload),
  generateInvoice: (payload) => post(URLS.REPORTS.SETTINGS.GENERATE_INVOICE, payload),
  deleteTemplate: (id) => deleteAPI(URLS.REPORTS.SETTINGS.DELETE + id),
  getInvoiceInvoiceRequests: () => get(URLS.REPORTS.SETTINGS.GETALL),
  getInvoiceRequestPreviewRowData: (taskId) =>
    get(URLS.REPORTS.SETTINGS.PREVIEW_INVOICE_REQUEST_ROW(taskId)),

  getInvoicePollingStatus: (payload) =>
    get(URLS.REPORTS.SETTINGS.POLLING_STATUS, payload, {
      responseType: "blob", // Important: Set response type to blob
    }),
  getInvoiceStatement: (taskId, options) =>
    get(`${URLS.REPORTS.SETTINGS.GENERATE_INVOICE_BY_TASKID}/${taskId}`, {}, options),

  //
  fetchLegalEntities: (payload) => get(URLS.LEGAL_ENTITY.GET, payload),
  fetchPartnerS3BaseUrl: (payload) => get(URLS.PARTNER.S3_BASE_URL, payload),
  fetchLogoForLoginPage: (payload) => get(URLS.PARTNER.FETCH_LOGO_LOGIN_PAGE, payload),
  fetchFabLogoLoginPage: (payload) => get(URLS.PARTNER.FETCH_FAB_LOGO_LOGIN_PAGE, payload),

  getDashboardList: (payload) => get(URLS.DASHBOARD_GRID.GET_DASHBOARDS, payload),
  getDashboardListV2: (payload) => get(URLS.DASHBOARD_GRID.GET_DASHBOARDS_V2, payload),

  getDashboardGrid: (userType, payload) => get(URLS.DASHBOARD_GRID.GET_GRID[[userType]], payload),
  saveUpdateDashboardGrid: (userType, payload, param) =>
    post(`${URLS.DASHBOARD_GRID.SAVE_UPDATE_GRID[userType]}?moduleId=${param}`, payload),

  // Module Grid APIs
  getModuleGrid: (userType) => get(URLS.MODULE_GRID.GET_GRID[[userType]]),
  getModuleList: (param, options) =>
    get(
      `${URLS.MODULE_GRID.GET_DASHBOARDS}${getModule(param?.type, "restrictionType")}${getModule(
        param?.moduleId,
        "moduleId",
        "&"
      )}`,
      {},
      options
    ),
  getEnabledModuleList: () => get(URLS.MODULE_GRID.GET_ENABLED_DASHBOARDS),
  saveUpdateModuleGrid: (userType, payload) =>
    post(URLS.MODULE_GRID.SAVE_UPDATE_GRID[userType], payload),

  fetchCustomerStatus: () => get(URLS.CUSTOMER_STATUS_SETTINGS.GET),
  createCustomerStatus: (payload) => post(URLS.CUSTOMER_STATUS_SETTINGS.CREATE, payload),
  deleteCustomerStatus: (payload) => deleteAPI(URLS.CUSTOMER_STATUS_SETTINGS.DELETE, payload),
  fetchPartnerSettings: (payload) => get(URLS.PARTNER_SETTINGS.GET, payload),
  createPartnerSettings: (payload) =>
    post(URLS.PARTNER_SETTINGS.CREATE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  updatePartnerSettings: (payload) =>
    put(URLS.PARTNER_SETTINGS.UPDATE, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  getAllSupportConfig: (params) => get(`${URLS.SUPPORT_CONFIGURATION.GET_ALL(params)}`),
  // getAllSupportConfig: () => get("https://run.mocky.io/v3/aa0ae20c-1dd2-4717-9c28-f679be05ed97"),
  updateSupportConfig: (payload) => put(URLS.SUPPORT_CONFIGURATION.UPDATE, payload),
  bulkUpdateSupportConfig: (payload) => put(URLS.SUPPORT_CONFIGURATION.BULK_UPDATE, payload),
  getAllCloudFrontPrivatePricing: (payload) =>
    get(URLS.Cloudfront_Private_Pricing.GET_ALL, payload),
  endCloudFrontPrivatePricingDiscount: (id, payload) =>
    put(`${URLS.Cloudfront_Private_Pricing.GET_ALL}/${id}`, payload),
  getPrivatePricingOnId: (id, payload) =>
    get(`${URLS.Cloudfront_Private_Pricing.GET_ALL}${id ? `/${id}` : ""}`, payload),

  getPrivatePricingbyId: (id, viewId, payload) =>
    get(
      `${URLS.Cloudfront_Private_Pricing.GET_ALL}${id ? `/${id}/view` : ""}${
        viewId ? `/${viewId}` : ""
      }`,
      payload
    ),

  getAWSRegionList: (payload) => get(URLS.Cloudfront_Private_Pricing.AWS_REGION, payload),
  saveCloudfrontPrivatePricing: (payload) => post(URLS.Cloudfront_Private_Pricing.GET_ALL, payload),
  // MAV API'S
  getAllUsers: (params, option) => get(URLS.GET_ALL_USERS, params, option),
  getUserById: (params) => get(URLS.GET_USER_BY_ID, params),
  getAllMAV: (params, option) => get(URLS.PREFERENCES.MAV.GET_ALL_MAV, params, option),
  getAllMAVV2: (params, option) => get(URLS.PREFERENCES.MAV.V2.GET_ALL_MAV, params, option),
  getAllMAVNames: (params, option) => get(URLS.PREFERENCES.MAV.GET_ALL_MAV_NAMES, params, option),
  getProjectCount: (params, option) => get(URLS.PREFERENCES.MAV.GET_PROJECT_COUNT, params, option),
  getProjectCountLegacyLens: (params, option) =>
    get(URLS.LEGACY_LENS.GET_PROJECT_COUNT_LEGACY_LENS, params, option),
  getMavRole: () => get(URLS.MAV_ROLE),
  deleteMAV: (id) => deleteAPI(URLS.PREFERENCES.MAV.DELETE_MAV + id),
  getMAVNames: (params) => get(URLS.PREFERENCES.MAV.GET_MAV_NAMES, params),
  getPreferencesTagsList: (params) => get(URLS.PREFERENCES.TAGS.GET_ALL_TAGS, params),
  createPreferencesTag: (payload) => post(URLS.PREFERENCES.TAGS.CREATE_TAG, payload),
  updatePreferencesTag: (payload) => put(URLS.PREFERENCES.TAGS.UPDATE_TAG, payload),
  deletePreferencesTag: (id) => deleteAPI(URLS.PREFERENCES.TAGS.DELETE + id),
  getAllAccountId: (param) => get(URLS.PREFERENCES.MAV.GET_ACCOUNT_ID, param),
  getAllAccountIdWithName: (param) =>
    get(URLS.PREFERENCES.MAV.GET_ACCOUNT_ID_WITH_ACCOUNT_NAME, param),
  getAllMavUsers: (params) => get(URLS.PREFERENCES.MAV.GET_MAV_USERS, params),
  addMAV: (payload) => post(URLS.PREFERENCES.MAV.SAVE_MAV, payload),
  publishMav: (payload) => post(URLS.PREFERENCES.MAV.PUBLISH, payload),

  // MAV API'S

  updateMavName: (payload) => put(URLS.PREFERENCES.MAV.UPDATE_MAV, payload),
  updateMavNameDefault: (payload) => put(URLS.PREFERENCES.MAV.UPDATE_MAV_DEFAULT, payload),

  // Azure API
  fetchAzureCurrency: () => get(URLS.CK_AZURE.GET_CURRENCY()),
  getProjectCountAzure: (params, option) => get(URLS.CK_AZURE.GET_PROJECTCOUNT, params, option),
  getMavExist: () => get(URLS.CK_AZURE.GET_MAV_EXIST),
  // Azure Resource Wise Breakup
  // monthly
  GetResourceWiseMonthlyData: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_MONTHLY(), payload),
  GetResourceWiseMonthlyResourceName: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_MONTHLY_RESOURCENAME(), payload),
  GetResourceWiseMonthlySubscriptionId: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_MONTHLY_SUBSCRIPTIONID(), payload),
  GetResourceWiseMonthlyDataDownload: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_MONTHLYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),

  // previous month
  GetResourceWisePreviousMonth: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_PREVIOUSMONTH(), payload),
  GetResourceWisePreviousMonthResourceName: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_PREVIOUSMONTH_RESOURCENAME(), payload),
  GetResourceWisePreviousMonthSubscriptionId: (payload) =>
    get(
      URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_PREVIOUSMONTH_SUBSCRIPTIONID(),
      payload
    ),
  GetResourceWisePreviousMonthDownload: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_PREVIOUSMONTHDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),

  // daily
  GetResourceWiseDailyData: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_DAILY(), payload),
  GetResourceWiseDailyDataResourceName: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_DAILY_RESOURCENAME(), payload),
  GetResourceWiseDailyDataSubscriptionId: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_V2_DAILY_SUBSCRIPTIONID(), payload),
  GetResourceWiseDailyDataDownload: (payload) =>
    get(URLS.CK_AZURE.RESOURCE_WISE_BREAKUP.RESOURCE_WISE_DAILYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),

  // Azure manual onboarding
  getAzureOnboardingStatus: () => get(URLS.CK_AZURE.MANUAL_ONBOARDING.ONBOARDING_STATUS()),
  getAzureCustomerOnboardingList: () =>
    get(URLS.CK_AZURE.MANUAL_ONBOARDING.CUSTOMER_ONBOARDING_LIST()),
  submitAzureOnboardingData: (payload) =>
    post(URLS.CK_AZURE.MANUAL_ONBOARDING.SUBMIT_ONBOARDINGDATA(), payload),
  getOnboardingPolicy: (payload) =>
    get(URLS.CK_AZURE.MANUAL_ONBOARDING.ONBOARDING_POLICY(), payload),
  validateSummary: (payload) => post(URLS.CK_AZURE.MANUAL_ONBOARDING.VALIDATE_SUMMARY(payload)),
  // lens APIS
  fetchMinMaxDates: (payload = {}) => get(URLS.CK_LENS.MIN_MAX_DATES(), payload),
  GetForecastData: (payload) => get(URLS.CK_LENS.BILLING_SUMMARY.COST_DASHBOARD(), payload),
  GraphTableDataBillingsummary: (payload) =>
    get(URLS.CK_LENS.BILLING_SUMMARY.GET_TABLE_OR_GRAPH(), payload),
  exportTableDataBillingsummary: (payload) =>
    get(URLS.CK_LENS.BILLING_SUMMARY.EXPORT_TABLE_DATA(), payload, {
      responseType: "arraybuffer",
    }),
  GetRecommendationData: (payload) =>
    get(URLS.CK_LENS.BILLING_SUMMARY.RECOMMENDATION_SUMMARY(), payload),
  GetCostByServiceData: (payload) =>
    get(URLS.CK_LENS.BILLING_SUMMARY.TOTAL_COST_BY_SERVICE(), payload),
  GetMonthlyData: (payload) => get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_MONTHLY(), payload),
  GetPreviousMonth: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_PREVIOUSMONTH(), payload),
  GetDailyData: (payload) => get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_DAILY(), payload),
  GetDailyDataCost: (payload) => get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_COST(), payload),
  GetAzureSubscriptionId: (id, options) => get(URLS.CK_AZURE.GET_SUBSCRIPTION_ID + id, {}, options),

  // Activation
  getMarketplaceUser: () => get(URLS.CK_AZURE.ACTIVATION.MARKETPLACE_USER),
  saveMarketplaceUser: (payload) =>
    post(URLS.CK_AZURE.ACTIVATION.SAVE_MARKETPLACE_SIGNUP_DATA, payload),

  // lens V2 apis

  GetMonthlyDataGroupByRegionName: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_MONTHLY_GROUP_BY_REGION_NAME(), payload),
  GetColorCodes: (payload) => get(URLS.CK_LENS.COLOR_CODES(), payload),
  GetMonthlyDataGroupByProductName: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_MONTHLY_GROUP_BY_PRODUCT_NAME(), payload),
  GetPreviousMonthGroupByRegionName: (payload) =>
    get(
      URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_PREVIOUSMONTH_GROUP_BY_REGION_NAME(),
      payload
    ),
  GetPreviousMonthGroupByProductName: (payload) =>
    get(
      URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_PREVIOUSMONTH_GROUP_BY_PRODUCT_NAME(),
      payload
    ),
  GetDailyDataGroupByRegionName: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_DAILY_GROUP_BY_REGION_NAME(), payload),
  GetDailyDataGroupByProductName: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_V2_DAILY_GROUP_BY_PRODUCT_NAME(), payload),

  // RICoverage
  GetRICoverageData: (payload) => get(URLS.CK_LENS.RI_COVERAGE.GET_RICOVERAGE, payload),
  GetRICoverageHourlyData: (payload) =>
    get(URLS.CK_LENS.RI_COVERAGE.GET_RICOVERAGE_HOURLYDATA, payload),
  ExportRICoverage: (payload) =>
    get(URLS.CK_LENS.RI_COVERAGE.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  GetMonthlyDataDownload: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_MONTHLYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),
  GetMonthlyDataDownloadV2: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_MONTHLYDOWNLOAD_V2(), payload),

  GetPreviousMonthDownload: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_PREVIOUSMONTHDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),
  GetPreviousMonthDownloadV2: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_PREVIOUSMONTHDOWNLOAD_V2(), payload),
  GetDailyDataDownload: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_DAILYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),
  GetDailyDataDownloadV2: (payload) =>
    get(URLS.CK_LENS.BILLING_CONSOLE.BILLING_CONSOLE_DAILYDOWNLOAD_V2(), payload),
  GetMonthlyAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_MONTHLY(), payload),
  GetV2MonthlySummaryAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_MONTHLY_SUMMARY(), payload),
  GetV2MonthlyDetailAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_MONTHLY_DETAIL(), payload),
  GetPreviousMonthAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_PREVIOUSMONTH(), payload),
  GetV2PreviousMonthSummaryAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_PREVIOUSMONTH_SUMMARY(), payload),
  GetV2PreviousMonthDetailAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_PREVIOUSMONTH_DETAIL(), payload),
  GetDailyAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_DAILY(), payload),
  GetV2DailySummaryAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_DAILY_SUMMARY(), payload),
  GetV2DailyDetailAccountWiseBreakupData: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_V2_DAILY_DETAIL(), payload),
  GetMonthlyAccountWiseBreakupDataDownload: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_MONTHLYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),
  GetPreviousMonthAccountWiseBreakupDataDownload: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_PREVIOUSMONTHDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),
  GetDailyAccountWiseBreakupDataDownload: (payload) =>
    get(URLS.CK_LENS.ACCOUNT_WISE_BREAKUP.ACCOUNT_WISE_BREAKUP_DAILYDOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),

  GetEmailNotificationGroups: (payload) =>
    get(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.GET_ALL, payload),
  GetEmailThresholdCount: (payload) =>
    get(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.GET_THRESHOLD_COUNT, payload),
  CreateEmailNotificationGroups: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.CREATE, payload),
  UpdateEmailNotificationGroups: (payload, id) =>
    put(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.UPDATE + id, payload),
  DeleteEmailNotificationGroups: (id) =>
    deleteAPI(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.DELETE + id),

  GetSlackNotificationGroups: (payload) =>
    get(URLS.CK_LENS.NOTIFICATION.INTEGRATION.SLACK.GET_ALL, payload),
  CreateSlackNotificationGroups: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.INTEGRATION.SLACK.CREATE, payload),
  UpdateSlackNotificationGroups: (payload, id) =>
    put(URLS.CK_LENS.NOTIFICATION.INTEGRATION.SLACK.UPDATE + id, payload),
  DeleteSlackNotificationGroups: (id) =>
    deleteAPI(URLS.CK_LENS.NOTIFICATION.INTEGRATION.SLACK.DELETE + id),

  GetBugetAlertsGroups: (payload) => get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.GET_ALL, payload),
  CreateBugetAlertsGroups: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.DAILY_COST_DEVIATION.CREATE, payload),
  UpdateBugetAlertsGroups: (payload, id) =>
    put(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.DAILY_COST_DEVIATION.UPDATE + id, payload),
  DeleteBugetAlertsGroups: (id) =>
    deleteAPI(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.DAILY_COST_DEVIATION.DELETE + id),
  CreateMonthlyBugetAlertsGroups: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.MONTHLY_COST_DEVIATION.CREATE, payload),
  UpdateMonthlyBugetAlertsGroups: (payload, id) =>
    put(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.MONTHLY_COST_DEVIATION.UPDATE + id, payload),
  DeleteMonthlyBugetAlertsGroups: (id) =>
    deleteAPI(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.MONTHLY_COST_DEVIATION.DELETE + id),

  getEditBudgetAlert: (payload, params) =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_EDIT_DATA(), params, payload),

  GetCostTrend: (payload, type) =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_COST() + type, payload),
  GetFilterList: (payload, type) =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_FILTERS_LIST() + type, payload),
  GetFilterListV2: () =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_FILTERLIST_V2()),
  GetFilterValues: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_FILTER_VALUES(), payload),
  GetGraphData: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_GRAPH_DATA(), payload),
  GetGraphDataV2: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.COST_TRENDS.GET_GRAPH_DATAV2(), payload),

  GetDailySummaryReport: (payload) =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.DAILY_SUMMARY_REPORT.GET, payload),
  UpdateDailySummaryReport: (payload) =>
    put(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.DAILY_SUMMARY_REPORT.UPDATE, payload),

  GetRIUtilization: (payload) =>
    get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_UTILIZATION.GET, payload),
  CreateRIUtilization: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_UTILIZATION.CREATE, payload),
  UpdateRIUtilization: (payload) =>
    put(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_UTILIZATION.UPDATE + payload.id, payload),
  DeleteRIUtilization: (id) =>
    deleteAPI(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_UTILIZATION.DELETE + id),

  GetRIExpiry: () => get(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_EXPIRY.GET, {}),
  UpdateRIExpiry: (payload) =>
    post(URLS.CK_LENS.NOTIFICATION.BUDGET_ALERTS.RI_EXPIRY.UPDATE, payload),

  // Cost Breakup API
  getStorageData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.STORAGE.GET_DATA(), payload),
  getStorageDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.STORAGE.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getInstanceCostData: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.INSTANCE_COST.GET_DATA(), payload),
  getInstanceCostDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.INSTANCE_COST.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getInstanceCostExportId: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.INSTANCE_COST.GET_COST_EXPORT_ID(), payload),
  getStorageExportId: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.STORAGE.GET_COST_EXPORT_ID(), payload),
  GET_POLL_STATUS: (id) =>
    get(
      URLS.CK_LENS.COST_BREAKUP.INSTANCE_COST.GET_POLL_STATUS(id),
      {},
      {
        isPartnerCustomerAuthRequired: false,
        responseType: "arraybuffer",
      }
    ),
  getServerlessData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.SERVERLESS.GET_DATA(), payload),
  getServerlessDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.SERVERLESS.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getLoggingandMonitoringData: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.LOGGING_MONITORING.GET_DATA(), payload),
  getLoggingandMonitoringExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.LOGGING_MONITORING.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getSecurityData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.SECURITY.GET_DATA(), payload),
  getSecurityDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.SECURITY.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getMigrationTransferData: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.MIGRATION_TRANSFER.GET_DATA, payload),
  getMigrationTransferExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.MIGRATION_TRANSFER.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  getContainersData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.CONTAINERS.GET_DATA(), payload),
  getContainersDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.CONTAINERS.EXPORT(), payload, { responseType: "arraybuffer" }),
  getDatabaseData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.DATABASE.GET_DATA(), payload),
  getDatabaseDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.DATABASE.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getNetworkContentData: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.NETWORK_CONTENT_DELIEVERY.GET_DATA(), payload),
  getNetworkContentDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.NETWORK_CONTENT_DELIEVERY.EXPORT(), payload, {
      responseType: "arraybuffer",
    }),
  getAnalyticsData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.ANALYTICS.GET_DATA, payload),
  getAnalyticsDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.ANALYTICS.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  getManagementGovernanceData: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.MANAGEMENT_GOVERNANCE.GET_DATA, payload),
  getManagementGovernanceDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.MANAGEMENT_GOVERNANCE.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  getMediaData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.MEDIA_SERVICES.GET_DATA, payload),
  getMediaExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.MEDIA_SERVICES.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  getDataTransferData: (payload) => post(URLS.CK_LENS.COST_BREAKUP.DATA_TRANSFER.GET_DATA, payload),
  getDataTransferSummaryData: (payload) =>
    get(URLS.CK_LENS.COST_BREAKUP.DATA_TRANSFER.GET_DATA_SUMMARY, payload),
  getDataTransferDataExport: (payload) =>
    post(URLS.CK_LENS.COST_BREAKUP.DATA_TRANSFER.EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  // summary
  GetReservationSummaryGet: (payload) =>
    get(URLS.CK_LENS.RESERVATIONSUMMARY.RESERVATION_SUMMARY_GET(), payload),
  GetReservationSummaryDownload: (payload) =>
    get(URLS.CK_LENS.RESERVATIONSUMMARY.RESERVATION_SUMMARY_DOWNLOAD(), payload, {
      responseType: "arraybuffer",
    }),

  getCloudFrontData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.CLOUDFRONT.GET_DATA, payload),
  getS3Data: (payload) => get(URLS.CK_LENS.RECOMMENDATION.S3.GET_DATA, payload),
  getInstancesData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.INSTANCES.GET_DATA, payload),
  getEBSData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.EBS.GET_DATA, payload),
  getEbsSnapshotData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.EBS_SNAPSHOT.GET_DATA, payload),
  geCLBData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.CLB.GET_DATA, payload),
  getUpgradeVersionData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.UPGRADE_VERSION.GET_DATA, payload),
  getGravitonData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.GRAVITON.GET_DATA, payload),
  downloadUpgradeVersionData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.UPGRADE_VERSION.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadGravitonData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.GRAVITON.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  getIdleNetworkData: (payload) => get(URLS.CK_LENS.RECOMMENDATION.IDLE_NETWORK.GET_DATA, payload),
  downloadIdleNetworksData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.IDLE_NETWORK.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadCloudFrontData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.CLOUDFRONT.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadS3Data: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.S3.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadInstancesData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.INSTANCES.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadEBSData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.EBS.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadEbsSnapshotData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.EBS_SNAPSHOT.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  downloadCLBData: (payload) =>
    get(URLS.CK_LENS.RECOMMENDATION.CLB.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),
  // AWS Console
  GetAwsSummaryData: (payload) => get(URLS.CK_LENS.AWS_CONSOLE.GET_DATA, payload),
  ExportAWSData: (payload) =>
    get(URLS.CK_LENS.AWS_CONSOLE.EXPORT, payload, { responseType: "arraybuffer" }),
  // AWS Console V2
  GetAwsV2SummaryData: (payload) => get(URLS.CK_LENS.AWS_CONSOLE.GET_DATA_V2_SUMMARY, payload),
  GetAwsV2DetailsData: (payload) => get(URLS.CK_LENS.AWS_CONSOLE.GET_DATA_V2_DETAILS, payload),
  // utilization
  GetUtilizationSummaryData: (payload) =>
    get(URLS.CK_LENS.UTILIZATION.GET_UTILIZATIONDATA, payload),
  GetUtilizationHourlyDetails: (payload) =>
    get(
      payload.tab === "savingsPLan"
        ? URLS.CK_LENS.UTILIZATION.GET_UTILIZATION_SP_HOURLYDATA
        : URLS.CK_LENS.UTILIZATION.GET_UTILIZATION_HOURLYDATA,
      payload
    ),
  ExportUtilization: (payload) =>
    get(URLS.CK_LENS.UTILIZATION.EXPORT_UTILIZATION, payload, { responseType: "arraybuffer" }),

  // cost explorer api's
  GetCostExplorerGroupByValues: () => get(URLS.CK_LENS.COSTEXPLORER.GROUP_BY_VALUES(), {}),
  GetCostExplorerGroupByTagsValues: () => get(URLS.CK_LENS.COSTEXPLORER.GROUP_BY_TAGNAME_VALUES()),
  GetCostExplorerGetFilters: (payload) => post(URLS.CK_LENS.COSTEXPLORER.FILTERS_VALUES(), payload),
  GetCostExplorerTableGraphData: (payload) =>
    post(URLS.CK_LENS.COSTEXPLORER.TABLE_GRAPHDATA(), payload),
  GetCostExplorerTableExportV2: (payload) =>
    post(URLS.CK_LENS.COSTEXPLORER.TABLE_EXPORT_V2(), payload),
  GetCostExplorerTableExport: (payload) =>
    post(URLS.CK_LENS.COSTEXPLORER.TABLE_EXPORT(), payload, { responseType: "arraybuffer" }),
  GetCostExplorerTags: (payload) => get(`${URLS.CK_LENS.COSTEXPLORER.TAGS_LIST()}${payload}`, {}),
  getCostExplorerRecentReports: () => get(URLS.CK_LENS.COSTEXPLORER.COSTEXPLORER_REPORT()),
  saveCostExplorerRecentReports: (payload, id) =>
    post(URLS.CK_LENS.SAVED_REPORTS_SAVE_NEW(id), payload),
  deleteCostExplorerRecentReports: (id) =>
    deleteAPI(`${URLS.CK_LENS.COSTEXPLORER.COSTEXPLORER_REPORT()}/${id}`),
  saveCostExplorerReport: (payload, id) =>
    post(URLS.CK_LENS.COSTEXPLORER.COSTEXPLORER_REPORT(id), payload),
  updateCostExplorerReport: (payload, id) =>
    put(URLS.CK_LENS.COSTEXPLORER.COSTEXPLORER_REPORT(id), payload),
  // TAG Report
  GetTagList: () => get(URLS.CK_LENS.TAG_REPORT.TAG_LIST()),
  GetTagReportDailyData: (payload, source) =>
    post(URLS.CK_LENS.TAG_REPORT.TAG_LIST_MONTHLY_DATA(), payload, { cancelToken: source?.token }),
  GetTagReportDailyDataExpandAll: (payload) =>
    post(URLS.CK_LENS.TAG_REPORT.TAG_LIST_MONTHLY_DATA_EXPANDALL(), payload),
  GetTagReportDownload: (payload, details) =>
    post(`${URLS.CK_LENS.TAG_REPORT.TAG_LIST_DOWNLONLOAD()}?detailed=${details}`, payload, {
      responseType: "arraybuffer",
    }),
  getAllLegalEntity: (payload) => get(URLS.LEGAL_ENTITY.GET, payload),
  getAllLegalEntityNames: (payload) => get(URLS.LEGAL_ENTITY.GET_NAMES, payload),
  CreateLegalEntity: (payload) => post(URLS.LEGAL_ENTITY.CREATE, payload),
  UpdateLegalEntity: (payload) => put(URLS.LEGAL_ENTITY.UPDATE, payload),
  bulkUpdateLegalEntity: (payload) => put(URLS.LEGAL_ENTITY.BULKUPDATE, payload),
  getEffectiveDateByAccIdForLegalEnitity: (payload, customerId) =>
    post(URLS.LEGAL_ENTITY.GET_EFFECTIVE_DATE(customerId), payload),
  getBillingName: (customerId, legalEntityId) =>
    get(URLS.LEGAL_ENTITY.GET_BILLING_NAME(customerId, legalEntityId)),

  getAllCBU: (payload) => get(URLS.CBU.GET, payload),
  getAllCBUNames: (payload) => get(URLS.CBU.GET_NAMES, payload),
  getEffectiveDateByAccIdForCBU: (payload, customerId) =>
    post(URLS.CBU.GET_EFFECTIVE_DATE(customerId), payload),
  getAllConfig: (payload) => get(URLS.GET_CONFIGURATION, payload),

  postCBUName: (payload) => post(URLS.CBU.CREATE, payload),
  bulkUpdateCBUName: (payload) => put(URLS.CBU.BULKUPDATE, payload),
  // Change Password
  changePassword: (payload) =>
    post(URLS.CHANGE_PASSWORD, payload, {
      headers: {
        "X-Access-Token": "add header",
      },
    }),

  getCurrentUserData: () => get(URLS.USER.GET_CURRENT_USER),

  // for fetching notification integration user emails
  getUserEmailNotificationGroup: (params) =>
    get(URLS.CK_LENS.NOTIFICATION.INTEGRATION.EMAIL.GET_EMAIL_LIST, params),
  // CK_AUTO
  addAwsAccount: (payload) => post(URLS.CK_AUTO.AWS_ACCOUNT, payload),
  AddCompleteOnboarding: (payload) => post(URLS.CK_AUTO.AWS_COMPLETE_ONBOARDING, payload),
  registerUser: (payload) => post(URLS.CK_AUTO.SIGN_UP_CK_AUTO, payload),
  partnerCreateUser: () => get(URLS.CK_AUTO.LOGIN_PARTNER_META),
  getFinalisedReport: (payload) => get(URLS.CK_AUTO.SAVING_ACHIEVED_GET_FINALISED_REPORT, payload),
  getTotalSummary: (payload) => get(URLS.CK_AUTO.SAVING_ACHIEVED_GET_TOTAL_SUMMARY, payload),
  cardAndTabsData: (payload) => get(URLS.CK_AUTO.SAVING_ACHIEVED_GET_CARDS_AND_TABS, payload),
  savingsTableData: (payload) => get(URLS.CK_AUTO.SAVING_ACHIEVED_GET_TABLE, payload),
  potentialSavingsDetails: (offeringType) =>
    get(`${URLS.CK_AUTO.POTENTIAL_SAVINGS_GET_DETAILED}?offeringType=${offeringType}`),
  partnerPotentialSavingsDetails: (customerId, offeringType) =>
    get(
      `${URLS.CK_AUTO.PARTNER_POTENTIAL_SAVINGS_GET_DETAILED}/${customerId}?offeringType=${offeringType}`
    ),
  downloadAPI: (url, param) =>
    get(url, param, {
      responseType: "arraybuffer",
    }),
  fetchMinMaxDatesCKAuto: () => get(URLS.CK_AUTO.MIN_MAX_DATES_CK_AUTO),
  getReportDateApi: () => get(URLS.CK_AUTO.GET_ACCOUNT_DETAILS),
  getCustomerBucketName: () => get(URLS.CK_AUTO.GET_BUCKET_NAME),
  getCustomerExternalIDRead: (payload) => get(URLS.CK_AUTO.GET_EXTERNAL_ID, payload),
  getCustomerBucketNameLens: () => get(URLS.LEGACY_LENS.GET_BUCKET_NAME_LENS),
  validateARN: (param) => get(URLS.CK_AUTO.VALIDATE_ARN, param),
  potentialGraphData: (offeringType) =>
    get(`${URLS.CK_AUTO.POTENTIAL_SAVINGS_GRAPH_DATA}?offeringType=${offeringType}`),
  partnerPotentialGraphData: (customerId, offeringType) =>
    get(
      `${URLS.CK_AUTO.PARTNER_POTENTIAL_SAVINGS_GRAPH_DATA}/${customerId}?offeringType=${offeringType}`
    ),

  // Missed Savings
  getMissedSavingServices: (payload) => get(URLS.CK_AUTO.MISSED_SAVING.SERVICES, payload),
  getMissedSavingPartnerServices: (customerId, payload) =>
    get(`${URLS.PLAN_SELECTION.SERVICES}${customerId}`, payload),
  getMissedSavingDetails: (payload) => get(URLS.CK_AUTO.MISSED_SAVING.DETAILS, payload),
  getMissedSavingPartnerDetails: (customerId, payload) =>
    get(`${URLS.PLAN_SELECTION.DETAILS}${customerId}`, payload),

  // Cudos dashboard API calls
  GetS3BucketCostByBucket: (param) => get(URLS.CK_LENS.S3_CUDOS.GET_S3_GRAPH_DATA, param),
  GetS3BucketCostByUsage: (param) => get(URLS.CK_LENS.S3_CUDOS.GET_S3_USAGE_GRAPH_DATA, param),
  GetS3BucketCostByUsageType: (param) =>
    get(URLS.CK_LENS.S3_CUDOS.GET_COST_BY_USAGE_GRAPH_DATA, param),
  getS3CostBreakUp: (param) => get(URLS.CK_LENS.S3_CUDOS.GET_S3_COST_BREAKUP, param),
  GetS3CardsData: (param) => get(URLS.CK_LENS.S3_CUDOS.GET_S3_CARDS, param),
  ExportS3Data: (param) =>
    get(URLS.CK_LENS.S3_CUDOS.EXPORT_S3, param, { responseType: "arraybuffer" }),
  getS3Highlights: (payload) => post(URLS.CK_LENS.S3_CUDOS.GET_S3_HIGHLIGHTS, payload),

  // Cudos Data Transfer API
  GetDataTransferCardsData: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_TRANSFER_CARDS, param),
  GetCostByAccountData: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_ACCOUNT, param),
  GetCostByType: (param) => get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_TYPE, param),
  GetCostByServiceAndUsage: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_USAGE_AND_SERVICE, param),
  GetCostByOperationAccountwise: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_OPERATION_ACCOUNTWISE, param),
  GetCostByServiceAndType: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_SERVICE_AND_TYPE, param),
  getDataSummarySummaryCards: (payload) => get(URLS.CUDOS.DATA_TRANSFER_SUMMARY_CARDS, payload),
  GetServicesByType: (param) => get(URLS.CUDOS.GET_SERVICES_BY_TYPE, param),
  GetTypeByservices: (param) => get(URLS.CUDOS.GET_TYPE_BY_SERVICES, param),
  getRegions: (param) => get(URLS.CUDOS.GET_REGIONS, param),
  getCostByInternetOut: (param) => get(URLS.CUDOS.GET_COST_BY_INTERNET_OUT, param),
  getCostByInterAZ: (param) => get(URLS.CUDOS.GET_COST_BY_INTER_AZ, param),
  getCostByRegionToRegion: (param) => get(URLS.CUDOS.GET_COST_BY_REGION_TO_REGION, param),
  GetCudosOperationTypes: (param) => get(URLS.CUDOS.GET_CUDOS_OPERATION_TYPES, param),
  GetCudosStorageTypes: (param) => get(URLS.CUDOS.GET_CUDOS_STORAGE_TYPES, param),
  GetCostOfServiceByType: (param) => get(URLS.CUDOS.GET_COST_OF_SERVICE_BY_TYPE, param),
  GetTopS3OperationCostByType: (param) => get(URLS.CUDOS.GET_TOP_S3_OPERATION_COST_BY_TYPE, param),
  GetTopS3StorageCostByType: (param) => get(URLS.CUDOS.GET_TOP_S3_STORAGE_COST_BY_TYPE, param),
  GetCostOfTypeByService: (param) => get(URLS.CUDOS.GET_COST_OF_TYPE_BY_SERVICE, param),
  GetDataTransferTopResource: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_TRANSFER_DASHBOARD_TOPRESOURECES, param),
  GetCostByRegionGetCostByRegion: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.GET_DATA_COST_BY_OPERATION_REGION, param),
  getOnDemandSplit: (payload) => get(URLS.CUDOS.ONDEMAND_CARDS, payload),
  getUnusedRiSpCost: (payload) => get(URLS.CUDOS.UNUSED_RI_SP_COST, payload),
  ExportCudosDataTransferData: (param) =>
    get(URLS.CK_LENS.DATA_TRANSFER_CUDOS.EXPORT_DATA_TRANSFER_CUDOS, param, {
      responseType: "arraybuffer",
    }),
  exportCostByInterAZ: (param) =>
    get(URLS.CUDOS.EXPORT_COST_BY_INTER_AZ, param, {
      responseType: "arraybuffer",
    }),
  exportCostByInternetOut: (param) =>
    get(URLS.CUDOS.EXPORT_COST_BY_INTERNET_OUT, param, {
      responseType: "arraybuffer",
    }),
  exportCostByRegionToRegion: (param) =>
    get(URLS.CUDOS.EXPORT_COST_BY_REGION_TO_REGION, param, {
      responseType: "arraybuffer",
    }),
  getEnableRiOpsStatus: () => get(URLS.CK_AUTO.RIOPS_GET_ENABLE_STATUS),
  getOnboardingAccountIds: () => get(URLS.CK_AUTO.RIOPS_GET_ACCOUNT_IDS),
  getCustomerPlan: () => get(URLS.CK_AUTO.GET_CUSTOMER_PLAN),
  getTotalSavingPartnerAPI: (payload, customerId) =>
    get(`${URLS.CK_AUTO.GET_TOTAL_SAVING_PARTNER}${customerId}`, payload),
  getTotalSavingCustomerAPI: (payload) => get(URLS.CK_AUTO.GET_TOTAL_SAVING_CUTOMER, payload),
  getOnboardCustomer: (payload) => get(URLS.CK_AUTO.ONBOARD_CUSTOMER, payload),
  getCURStepDetail: (payload) => get(URLS.CK_AUTO.GET_CUR_DETAIL, payload),
  getCURInlinePolicy: (payload) => get(URLS.CK_AUTO.GET_CUR_INLINE_POLICY, payload),
  getOnboardingAccountIdDetails: (payload) =>
    get(URLS.CK_AUTO.RIOPS_GET_ACCOUNT_ID_DETAILS, payload),
  validatePayer: () => get(URLS.CK_AUTO.PAYER_VALIDATE),
  getNotificationList: (apiPath) => get(apiPath),
  // RIOPS
  nonAisplAwsAccount: (payload) => get(URLS.CK_AUTO.NON_AISPL_GET_AWS_ACCOUNT_IDS, payload),
  verifyARN: (payload) => get(URLS.CK_AUTO.VERIFY_ARN, payload),
  checkCURReplication: () => get(URLS.CK_AUTO.CHECK_CUR_REPLICATION),
  // templates urls :
  gettemplatesConfigurationEDP: () => get(URLS.EDP_TEMPLATES.CONFIGURATION_List),
  getSelectTemplatesEDP: (payload) => get(URLS.EDP_TEMPLATES.TEMPLATE_LIST, payload),
  saveEDPDiscount: (payload, id) => post(URLS.EDP_TEMPLATES.SAVE_EDP(id), payload),
  gettemplatesConfigurationsEDP: () =>
    get("https://run.mocky.io/v3/d8199705-3573-4891-8cfb-4fc189a35e32"),
  // gettemplatesConfigurationsEDP: (params) => get(URLS.FLAT_DISCOUNT.TEMPLATE_LIST(), null, params),

  // On Demand Discount
  getAllDiscount: () => get(URLS.DISCOUNT.GET_ON_DEMAND),

  // customer health check
  getHeathCheck: (payload) => get(URLS.CUSTOMER.HEATH_CHECK, payload),
  getCustomerHealthRefreshedData: (id) => put(`${URLS.CUSTOMER.CUSTOMERS_HEALTH_REFRESHED}/${id}`),

  // plan  selection
  getCustomerOffering: (customerId) =>
    get(`${URLS.CK_AUTO.CK_CUSTOMER_OFFERING}?customerId=${customerId}`),
  getDistinctOfferings: () => get(`${URLS.CK_AUTO.DISTINCT_OFFERINGS}`),
  getCustomerlevelAndPartnerLevelPlan: () => get(`${URLS.CK_AUTO.CUSTOMER_AND_PARTNER_LEVEL_PLAN}`),
  updatePartnerPlan: (id) => put(`${URLS.CK_AUTO.UPDATE_PARTNER_PLAN}?offeringId=${id}`),
  updateCustomerplan: (payload) =>
    put(
      `${URLS.CK_AUTO.UPDATE_CUSTOMER_PLAN}?customerId=${payload.customerId}&offeringId=${payload.offeringId}`
    ),

  // Quote Generator
  getQuoteList: () => get(`${URLS.CK_QUOTE_GEN.GET_LIST}`),
  uploadQuotation: (payload) =>
    post(URLS.CK_QUOTE_GEN.UPLOAD_QUOTATION, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  getCustomerAutoMetaData: () => get(URLS.CK_AUTO.CUSTOMER_AUTO_METADATA),

  vrServiceList: () => get("https://run.mocky.io/v3/daab3dfb-e045-402e-86b1-9f5e0e0d238c"),
  vrConfigurations: () => get("https://run.mocky.io/v3/bebd9168-dc8c-4e44-8569-1285e61a7589"),

  // virtual reservation
  getVirtualReservationListCustomer: (service) =>
    get(URLS.VIRTUAL_RESERVATIONS.GET_RESERVATIONLIST_CUSTOMER(service)),
  // getVirtualReservationList: () => get(URLS.VIRTUAL_RESERVATIONS.GET_RESERVATIONLIST),
  getVirtualReservationList: (payload) =>
    get(URLS.VIRTUAL_RESERVATIONS.GET_RESERVATIONLIST(payload)),
  getVRDropdownConfig: (params) => get(URLS.VIRTUAL_RESERVATIONS.GET_VR_DROPDOWN_CONFIG, params),
  getPartnerAccountIds: (customerID, payload) =>
    get(URLS.VIRTUAL_RESERVATIONS.GET_PARTNER_ACCOUNTID(customerID), payload),
  getCustomerAccountIds: () => get(URLS.VIRTUAL_RESERVATIONS.GET_CUSTOMER_ACCOUNTID),
  getVRCartTableData: (service, payload) =>
    get(URLS.VIRTUAL_RESERVATIONS.GET_CART_TABLE_DATA(service), payload),
  VRPlaceOrder: (params, payload) =>
    post(URLS.VIRTUAL_RESERVATIONS.PLACE_CART_ORDER(params), payload),
  VRPlaceOrderCustomer: (params, payload) =>
    post(URLS.VIRTUAL_RESERVATIONS.PLACE_CART_ORDER_CUSTOMER(params), payload),
  updateVirtualReservationModal: (service, customerId, payload) =>
    put(URLS.VIRTUAL_RESERVATIONS.UPDATE_RESERVATION(service, customerId), payload),
  downloadVRData: (payload) =>
    get(URLS.VIRTUAL_RESERVATIONS.DOWNLOAD_VR_DATA, payload, {
      responseType: "arraybuffer",
    }),
  getVRIDownloadTabsList: (customerID) =>
    get(URLS.VIRTUAL_RESERVATIONS.GET_VR_DOWNLOAD_TABS_LIST(customerID)),
  getVRICustomerHistory: (service, id) =>
    get(URLS.VIRTUAL_RESERVATIONS.COMMENT_HISTORY(service, id)),

  // Flat Discount
  getFlatDiscountList: (customerId) => get(URLS.FLAT_DISCOUNT.GET_DISCOUNT_LIST(customerId)),
  updateFlatDiscount: (payload, id) => put(URLS.FLAT_DISCOUNT.UPDATE_FLAT_DISCOUNT(id), payload),
  getTemplateList: (payload) => get(URLS.FLAT_DISCOUNT.TEMPLATE_LIST, payload),
  getTemplateListServiceWise: (payload) =>
    get(URLS.FLAT_DISCOUNT.TEMPLATE_LIST_SERVICE_WISE(payload.service)),
  saveFlatDiscount: (id, payload) => post(URLS.FLAT_DISCOUNT.SAVE_FLAT_DISCOUNT(id), payload),
  getTemplateModalData: (id) => get(URLS.FLAT_DISCOUNT.TEMPLATE_MODAL_DATA(id)),
  downloadFile: (url, params) =>
    get(url, params, {
      responseType: "arraybuffer",
    }),

  // Charges And Refunds
  getChargesAndRefundsList: (customerId) =>
    get(URLS.CHARGES_AND_REFUND.GET_CHARGES_AND_REFUND_LIST(customerId)),
  getChargesAndRefundsConfiguration: () =>
    get(URLS.CHARGES_AND_REFUND.GET_CHARGES_AND_REFUND_CONFIGURATION()),
  setChargesAndRefundsConfiguration: (payload) =>
    post(URLS.CHARGES_AND_REFUND.SET_CHARGES_AND_REFUND_CONFIGURATION(), payload),
  getChargesAndRefundsConfigurationOptions: (params) =>
    get(URLS.CHARGES_AND_REFUND.GET_CHARGE_AND_REFUND_OPTIONS(params)),
  updateChargesAndRefundsModal: (customerId, payload) =>
    put(URLS.CHARGES_AND_REFUND.UPDATE_CHARGES_AND_REFUNDS(customerId), payload),
  saveChargesAndRefunds: (customerId, payload) =>
    post(URLS.CHARGES_AND_REFUND.SAVE_CHARGES_AND_REFUNDS(customerId), payload),

  // Finops
  getMonthlyReportList: () => get(URLS.FINOPS.GETMONTHLYREPORTLIST),
  saveMonthlyReportConfiguration: (param) => post(URLS.FINOPS.SAVEREPORTLIST, param),
  MonthlyDataStartDate: () => get(URLS.FINOPS.START_DATE),
  generateMonthlyReportList: (payload) =>
    post(URLS.FINOPS.UPLOAD_MONTHLYREPORT, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  getAllRecommendations: (payload) => get(URLS.FINOPS.ALL_RECOMMENDATIONS, payload),
  geCustomerWiseRecommendations: () => get(URLS.FINOPS.CUSTOMER_WISE_RECOMMENDATIONS),
  getMavListOnPartner: (params) => get(URLS.FINOPS.GETMAVLISTONPARTNER, params),
  generateWar: (params) => post(URLS.FINOPS.GENERATE_WAR, params),
  downloadWar: (params) =>
    get(URLS.FINOPS.DOWNLOAD_WAR, params, {
      responseType: "arraybuffer",
    }),
  validateARNs: (params) => post(URLS.FINOPS.VALIDATE_ARNS, params),
  anomalyListing: () => get(URLS.FINOPS.ANOMALY_LISTING),
  archivedListing: () => get(URLS.FINOPS.ARCHIVED_ANOMALY_LISTING),
  archivedListingWithFilters: (params) => get(URLS.FINOPS.ARCHIVED_ANOMALY_LISTING, params),
  anomalyData: (params) => post(URLS.FINOPS.ANOMALY_DATA, params),
  sendAnomalyMail: (params) => post(URLS.FINOPS.SEND_MAIL_ANOMALY, params),
  updateAnomalyStatus: (params) => put(URLS.FINOPS.UPDATE_ANOMALY_STATUS, params),
  addAnomalyComment: (params) => put(URLS.FINOPS.ADD_ANOMALY_COMMENT, params),
  // War Report
  getAllWarReport: () => get(URLS.FINOPS.ALL_WAR_REPORT),
  getAllWarServicesList: () => get(URLS.FINOPS.ALL_WAR_SERVICES_LIST),
  generateDeltaReport: (params) => get(URLS.FINOPS.REQUEST_DELTA, params),
  viewDeltaReport: (params) => get(URLS.FINOPS.VIEW_DELTA, params),

  // Market place
  getMarketPlaceCustomers: (params) => get(URLS.CK_MARKETPLACE.GET_CUSTOMERS, params),
  getCustomerMeteringRecordSummary: (params) =>
    post(URLS.CK_MARKETPLACE.CUSTOMER_METERING_RECORDS_SUMMARY, params),
  sendMeteringRecord: (params) => post(URLS.CK_MARKETPLACE.SEND_METERING_RECORDS, params),
  getCustomerMeteringRecord: (payload) =>
    post(URLS.CK_MARKETPLACE.CUSTOMER_METERING_RECORDS, payload),
  getCustomerMeteringFinalized: (payload) =>
    get(URLS.CK_MARKETPLACE.CUSTOMER_METERING_FINALIZED, payload),
  getMarketPlaceMinDate: () => get(URLS.CK_MARKETPLACE.MARKETPLACE_MIN_DATE),
  getCustomerInvoiceRecord: (payload) =>
    post(URLS.CK_MARKETPLACE.CUSTOMER_INVOICE_RECORDS, payload),
  getRiOpsActiveCustomers: (payload) =>
    get(URLS.CK_MARKETPLACE.GET_RI_OPS_ACTIVE_CUSTOMERS, payload),

  // Flat Discount
  getDiscountConfigApi: (params) => get(URLS.FLAT_DISCOUNT.GET_TEMPLATE_CONFIGURATION, params),
  saveDiscountConfig: (params) => post(URLS.FLAT_DISCOUNT.SAVE_TEMPLATE_CONFIGURATION, params),
  getDiscountConfigById: (params) => get(`${URLS.FLAT_DISCOUNT.GET_TEMPLATE_BY_ID}/${params.id}`),

  // EDP Discount
  getEDPDiscountTemplates: () => get(URLS.EDP_TEMPLATES.EDP_DISCOUNT_TEMPLATE),
  deleteEDPDiscountTemplates: (id) =>
    deleteAPI(`${URLS.EDP_TEMPLATES.EDP_DISCOUNT_TEMPLATE}/${id}`),
  // EDP discount
  updateEndEdpDiscount: (payload, id) => put(URLS.EDP_DISCOUNT.END_EDP_DISCOUNT(id), payload),
  getDiscountListData: (id) => get(URLS.EDP_TEMPLATES.EDP_DISCOUNT_List(id)),
  getDiscountListExportData: (id) => get(URLS.EDP_TEMPLATES.EXPORT_EDP_LIST(id)),

  // Reservation Discount
  saveReservationDisocunt: (params, payload) => post(URLS.SAVERESERVATIONDISCOUNT(params), payload),
  updateReservationDiscountModal: (customerId, payload) =>
    put(URLS.RESERVATION_DISCOUNT.UPDATE_RESERVATION_DISCOUNT(customerId), payload),
  getReservationDiscountDownloadTabsList: (customerID) =>
    get(URLS.RESERVATION_DISCOUNT.GET_RESERVATION_DISCOUNT_DOWNLOAD_TABS_LIST(customerID)),
  getReservationDiscountList: (selectedTab, id) =>
    get(URLS.RESERVATION_DISCOUNT.GET_RESERVATION_DISCOUNT_LIST(id), selectedTab),
  // Support configuration :
  GetSupportFormConfig: () => get(URLS.SUPPORTCONFIGURATIONFORM),
  GetSupportConfigurationById: (params, payload) =>
    post(URLS.GETSUPPORTCONFIGBYID(params), payload),
  getEffectiveDate: (customerID, payload) =>
    post(URLS.SUPPORT_CONFIGURATION.GET_EFFECTIVE_DATE(customerID), payload),
  MoveAccount: (params, payload) => post(URLS.MOVEACCOUNT(params), payload),
  getCreditsListData: (id) => get(URLS.CREDITS.GET_CREDITS_LIST(id)),
  endCredits: (payload, id) => put(URLS.CREDITS.GET_CREDITS_LIST(id), payload),
  saveCredits: (payload, id) => post(URLS.CREDITS.GET_CREDITS_LIST(id), payload),
  customerHistory: (id) => get(URLS.CREDITS.COMMENT_HISTORY(id)),
  changeSupportConfiguration: (params) => put(URLS.UPDATESUPPORTCONFIG, params),

  getCustomerInvoiceDate: (customerId) => get(URLS.GET_CUSTOMER_INVOICE_DATE(customerId)),

  // Media Connect Api's
  getRegionList: (params) => get(`${URLS.MEDIA_CONNECT.GET_REGION_LIST}?type=${params}`),
  getPricingList: (params) => get(`${URLS.MEDIA_CONNECT.GET_PRICING_LIST}?type=${params}`),
  getPPAData: (selectedTab, id) => get(URLS.PPA.GET_PPA_TABLE_DATA(id, selectedTab)),
  savePPA: (payload, id, selectedKey) =>
    post(URLS.PPA.SAVE_PPA_CONFIGURE(id, selectedKey), payload),

  updatePPAModal: (selectedTab, customerId, payload) =>
    put(URLS.PPA.UPDATE_PPA_DISCOUNT(selectedTab, customerId), payload),

  getPPADownloadTabsList: (id) => get(URLS.PPA.GET_PPA_DOWNLOAD_TABS_LIST(id)),
  getPPAConfigurationRate: () => get(URLS.PPA.GET_PPA_CONFIGURATION_RATE()),
  getCustomerAccountList: () => get(URLS.ACCOUNT_SYNC_API),

  // clodfront ppa api's
  getPPACommentHistory: (customerId, payload) => get(URLS.PPA.COMMENT_HISTORY(customerId, payload)),
  getPPAPreviewData: (customerId, payload) => get(URLS.PPA.PREVIEW_DATA(customerId, payload)),

  // construct ordering
  getConstructOrderingList: () => get(URLS.ORDERING_TEMPLATE()),
  deleteOrderingTemplates: (id) => deleteAPI(`${URLS.ORDERING_TEMPLATE()}/${id}`),
  UpdateOrderingTemplates: (payload, id) => put(URLS.ORDERING_TEMPLATE(id), payload),
  saveOrderingTemplate: (payload) => post(URLS.SAVE_ORDERING_TEMPLATE, payload),
  getDefaultOrderingList: () => get(URLS.GET_DEFAULT_ORDERING),
  orderingTemplateHistory: (id) => get(URLS.TEMPLATE_HISTORY(id)),
  customerTypeHistory: (id) => get(URLS.CUSTOMER_TYPE_HISTORY(id)),
  customerInvoiceDateHistory: (id) => get(URLS.CUSTOMER_INVOICE_DATE_HISTORY(id)),
  customerOrderList: (id) => get(URLS.GET_CUSTOMER_ORDERING_LIST_BY_ID(id)),
  getCurFilters: () => get(URLS.CUR.GET_CUR_COLUMN_FILTERS),
  getCostUsageReportList: () => get(URLS.CUR.GET_CUR_LIST),
  saveCurData: (payload) => post(URLS.CUR.SAVE_CUR_DATA, payload),
  getPartnerList: () => get(URLS.ROLE.GET_PARTNERS_LIST),
  getSwtichRole: (params) => get(`${URLS.ROLE.GET_SWITCH_ROLE(params)}`),
  getCustomerData: (params) => get(`${URLS.ROLE.GET_CUSTOMER_CUSTOMER(params)}`),
  getCustomerForPartner: () => get(`${URLS.ROLE.GET_CUSTOMER_FOR_PARTNER}`),

  // Customer-Flow
  getUsageStatementConfig: () => get(URLS.USAGE_STATEMENTS.GET_CONFIG),

  // Customer-Account
  getCustomerList: (id) => get(URLS.CUSTOMER_ACCOUNT.GET_CUSTOMER_LIST(id)),

  // Permissions
  getAccessLevelList: (payload) => get(URLS.RBAC.GET_ACCESS_TYPES, payload),
  getPermissionGroupList: () => get(URLS.RBAC.GET_PERMISSION_GROUP_LIST),
  getModuleLevelList: (payload) => get(URLS.RBAC.GET_MODULE_LIST, payload),
  getModuleDashboardList: (payload) => get(URLS.RBAC.GET_MODULE_DASHBOARD_LIST, payload),
  getActionList: () => get(URLS.RBAC.GET_ACTION_TYPES),

  createUserPermission: (payload) => post(URLS.RBAC.CREATE_USER, payload),
  updateUserPermission: (payload) => put(URLS.RBAC.CREATE_USER, payload),
  getUserPermission: (payload) => get(URLS.RBAC.GET_PERMISSION_GROUP_DETAIL(payload)),
  deletePermissionGroup: (id) => deleteAPI(URLS.RBAC.DELETE_PERMISSION_GROUP(id)),

  // Permissions Tags
  getPermissionsTagsList: (params) => get(URLS.RBAC.TAGS.GET_ALL, params),
  getPermissionsTagsForCustomer: (params) => get(URLS.RBAC.TAGS.GET_FOR_CUSTOMER, params),
  getPermissionsTagsByID: (id) => get(URLS.RBAC.TAGS.GET_BY_ID(id)),
  deletePermissionTags: (id) => deleteAPI(URLS.RBAC.TAGS.DELETE(id)),
  createPermissionTags: (payload) => post(URLS.RBAC.TAGS.CREATE, payload),
  updatePermissionsTags: (payload) => put(URLS.RBAC.TAGS.UPDATE(payload.id), payload),
  getColumnListingData: () => get(URLS.GET_COLUMN_LISTING),
  getCurConfigPreview: (params) => get(URLS.CUR.GET_CUR_CONFIG_PREVIEW, params),
  payerAccountSync: () => get(URLS.PAYER_ACCOUNT_SYNC_API),
  // cudos database
  getCudosDatabaseCards: (payload) => get(URLS.CUDOS.GET_CARDS, payload),
  getTopResourcesTabs: (payload) => get(URLS.CUDOS.GET_TOP_RESOURCES_TABS, payload),
  getTopResources: (param) => get(URLS.CUDOS.GET_TOP_RESOURCES, param),
  getRecommendationSummary: (payload) => get(URLS.CUDOS.GET_RECOMMENDATION_SUMMARY, payload),
  getHighlightCardData: (payload) => get(URLS.CUDOS.GET_HIGHLIGHT_CARD_DATA, payload),
  getCostByInstanceTypeDatabase: (payload) => get(URLS.CUDOS.GET_COST_BY_INSTANCE_TYPE, payload),
  getTotalAccountsData: (payload) => get(URLS.CUDOS.GET_TAOTAL_ACCOUNTS, payload),
  getTop20CostUsage: (payload) => get(URLS.CUDOS.GET_TOP20_USAGE, payload),
  exportTop20CostUsage: (payload) =>
    get(URLS.CUDOS.EXPORT_TOP20_USAGE, payload, {
      responseType: "arraybuffer",
    }),
  getCostEngine: (payload) => get(URLS.CUDOS.GET_COST_ENGINE, payload),
  getCostRegion: (payload) => get(URLS.CUDOS.GET_COST_REGION, payload),
  getCostByPricingType: (payload) => get(URLS.CUDOS.GET_COST_BY_PRICING_TYPE, payload),
  getCostByUsageType: (payload) => get(URLS.CUDOS.GET_COST_BY_USAGE_TYPE, payload),
  getCostByStorageType: (payload) => get(URLS.CUDOS.GET_COST_BY_STORAGE_TYPE, payload),
  getCostByTop5Usage: (payload) => get(URLS.CUDOS.GET_COST_BY_TOP_5_USAGE, payload),
  getRiCoverageUsage: (payload) => get(URLS.CUDOS.GET_RI_COVERAGE_USAGE, payload),
  getCostByService: (payload) => get(URLS.CUDOS.GET_COST_BY_SERVICE, payload),
  getTopBucketByCost: (payload) => get(URLS.CUDOS.GET_TOP_BUCKET_BY_COST, payload),
  getS3CostBreakDown: (payload) => get(URLS.CUDOS.GET_S3_COST_BREAKDOWN, payload),
  getCostByOperation: (payload) => get(URLS.CUDOS.GET_COST_OPERATION, payload),
  getCostByOperatingSystem: (payload) => get(URLS.CUDOS.GET_COST_OPERATING_SYSTEM, payload),
  getCostByProcessor: (payload) => get(URLS.CUDOS.GET_COST_PROCESSOR, payload),
  getCostType: (payload) => get(URLS.CUDOS.GET_COST_TYPE, payload),
  getCostByProcessorArchitecture: (payload) =>
    get(URLS.CUDOS.GET_COST_PROCESSOR_ARCHITECTURE, payload),
  getCostByCPUAndMemory: (payload) => get(URLS.CUDOS.GET_COST_BY_CPU_AND_MEMORY, payload),
  getCPUAndMemoryCard: (payload) => get(URLS.CUDOS.CPU_AND_MEMORY_CARD, payload),

  exportTotalCostByAccount: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_ACCOUNT, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByRegion: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_REGION, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByEngine: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_ENGINE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByUsage: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_USAGE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByInstanceType: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_INSTANCE_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByPricingType: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_PRICING_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByService: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_SERVICE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostOfTypeByService: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_OF_TYPE_BY_SERVICE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostOfServiceByType: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_OF_SERVICE_BY_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportTopBucketByStorageCost: (payload) =>
    get(URLS.CUDOS.EXPORT_TOP_BUCKET_BY_STORAGE_COST, payload, {
      responseType: "arraybuffer",
    }),
  exportTopBucketByOperationCost: (payload) =>
    get(URLS.CUDOS.EXPORT_TOP_BUCKET_BY_OPERATION_COST, payload, {
      responseType: "arraybuffer",
    }),
  exportTopBucketByCost: (payload) =>
    get(URLS.CUDOS.EXPORT_TOP_BUCKET_BY_COST, payload, {
      responseType: "arraybuffer",
    }),

  exportByResources: (payload) =>
    get(URLS.CUDOS.EXPORT_BY_RESOURCES, payload, {
      responseType: "arraybuffer",
    }),
  exportS3CostBreakUp: (payload) =>
    get(URLS.CUDOS.EXPORT_S3_COST_BREAKUP, payload, {
      responseType: "arraybuffer",
    }),

  exportCostByOperation: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_OPERATION, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByOperatingSystem: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_OPERATING_SYSTEM, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByProcessor: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_PROCESSOR, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByProcessorArchitecture: (payload) =>
    get(URLS.CUDOS.EXPORT_TOTAL_COST_BY_PROCESSOR_ARCHITECTURE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByCPUAndMemory: (payload) =>
    get(URLS.CUDOS.EXPORT_COST_BY_CPU_AND_MEMORY, payload, {
      responseType: "arraybuffer",
    }),

  // CK_ANALYTICS - module apis
  getAnalyticsMarketData: () => get(URLS.CK_ANALYTICS.MARKETPLACE_RI.GET_FILTER_VALUES),
  getAnalyticsMarketTableData: (payload, options) =>
    post(URLS.CK_ANALYTICS.MARKETPLACE_RI.GET_MARKETPLACE_RI_DATA, payload, options),
  exportAnalyticsMarketTableData: (payload) =>
    post(URLS.CK_ANALYTICS.MARKETPLACE_RI.EXPORT_EXCEL_SHEET, payload, {
      responseType: "arraybuffer",
    }),
  getDashboardDates: () => get(URLS.CK_ANALYTICS.DASHBOARD_DATES),

  // TTN-RI vs VRI - api Starts Here
  getAnalyticsTTNRIvsVRItableData: () => get(URLS.CK_ANALYTICS.TTN_RI_VS_VRI.GET_TABLE_DATA),
  getAnalyticsRIDetails: (payload, riType) =>
    post(URLS.CK_ANALYTICS.TTN_RI_VS_VRI.RI_DETAILS(riType), payload), // riType: "vRiDetails" || "ttnRiDetails"
  ttnRiAndVriExportGet: (riTypeWithPayer, payload = {}) =>
    get(URLS.CK_ANALYTICS.TTN_RI_VS_VRI.EXPORT(riTypeWithPayer), payload, {
      responseType: "arraybuffer",
    }), // riType: "payer/vRiDetails" || "payer/ttnRiDetails"
  ttnRiAndVriExportPost: (riTypeWithPayer, payload = {}) =>
    post(URLS.CK_ANALYTICS.TTN_RI_VS_VRI.EXPORT(riTypeWithPayer), payload, {
      responseType: "arraybuffer",
    }), // riType: "payer/vRiDetails" || "payer/ttnRiDetails"
  // TTN-RI vs VRI - api Ends Here

  // Error Index Codes Analytics - api

  getErrorIndexCodes: () => get(URLS.CK_ANALYTICS.ERROR_INDEX),
  // Coverage - apis starts here
  getAllServicesForCoverage: (payload) => get(URLS.CK_ANALYTICS.COVERAGE.RI_COVERAGE, payload),
  getDataInfoForCoverage: (payload) => get(URLS.CK_ANALYTICS.COVERAGE.DATA_INFO, payload),
  exportCoverageExcel: (payload) =>
    get(URLS.CK_ANALYTICS.COVERAGE.EXPORT, payload, {
      responseType: "arraybuffer",
    }),

  // Coverage - apis ends here
  // Unused Inventory - apis starts here
  getAllServicesForUnusedInventory: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.WASTAGE, payload),
  getDataForUnusedInventory: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.WASTAGE_DATA, payload),
  exportWastageExcel: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.WASTAGE_EXPORT, payload, {
      responseType: "arraybuffer",
    }),
  getUpComingRiExpiry: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_UP_COMING_RI_EXPIRY, payload),
  getInventoryList: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_INVENTORY_DATA, payload),
  getOnDemandCostDaily: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_ON_DEMAND_COST_DAILY, payload),
  getMinOnDemandCostDaily: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_MIN_OD_COST_DAILY, payload),
  getRiWastageHourlyData: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_RI_WASTAGE_HOURLY_DATA, payload),
  getRiWastageTrendSummary: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.GET_RI_WASTAGE_TRAND_DATA, payload),
  exportRiInventoryData: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.EXPORT_RI_INVENTORY_TABLE_DATA, payload, {
      responseType: "arraybuffer",
    }),
  exportWastageDetails: (payload) =>
    get(URLS.CK_ANALYTICS.UNUSED_INVENTORY.EXPORT_WASTAGE_DETAILS, payload, {
      responseType: "arraybuffer",
    }),

  createAlert: (payload) =>
    post(URLS.CK_ANALYTICS.NOTIFICATIONS.ON_DEMAND_SPEND.CREATE_ALERT, payload),

  deleteAlert: (alertId) =>
    deleteAPI(URLS.CK_ANALYTICS.NOTIFICATIONS.ON_DEMAND_SPEND.DELETE_ALERT(alertId)),

  updateAlert: (payload) =>
    post(URLS.CK_ANALYTICS.NOTIFICATIONS.ON_DEMAND_SPEND.UPDATE_ALERT, payload),

  getAlertDetail: (alertId) =>
    get(URLS.CK_ANALYTICS.NOTIFICATIONS.ON_DEMAND_SPEND.GET_ALERT_DETAILS(alertId)),

  // Unused Inventory  - apis ends here

  // Analytics Notifications starts here
  getOnDemandNotificationDetails: () =>
    get(URLS.CK_ANALYTICS.NOTIFICATIONS.ON_DEMAND_SPEND.GET_LIST),
  // Analytics Notifications ends here

  // Hourly Grid - compute
  getHourlyGridFilterValues: (payload) => get(URLS.HOURLY_GRID_COMPUTE.GET_FILTER, payload),
  getHourlyGridFilterValuesByFilterName: (payload, id) =>
    post(URLS.HOURLY_GRID_COMPUTE.GET_FILTERS_BY_FILTERNAME(id), payload),
  getHourlyGridTagFilterValuesByFilterName: (payload) =>
    get(URLS.HOURLY_GRID_COMPUTE.GET_TAG_FILTERS_BY_FILTERNAME, payload),
  getAggregateCost: (payload) => get(URLS.HOURLY_GRID_COMPUTE.GET_AGGREGATE_COST, payload),
  getViewBy: (payload) => get(URLS.HOURLY_GRID_COMPUTE.GET_VIEW_BY, payload),
  getGroupByData: (payload, id) => post(URLS.HOURLY_GRID_COMPUTE.GET_GROUP_DATA(id), payload),
  getGroupBy: (id) => get(URLS.HOURLY_GRID_COMPUTE.GET_GROUP_BY(id)),
  saveHourlyReport: (payload) => get(URLS.HOURLY_GRID_COMPUTE.SAVE_REPORT, payload),
  updateHourlyReport: (payload) => get(URLS.HOURLY_GRID_COMPUTE.UPDATE_REPORT, payload),
  getSavedReportListing: (type, payload) => get(URLS.CK_LENS.SAVED_REPORTS_LISTING(type), payload),
  getHeatMapData: (payload, id) => post(URLS.HOURLY_GRID_COMPUTE.GET_HEATMAP_LISTING(id), payload),
  exportHeatMapData: (payload, id) =>
    post(URLS.HOURLY_GRID_COMPUTE.EXPORT_HEATMAP_DATA(id), payload, {
      responseType: "arraybuffer",
    }),
  exportHeatMapDataV2: (payload, id) =>
    post(URLS.HOURLY_GRID_COMPUTE.EXPORT_HEATMAP_DATA_V2(id), payload),
  getMarketPlaceProcessedData: () =>
    get(URLS.CK_ANALYTICS.MARKETPLACE_RI_BULK_UPLOAD.GET_PROCCESSED_FILE_DATA),
  getMarketPlaceProcessedFile: (fileId) =>
    post(
      `${URLS.CK_ANALYTICS.MARKETPLACE_RI_BULK_UPLOAD.DOWNLOAD_PROCCESSED_EXCEL_SHEET}/${fileId}`,
      {},
      { responseType: "arraybuffer" }
    ),
  uploadMarketPlaceBulkFilterExcel: (payload) =>
    post(URLS.CK_ANALYTICS.MARKETPLACE_RI_BULK_UPLOAD.UPLOAD_FILTERS_EXCEL_SHEET, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  getFilteredRiData: (id) =>
    get(`${URLS.CK_ANALYTICS.MARKETPLACE_RI_BULK_UPLOAD.GET_PROCCESSED_DATA}/${id}`),

  getRiInventoryTabs: () => get(`${URLS.CK_ANALYTICS.RI_INVENTORY.GET_TABS_DATA}`),
  getRiInventoryArnData: (params) => get(`${URLS.CK_ANALYTICS.RI_INVENTORY.GET_ARN_DATA}`, params),
  getRiInventoryDetails: (params) =>
    get(URLS.CK_ANALYTICS.RI_INVENTORY.GET_INVENTORY_DETAILS, params),
  getRiInventoryEC2Details: (params) =>
    get(URLS.CK_ANALYTICS.RI_INVENTORY.GET_INVENTORY_EC2_DETAILS, params),
  exportRiInventoryEC2Details: (payload) =>
    get(URLS.CK_ANALYTICS.RI_INVENTORY.DOWNLOAD_EC2, payload, {
      responseType: "arraybuffer",
    }),
  exportRiInventoryDetails: (payload) =>
    get(URLS.CK_ANALYTICS.RI_INVENTORY.DOWNLOAD, payload, {
      responseType: "arraybuffer",
    }),

  getHourlyDashboardDates: () => get(URLS.HOURLY_GRID_COMPUTE.GET_DASHBOARD_DATES()),

  // VRI additional Discount APIs
  getAdditionalDiscountlisting: (id) => get(URLS.ADDITIONAL_DISCOUNT.GET_DATA(id)),
  getCommentHistory: (customerId, id) =>
    get(URLS.ADDITIONAL_DISCOUNT.COMMENT_HISTORY(customerId, id)),
  updateAdditionalDiscountModal: (customerId, payload) =>
    put(URLS.ADDITIONAL_DISCOUNT.GET_DATA(customerId), payload),
  getServiceList: () => get(URLS.ADDITIONAL_DISCOUNT.GET_SERVICE_LIST),
  saveAdditonalDiscount: (payload, id) => post(URLS.ADDITIONAL_DISCOUNT.SAVE_DISCOUNT(id), payload),

  // VRI for Lens
  saveBuyAgainDetails: (service, customerId, payload) =>
    post(URLS.SAVE_BUY_AGAIN_DETAILS(service, customerId), payload),
  getRevenueData: (subDashboard, monthlyOrQuaterly, payload) =>
    post(URLS.CK_ANALYTICS.REVENUE.getData(subDashboard, monthlyOrQuaterly), payload),
  getRevenueDataByType: (subDashboard, payload) =>
    post(URLS.CK_ANALYTICS.REVENUE.getDataByType(subDashboard), payload),
  exportRevenueData: (subDashboard, payload) =>
    post(URLS.CK_ANALYTICS.REVENUE.exportData(subDashboard), payload, {
      responseType: "arraybuffer",
    }),
  getHourlySavedReportsTabs: (param) => get(URLS.SAVED_REPORT_TAB, param),

  // Customers V2
  getCustomerListing: (params) => get(URLS.CUSTOMERS.ALL, params),
  getCustomerTypes: () => get(URLS.CUSTOMERS.TYPE),
  savelastInvoice: (payload) => put(URLS.CUSTOMERS.SAVE_LASTINVOICE, payload),
  getCustomerEffectiveDate: (payload) => post(URLS.CUSTOMERS.GET_EFFECTIVE_DATE, payload),
  getAnalyticsCovergeApis: {
    getCustomerWiseDetails: (paload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_CUSTOMER_WISE_DETAILS, paload),
    getRiExpiry: (paload) => get(URLS.CK_ANALYTICS.COVERAGE.GET_RI_EXPIRY, paload),
    getCustomerWiseDetailsOtherPayer: (paload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_CUSTOMER_WISE_DETAILS_OTHER_PAYERS, paload),
    getRiExpiryOtherPayer: (paload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_RI_EXPIRY_OTHER_PAYERS, paload),
    getRiPriceTableData: (payload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_RI_PRICE_TABLE_DATA, payload),
    getCoverageTrandData: (paload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_COVERAGE_TRAND_DATA, paload),
    getRiCoverageCart: (payload) =>
      get(URLS.CK_ANALYTICS.COVERAGE.GET_RI_COVERAGE_CART(""), payload),
    setRiCoverageCart: (payload, param) =>
      post(URLS.CK_ANALYTICS.COVERAGE.GET_RI_COVERAGE_CART(param), payload),
  },

  // CK TUNER
  tuner: {
    getCkTunerHomePageData: (startDate, endDate, mavId) =>
      get(URLS.CK_TUNER.GET_HOMEPAGE_DATA(startDate, endDate, mavId)),
    getCKTunerAuditData: (path, payload, params) =>
      post(URLS.CK_TUNER.GET_AUDIT_DATA + path, payload, params),
    getCKTunerRecommendationsExecuted: (params, cache) =>
      get(URLS.CK_TUNER.GET_AUDIT_DATA, params, {}, cache),
    getCKTunerRecommendations: (params, cache) =>
      get(URLS.CK_TUNER.GET_RECOMMENDATIONS_DATA, params, {}, cache),
    getCKTunerWarRecommendations: (params) =>
      get(URLS.CK_TUNER.GET_WAR_RECOMMENDATIONS_DATA, params),
    getCKTunerWellManagedResources: (params, cache) =>
      get(URLS.CK_TUNER.GET_WMR_DATA, params, {}, cache),
    getCKTunerWarWellManagedResources: (params = {}) => get(URLS.CK_TUNER.GET_WAR_WMR_DATA, params),
    getCKTunerRecommendationsCards: (params, cache) =>
      get(URLS.CK_TUNER.GET_RECOMMENDATIONS_CARDS_DATA, params, {}, cache),
    getCKTunerWarRecommendationsCards: (params) =>
      get(URLS.CK_TUNER.GET_RECOMMENDATIONS_WAR_CARDS_DATA, params),
    getCKTunerRecommendationsSavings: (params, cache) =>
      get(URLS.CK_TUNER.GET_RECOMMENDATIONS_SAVINGS, params, {}, cache),
    getCKTunerWarRecommendationsSavings: (params) =>
      get(URLS.CK_TUNER.GET_WAR_RECOMMENDATIONS_SAVINGS, params),
    getCKTunerOnboardedAccountsDetails: (params, cache) =>
      get(URLS.CK_TUNER.GET_ONBOARDED_ACCOUNTS_DETAILS, params, {}, cache),
    getCkTunerPermissionTemplates: () => get(URLS.CK_TUNER.GET_PERMISSION_TEMPLATES),
    validateCkTunerAccount: (id) => get(URLS.CK_TUNER.ACTION_VALIDATE(id)),
    validateCkTunerGcpProjects: (id) => get(URLS.CK_TUNER.GCP_ACTION_VALIDATE(id)),
    validateCkTunerAccountOnBoard: (payload) =>
      post(URLS.CK_TUNER.ACTION_VALIDATE_ONBOARD, payload),
    unlinkCkTunerAccount: (id) => deleteAPI(URLS.CK_TUNER.ACTION_UNLINK(id)),
    getAccountsListing: (params, moduleName) =>
      get(URLS.CK_TUNER.GET_ACCOUNTS_LISTING(moduleName), params),
    toggleScheduler: (payload) => post(URLS.CK_TUNER.TOGGLE_SCHEDULER, payload),
    updateScheduler: (payload) => post(URLS.CK_TUNER.UPDATE_SCHEDULER, payload),
    userOnboardAttempts: (payload) => post(URLS.CK_TUNER.USER_ONBOARD_ATTEMPTS, payload),
    userOnboardAttemptsPoll: (id) => get(URLS.CK_TUNER.USER_ONBOARD_ATTEMPTS_POLL(id)),
    getCustomTrustPolicy: (payload) => post(URLS.CK_TUNER_ONBOARDING.CUSTOM_TRUST_POLICY, payload),
    getInlineTrustPolicy: (payload) => post(URLS.CK_TUNER_ONBOARDING.INLINE_POLICY, payload),
    getManagedPolicy: (payload) => post(URLS.CK_TUNER_ONBOARDING.MANAGED_POLICY, payload),
    ckTunerOnboard: (payload) => post(URLS.CK_TUNER_ONBOARDING.ONBOARD, payload),
    ckTunerManagedPolicy: (payload) =>
      post(URLS.CK_TUNER_ONBOARDING.MANAGED_INLINE_POLICY, payload),
    ckTunerUpdateAccess: (payload) => patch(URLS.CK_TUNER.UPDATE_ACCESS, payload),
    getCkTunerSchedulerListing: (selectedTab, mavId, cache) =>
      get(URLS.CK_TUNER.SCHEDULER_GET_LISTING(selectedTab, mavId), {}, {}, cache),
    getCkTunerCardsGraph: (selectedTab, startDate, endDate, mavId, cache) =>
      get(
        URLS.CK_TUNER.SCHEDULER_GET_CARDS_GRAPHS(selectedTab, startDate, endDate, mavId),
        {},
        {},
        cache
      ),
    ckTunerToggleScheduler: (payload, service) =>
      post(URLS.CK_TUNER.SCHEDULER_TOGGLE(service), payload),
    ckTunerToggleSchedulerService: (payload, service, type) =>
      post(URLS.CK_TUNER.SCHEDULER_SERVICE_TOGGLE(service, type), payload),
    ckTunerUpdatedSchedulerConfig: (payload, service) =>
      post(URLS.CK_TUNER.SCHEDULER_CONFIG_UPDATE(service), payload),
    ckTunerBulkToggleScheduler: (payload, service) =>
      post(URLS.CK_TUNER.SCHEDULER_BULK_TOGGLE(service), payload),
    getAllAccountIdWithNameV2: (param) =>
      get(URLS.CK_TUNER.GET_ACCOUNT_ID_WITH_ACCOUNT_NAME_V2, param),
    getTunerSummaryCardsData: (cache) => get(URLS.CK_TUNER.SUMMARY.GET_CARDS_DATA, {}, {}, cache),
    getTunerSummaryTopRecommendations: (cache) =>
      get(URLS.CK_TUNER.SUMMARY.GET_TOP_RECOMMENDATIONS, {}, {}, cache),
    getSavingsGraphData: (view, type, cache) =>
      get(URLS.CK_TUNER.SUMMARY.SAVINGS_GRAPH(view, type), {}, {}, cache),
    getGlanceGraphData: (type, cache) =>
      get(URLS.CK_TUNER.SUMMARY.GLANCE_GRAPH(type), {}, {}, cache),
    getAnalysisGraphData: (type, cache) =>
      get(URLS.CK_TUNER.SUMMARY.ANALYSIS_GRAPH(type), {}, {}, cache),

    getTunerDashborad: () => get(URLS.CK_TUNER.GET_TUNER_DASHBORD),
    getTunerGrid: () => get(URLS.CK_TUNER.TUNER_GRID),
    updateTunerGrid: (payload) => post(URLS.CK_TUNER.TUNER_GRID, payload),
    getEnabledTunerFeatures: (type, options) =>
      get(
        `${URLS.CK_TUNER.ADMIN.GET_ENABLED_FEATURES}${getModule(type, "restrictionType")}`,
        {},
        options
      ),
    getSpotbotCardsGraphs: (payload, cache) =>
      post(URLS.CK_TUNER.SPOT_BOT.GET_CARDS_GRAPH_DATA, payload, {}, cache),
    getTunerSpotBotListing: (payload, cache) =>
      post(URLS.CK_TUNER.SPOT_BOT.GET_LISTING, payload, {}, cache),
    toggleTunerSpotBot: (payload) => post(URLS.CK_TUNER.SPOT_BOT.TOGGLE_SPOT, payload),
    toggleTunerSpotBotBulk: (payload, type) =>
      post(URLS.CK_TUNER.SPOT_BOT.TOGGLE_SPOT_BULK(type), payload),
    getTunerOnboardingJson: (params) => get(URLS.CK_TUNER.ONBOARDING.GET_JSON, params),

    getEventHistory: (startDate, endDate) => get(URLS.CK_TUNER.EVENT_HISTORY(startDate, endDate)),
    updateAccountEnvironment: (payload) => patch(URLS.CK_TUNER.UPDATE_ACCOUNT_ENV, payload),
    exportRecommendation: (params) =>
      get(URLS.CK_TUNER.EXPORT_RECOMMENDATIONS, params, {
        responseType: "arraybuffer",
      }),
    accountRemediation: {
      getAttemptId: (payload) => post(URLS.CK_TUNER.ACCOUNT_REMEDIATION.ATTEMPT_START(payload)),
      poll: (attemptId) => get(URLS.CK_TUNER.ACCOUNT_REMEDIATION.ATTEMPT_STATUS(attemptId)),
      history: (payload) => post(URLS.CK_TUNER.ACCOUNT_REMEDIATION.HISTORY, payload),
    },
    recommendationRemediation: {
      getRemediationData: (payload) => post(URLS.CK_TUNER.REMEDIATION.GET_REMEDIATION_ID, payload),
      getRemediationStatus: (id) => get(URLS.CK_TUNER.REMEDIATION.GET_REMEDIATION_STATUS(id)),
      getFactorData: (params, cache) =>
        get(URLS.CK_TUNER.REMEDIATION.GET_FACTOR_DATA, params, {}, cache),
    },
    gcp: {
      onboarding: {
        getAttemptId: () => post(URLS.CK_TUNER.GCP.ONBOARDING.ATTEMPT),
        poll: (attemptId) => get(URLS.CK_TUNER.GCP.ONBOARDING.POLL(attemptId)),
        // poll: () => get(URLS.CK_TUNER.GCP.ONBOARDING.POLL),
      },
    },
  },

  // Cudos EKS APIs
  getSummaryCards: (payload) => get(URLS.CUDOS_EKS.SUMMARY_CARDS, payload),
  getCostCluster: (payload) => get(URLS.CUDOS_EKS.GET_COST_CLUSTER, payload),
  getCostByNameSpace: (payload) => get(URLS.CUDOS_EKS.GET_COST_NAMESPACE, payload),
  getCostByPurchase: (payload) => get(URLS.CUDOS_EKS.GET_COST_PURCHASE, payload),
  exportTotalCostByCluster: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOTAL_COST_BY_CLUSTER, payload, {
      responseType: "arraybuffer",
    }),
  exportTotalCostByNamespace: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOTAL_COST_BY_NAMESPACE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByPurchaseTypeEKS: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOTAL_COST_BY_PURCHASE_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByUsageTypeEKS: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOTAL_COST_BY_USAGE_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportCostByStorageTypeEKS: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOTAL_COST_BY_STORAGE_TYPE, payload, {
      responseType: "arraybuffer",
    }),
  exportTop20CostUsageTableData: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOP_20_USAGE, payload, {
      responseType: "arraybuffer",
    }),
  exportPollingTop20CostUsageTableData: (payload) =>
    get(URLS.CUDOS_EKS.EXPORT_TOP_20_USAGE_V2, payload),
  getCustomTrustPolicy: (externalId, accessType) =>
    get(URLS.CK_TUNER_ONBOARDING.CUSTOM_TRUST_POLICY(externalId, accessType)),
  getInlineTrustPolicy: (payload) => post(URLS.CK_TUNER_ONBOARDING.INLINE_POLICY, payload),
  getManagedPolicy: (IAMRole, accessType) =>
    get(URLS.CK_TUNER_ONBOARDING.MANAGED_POLICY(IAMRole, accessType)),
  ckTunerOnboard: (payload) => post(URLS.CK_TUNER_ONBOARDING.ONBOARD, payload),
  ckTunerManagedPolicy: (payload) => post(URLS.CK_TUNER_ONBOARDING.MANAGED_INLINE_POLICY, payload),
  ckTunerUpdateAccess: (payload) => patch(URLS.CK_TUNER.UPDATE_ACCESS, payload),
  getCkTunerSchedulerListing: (selectedTab, mavId) =>
    get(URLS.CK_TUNER.SCHEDULER_GET_LISTING(selectedTab, mavId)),
  getCkTunerCardsGraph: (selectedTab, startDate, endDate, mavId) =>
    get(URLS.CK_TUNER.SCHEDULER_GET_CARDS_GRAPHS(selectedTab, startDate, endDate, mavId)),
  ckTunerToggleScheduler: (payload, service) =>
    post(URLS.CK_TUNER.SCHEDULER_TOGGLE(service), payload),
  ckTunerToggleSchedulerService: (payload, service, type) =>
    post(URLS.CK_TUNER.SCHEDULER_SERVICE_TOGGLE(service, type), payload),
  ckTunerUpdatedSchedulerConfig: (payload, service) =>
    post(URLS.CK_TUNER.SCHEDULER_CONFIG_UPDATE(service), payload),
  ckTunerBulkToggleScheduler: (payload, service) =>
    post(URLS.CK_TUNER.SCHEDULER_BULK_TOGGLE(service), payload),
  getAllAccountIdWithNameV2: (param) =>
    get(URLS.CK_TUNER.GET_ACCOUNT_ID_WITH_ACCOUNT_NAME_V2, param),

  // Codegen
  getAllTemplateList: () => get(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.GET_TEMPLATE_LIST),
  getAllTemplateVersionList: (templateName) =>
    get(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.GET_TEMPLATE_VERSION_LIST(templateName)),
  toggleTemplateVersion: (payload) =>
    post(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.TOGGLE_TEMPLATE_VERSION, payload),
  toggleViewVersion: (params) => post(URLS.DYNAMIC_VIEWS.CK_VIEW.TOGGLE_VIEW_VERSION(params)),
  getSchemaByType: (payload) => get(URLS.DYNAMIC_VIEWS.GET_SCHEMA_BY_TYPE, payload),
  saveDynamicTemplate: (payload) => post(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.SAVE_TEMPLATE, payload),

  getAllViewList: () => get(URLS.DYNAMIC_VIEWS.CK_VIEW.GET_VIEW_LIST),
  getAllViewVersionList: (viewName) =>
    get(URLS.DYNAMIC_VIEWS.CK_VIEW.GET_VIEW_VERSION_LIST(viewName)),

  validateView: (payload) => post(URLS.DYNAMIC_VIEWS.CK_VIEW.VALIDATE_VIEW, payload),
  updateTemplate: (payload) => post(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.UPDATE_TEMPLATE, payload),
  getTemplateVersionByname: (payload) =>
    get(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.GET_TEMPLATE_BY_VERSION, payload),
  updateView: (payload, viewName) => put(URLS.DYNAMIC_VIEWS.CK_VIEW.UPDATE_VIEW(viewName), payload),
  saveDynamicView: (payload) => post(URLS.DYNAMIC_VIEWS.CK_VIEW.SAVE_VIEW, payload),
  getAllAssociatedView: ({ name, version }) =>
    get(URLS.DYNAMIC_VIEWS.GET_ALL_ASSOCIATED_VIEW(name, version)),
  getViewVersionByName: (name, version) =>
    get(URLS.DYNAMIC_VIEWS.CK_VIEW.GET_VIEW_BY_VERSION(name, version)),

  getAllTaskList: () => get(URLS.DYNAMIC_VIEWS.CK_TASK.GET_TASK_LIST),
  getAirflowPipelineList: () => get(URLS.DYNAMIC_VIEWS.CK_PIPELINE.GET_PIPELINE_LIST),
  togglePipeline: (payload) => put(URLS.DYNAMIC_VIEWS.CK_PIPELINE.TOGGLE_PIPELINE, payload),

  deletePipeline: (id) => deleteAPI(URLS.DYNAMIC_VIEWS.CK_PIPELINE.DELETE_PIPELINE(id)),
  savePipline: (payload) => post(URLS.DYNAMIC_VIEWS.CK_PIPELINE.SAVE_PIPELINE, payload),
  updatePipeline: (payload) => put(URLS.DYNAMIC_VIEWS.CK_PIPELINE.SAVE_PIPELINE, payload),
  validatePipline: (payload) => post(URLS.DYNAMIC_VIEWS.CK_PIPELINE.VALIDATE_PIPELINE, payload),
  saveTask: (payload) => post(URLS.DYNAMIC_VIEWS.CK_TASK.SAVE_TASK, payload),
  deleteTask: (id) => deleteAPI(URLS.DYNAMIC_VIEWS.CK_TASK.DELETE_TASK(id)),
  viewTask: (id) => get(URLS.DYNAMIC_VIEWS.CK_TASK.VIEW_TASK(id)),

  getTaskTypeDropdownMenu: () => get(URLS.DYNAMIC_VIEWS.CK_TASK.TASK_TYPE_MENU_ITEM),
  getPipelineConfig: (id) => get(URLS.DYNAMIC_VIEWS.CK_PIPELINE.GET_PIPELINE_CONFIG, id),
  getPipelineTags: () => get(URLS.DYNAMIC_VIEWS.CK_PIPELINE.GET_PIPELINE_TAGS),

  getPipelineWorkflow: (id) => get(URLS.DYNAMIC_VIEWS.CK_WORKFLOW.PIPELINE_WORKFLOW, id),
  savePipelineWorkflow: (payload) =>
    post(URLS.DYNAMIC_VIEWS.CK_WORKFLOW.PIPELINE_WORKFLOW, payload),
  saveAsDraftView: (payload) => post(URLS.DYNAMIC_VIEWS.CK_VIEW.SAVE_AS_DRAFT_VIEW, payload),
  deleteTemplateVersion: ({ name, version }) =>
    deleteAPI(URLS.DYNAMIC_VIEWS.CK_TEMPLATE.DELETE_VERSION(name, version)),
  deleteViewVersion: ({ name, version }) =>
    deleteAPI(URLS.DYNAMIC_VIEWS.CK_VIEW.DELETE_VERSION(name, version)),
  getAllAssociatedVersion: (name) => get(URLS.DYNAMIC_VIEWS.GET_ALL_ASSOCIATED_VERSION(name)),
};

export default commonService;
