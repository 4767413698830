import React from "react";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";
import Checkbox from "@material-ui/core/Checkbox";
import { noop } from "../../../../Utils/commonUtils";
import { addDataToDb, getDataFromDb, openDB } from "../../../../Utils/indexedDBUtils";
import { sortByState } from "../../../Dashboard/CustomerManagement/CreateCustomer/utils";
import { ReactComponent as MessageIcon } from "../../../Dashboard/CustomerManagement/CreateCustomer/Credits/Icons/ic-message.svg";

const checkboxHeaderComponent = () => ({
  Header: "",
  accessor: "checkboxId",
  bodyClass: "whitespace-nowrap normal-case !sticky left-0 z-10 bg-white",
  headerClass: "whitespace-nowrap !sticky left-0 !z-20 top-0",
  props: {
    doNotAddColumnSelector: true,
    disabledMenuItem: true,
    hideOnRO: true,
  },
});

export const VRI_ADDITIONAL_DISCOUNT = (getCommentHistory = noop) => [
  {
    ...checkboxHeaderComponent(),
  },
  {
    Header: "Service(s)",
    accessor: "service",
    searchable: true,
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !sticky left-[30px] bg-[#ffff] z-10 !text-left",
    headerClass: "whitespace-nowrap !sticky left-[30px] !z-20 !text-left",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Term",
    accessor: "term",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Discount (%)",
    accessor: "discount",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "End Date",
    accessor: "endDate",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Comments",
    accessor: "comments",
    // sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    searchable: true,
    render: (item) =>
      item?.commentsAvailable ? (
        <button type="button" className="w-full !block" onClick={() => getCommentHistory(item)}>
          <MessageIcon />
        </button>
      ) : (
        <div className="w-full">- - -</div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
  {
    Header: "Last Updated At",
    accessor: "lastUpdatedAt",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
  {
    Header: "Last Updated By",
    accessor: "lastUpdatedBy",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
  },
];

// Function to set IndexDB column selector
export const setIndexDbColumnSelector = (columnSelectorKey, updatedColumnHeaders) => {
  const dbOpen = openDB();
  if (dbOpen) {
    // Retrieve data from IndexDB with the columnSelectorKey
    getDataFromDb(columnSelectorKey, (d) => {
      const obj = { ...d };
      // If no data is found for particular tab or there is no data for this particular table
      if ((d && !Object.keys(d).includes("ec2")) || !d) {
        // Filter the updatedColumnHeaders to get an array of selected headers
        const selectedHeaders = updatedColumnHeaders
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor);
        obj.ec2 = { columHeaders: selectedHeaders };

        // Add the updated data back to IndexDB under the columnSelectorKey
        addDataToDb(columnSelectorKey, obj);
      }
    });
  }
};

export const toggleConfigList = (list, active) => {
  const updatedList = list
    ?.filter((item) => (active ? item?.status === "Active" : item))
    ?.map((item) => ({
      ...item,
      checked: item?.checked ?? false,
      enable: item?.status === "Active",
    }));
  return sortByState(updatedList, "status");
};

export const restructureAdditionalDiscountTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item?.status === "Expired" ||
              item?.status === "Nullified" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item?.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRow", item)}
            disableRipple
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
  });
};

export const transformAdditionalDiscountData = (data) =>
  data?.map((item) => ({
    ...item,
    startDate: item?.startDate ? moment(item?.startDate).format("DD-MMM-YYYY") : "",
    endDate: item?.endDate ? moment(item?.endDate).format("DD-MMM-YYYY") : "",
    createdAt: item?.createdAt ? moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm") : "",
    lastUpdatedAt: item?.lastUpdatedAt
      ? moment(item?.lastUpdatedAt).format("DD-MMM-YYYY, HH:mm")
      : "",
  }));

const changeValidationStructure = (formConfig) =>
  formConfig?.map((item) => item?.config || item).flat();

export default changeValidationStructure;
