import React, { useState, useEffect } from "react";
import "./style.scss";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withRouter from "../../../../Common/WithRouter";
import actions from "../../../../../Actions/index";
import { ReactComponent as HomeOutlinedIcon } from "../../../../../Assets/icons/others/iconHomeV2.svg";

function CudosHeader(props) {
  const {
    breadcrumsData,
    headerName,
    selectedDateProp = () => {},
    accountStartDate,
    accountEndDate,
    navigate,
    isLoading,
    showMonthDropdown = true,
    lensVersion = false,
  } = props;

  const [selectedDate, setSelectedDate] = useState(null);

  const startDate = moment(accountStartDate);
  const endDate = moment(accountEndDate);
  const result = [];

  if (endDate.isBefore(startDate)) {
    throw new Error("End date must be greated than start date.");
  }

  while (moment(startDate.format("YYYY-MM-01")).isBefore(endDate)) {
    result.push(startDate.format("YYYY-MM-01"));
    startDate.add(1, "month");
  }
  const dataQuery = localStorage.getItem("query");

  const updatedUrl = new URLSearchParams(window.location.search);
  const updatedDate = moment(`${updatedUrl.get("year")}/${updatedUrl.get("month")}/01`).format(
    "YYYY-MM-01"
  );

  useEffect(() => {
    if (
      !!accountEndDate &&
      updatedUrl?.get("year") &&
      updatedUrl?.get("month") &&
      !moment(`${updatedUrl.get("year")}-${updatedUrl.get("month")}-01`).isBetween(
        moment(accountStartDate),
        moment(accountEndDate),
        "month",
        "[]"
      )
    ) {
      const temp = accountEndDate;
      const searchQuery = `?month=${moment(temp).format("MM")}&year=${moment(temp).format("YYYY")}`;
      localStorage.setItem("query", searchQuery);
      const searchURL = new URLSearchParams(window.location.search);
      const selected = searchURL.get("selected");
      navigate({
        search: `?month=${moment(temp).format("MM")}&year=${moment(temp).format("YYYY")}${
          selected ? `&selected=${selected}` : ""
        }`,
      });
      const date = new URLSearchParams(window.location.search);
      const updatedselectedDate = moment([date.get("year"), date.get("month") - 1]).format(
        "YYYY-MM-01"
      );
      setSelectedDate(updatedselectedDate);
      selectedDateProp(temp);
    } else if (dataQuery !== null) {
      // need to reverify for data transfer and s3
      const date = new URLSearchParams(dataQuery);
      const searchQuery = `?month=${date.get("month")}&year=${date.get("year")}`;
      localStorage.setItem("query", searchQuery);
      const month = date.get("month");
      const year = date.get("year");
      const formmatData = `${year}-${month}-01`;
      setSelectedDate(formmatData);
    } else {
      setSelectedDate(
        moment(`${updatedUrl.get("year")}/${updatedUrl.get("month")}/01`).format("YYYY-MM-01")
      );
    }
  }, [updatedUrl, updatedDate, accountEndDate, accountStartDate]);

  const handleDateChange = (event) => {
    const {
      target: { value },
    } = event;
    const searchQuery = `?month=${moment(value).format("MM")}&year=${moment(value).format("YYYY")}`;
    localStorage.setItem("query", searchQuery);
    const searchURL = new URLSearchParams(window.location.search);
    const selected = searchURL.get("selected");
    navigate({
      search: `?month=${moment(value).format("MM")}&year=${moment(value).format("YYYY")}${
        selected ? `&selected=${selected}` : ""
      }`,
    });
    setSelectedDate(value);
    selectedDateProp(event);
  };

  return (
    <div className="Component_header_wrapper">
      <div>
        <div className="breadcrums_wrapper">
          <Breadcrumbs separator={<ChevronRightIcon />}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="/dashboard/billingSummary"
            >
              <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            {!!breadcrumsData.length &&
              breadcrumsData.map((item) => <p className="small-text-breadcrum">{item}</p>)}
          </Breadcrumbs>
        </div>
        <h3 className="header_text">{headerName}</h3>
      </div>
      {showMonthDropdown ? (
        <div
          className={`billing_console_header wrapper ${isLoading ? "disabled" : ""}`}
          id="dropdownMenu1"
        >
          <div className={`month-dropdownCont ${lensVersion ? "lens-filter-style" : ""} `}>
            <label htmlFor="dropdown" className="dropdown ">
              Month
            </label>
            <select
              id="dropdown"
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              className="custom_selectbox"
            >
              {result
                .slice(0)
                .reverse()
                .map((item) => (
                  <option value={item} id={moment(item).format("MMMM_YYYY")}>
                    {moment(item).format("MMMM YYYY")}
                  </option>
                ))}
            </select>
          </div>
        </div>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  payerDetails: state.HeaderReducer,
  accountDate: state.AccountDateReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CudosHeader));
