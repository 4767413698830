import React from "react";
import ReservationHeaderV2 from "../../ReservationHeaderV2";
import CostBreakupHeaderV2 from "../../CostBreakupHeaderV2";

function CostBreakupDashboardHeader(props) {
  const {
    selectedView,
    selectedDate,
    downloadSheet,
    location,
    isLoading,
    totalFooter,
    excelLoading,
    tableData,
    selectedUrl,
    renderToggleTabButton = false,
    renderToggleTabButtonData = {},
    openFrom = "",
    showMonthDropDown = true,
    excelButtonData,
    lensVersion = false,
  } = props;
  return (
    <div>
      {openFrom === "reservation" ? (
        <ReservationHeaderV2
          hasData={isLoading || !tableData?.length}
          isLoading={isLoading}
          excelLoading={excelLoading}
          customClass="!p-[0]"
          renderToggleTabButtonData={renderToggleTabButtonData}
          excelButtonData={excelButtonData}
        />
      ) : (
        <CostBreakupHeaderV2
          selectedView={selectedView}
          selectedDate={selectedDate}
          downloadSheet={downloadSheet}
          props={location}
          selectedUrl={selectedUrl}
          hasData={
            isLoading || !(totalFooter && Object.keys(totalFooter)?.length && tableData?.length)
          }
          excelLoading={excelLoading}
          isLoading={isLoading}
          downloadButtonStyle="!h-auto !p-[10px] !font-medium"
          customClass="!p-[0] cost-breakup-wrapper-custom"
          renderToggleTabButton={renderToggleTabButton}
          renderToggleTabButtonData={renderToggleTabButtonData}
          showMonthDropDown={showMonthDropDown}
          lensVersion={lensVersion}
        />
      )}
    </div>
  );
}

export default CostBreakupDashboardHeader;
