const initState = {
  files: {},
};

const initAction = { type: "", payload: {} };

export default function FileLoaderReducer(state = initState, action = initAction) {
  switch (action.type) {
    case "UPDATE_FILES": {
      const { fileName, month, service, year, percentage, id, startTime } = action.payload;
      const key = `${service}_${year}_${month}`;
      return {
        files: {
          ...state.files,
          [key]: {
            fileName,
            percentage,
            id,
            month,
            service,
            year,
            startTime: startTime || state.files?.[key]?.startTime,
          },
        },
      };
    }
    case "UPDATE_SUCCESS": {
      const { month, service, year, fileName, percentage } = action.payload;
      const key = `${service}_${year}_${month}`;

      return { files: { ...state.files, [key]: { fileName, percentage, isDownloaded: true } } };
    }

    case "UPDATE_ERROR": {
      const { month, service, year, fileName, isTimeExceeded } = action.payload;
      const key = `${service}_${year}_${month}`;

      return {
        files: {
          ...state.files,
          [key]: { fileName, percentage: 100, isError: true, isDownloaded: true, isTimeExceeded },
        },
      };
    }

    case "DELETE_FILE": {
      const updatedFiles = { ...state.files };
      delete updatedFiles[action.payload];

      return { ...state, files: updatedFiles };
    }

    case "REMOVE_ALL":
      return {
        ...state,
        files: {},
      };
    default:
      return state;
  }
}
