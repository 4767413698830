import React from "react";
import { noop } from "../../../../../../Utils/commonUtils";
import TabWrapper from "../../Tabs";

function Tabs({
  tabs = [],
  tabClicked = noop,
  selected = "",
  wrapperClass = "",
  isLoading = false,
  lensVersion = false,
}) {
  return (
    <div className={wrapperClass}>
      {tabs?.length ? (
        <TabWrapper
          tabs={tabs}
          tabClicked={(e) => tabClicked(e, "tab")}
          selected={selected || tabs?.[0]?.label}
          isRoundedCorner="rounded"
          isLoading={isLoading}
          lensVersion={lensVersion}
        />
      ) : null}
    </div>
  );
}

export default Tabs;
