import React from "react";
import { truncatedText } from "../../../../Utils/tunerUtils";
import TooltipV2 from "../../../Common/TooltipV2";

function TruncatedTextWithPopUp({ text, charLimit }) {
  return (
    <p>
      <TooltipV2
        popoverId="ck-bottom-tooltip"
        parentClass="tooltip_table_v2 stripped ck-config-tooltip tuner-tab-truncate-tooltip"
        tooltipid="reset_default_state"
        showTooltipIcon={false}
        tooltipOnDisplayText={`${text}`}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        displayText={() => truncatedText(text, charLimit)}
      >
        <span className="ck-account-name">{text}</span>
      </TooltipV2>
    </p>
  );
}

export default TruncatedTextWithPopUp;
