import React from "react";
import {
  getCurrencySymbol,
  numberWithCommasandDecimal,
} from "../../../../../../../Utils/commonUtils";
import { ReactComponent as TotalCost } from "../../../Assets/total-cost.svg";

export default function GrandTotalCard({ grandTotal, hideGrandTotalCards, tableLegend }) {
  return grandTotal?.length && !hideGrandTotalCards ? (
    <div className="total-container">
      {grandTotal?.find((item) => item?.column === `Total (${getCurrencySymbol()})`) && (
        <div className="total-cost">
          <div className="flex gap-[7px] items-center">
            <TotalCost />
            <p className="total-value">Total Cost</p>
          </div>
          <p className="font-normal text-[22px] text-[#000000] leading-4 mt-[12px]">
            {`${numberWithCommasandDecimal(
              grandTotal?.find((item) => item?.column === `Total (${getCurrencySymbol()})`)?.total
            )}`}
          </p>
        </div>
      )}
      <div className="flex gap-[5px]">
        {grandTotal?.map(
          (item) => item?.column !== `Total (${getCurrencySymbol()})` && tableLegend(item)
        )}
      </div>
    </div>
  ) : null;
}
