import React from "react";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "./style.scss";

export default function AutoCompleteInput(props) {
  const {
    options,
    keyToShowInOptions,
    placeHolder,
    label,
    labelClass,
    handleChange,
    value,
    containerClass = "",
    error,
    touched,
    onBlurHandler,
    multiple = false,
    name = "auto-complete-input",
    readOnly = false,
  } = props;

  const createEvent = (handleValue) => ({
    target: {
      name,
      value: handleValue,
    },
  });

  const onHandleChange = (handleValue) => {
    const _event = createEvent(handleValue);
    handleChange(_event);
  };

  const onBlurHandleChange = (handleValue) => {
    if (!readOnly) {
      const _event = createEvent(handleValue);
      onBlurHandler(_event);
    }
  };

  const renderOptions = () => {
    if (keyToShowInOptions) {
      return options.map((option) => option[keyToShowInOptions]);
    }
    return options;
  };
  const selectedValue = multiple ? value || [] : value || null;

  return (
    <div className={`${containerClass} flex flex-col`}>
      <label className={labelClass}>{label}</label>
      <Autocomplete
        multiple={multiple}
        freeSolo
        id="auto-complete-input"
        options={renderOptions()}
        value={selectedValue}
        inputValue={!multiple ? selectedValue || "" : undefined}
        readOnly={readOnly}
        onChange={(_, val) => {
          onHandleChange(val);
        }}
        onClose={(e, reason) => {
          if (reason === "blur") {
            const presentValue = e.target.value || "";
            onBlurHandleChange(multiple ? value : presentValue);
          }
        }}
        onInputChange={(_, inputVal) => {
          if (!multiple) {
            onHandleChange(inputVal);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeHolder}
            className={
              error && touched ? "text-field-autocomplete error-border" : "text-field-autocomplete"
            }
          />
        )}
      />
      {error && touched && (
        <FormHelperText className="error_message" error={error} size="small">
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
