import React from "react";
import { capitalizeFirstWord } from "../../../Utils/commonUtils";

export default function LabeledDataList({ data, defaultDisplayText = "- - -", className = "" }) {
  const formatPermissionType = (type) => (type ? capitalizeFirstWord(type) : "");

  return (
    <div className={`inline-block font-medium ${className}`}>
      {data?.length ? (
        data?.map(({ moduleName, permissionType }, index) => (
          <span key={moduleName}>
            <span className="capitalize text-[#2B2B2B]">{moduleName}</span>:
            <span className="text-[#0A3CA2]"> {formatPermissionType(permissionType)}</span>
            {index !== data.length - 1 && " | "}
          </span>
        ))
      ) : (
        <span className="ml-[2px] text-[11px] font-normal">{defaultDisplayText}</span>
      )}
    </div>
  );
}
