import React, { useCallback, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

function TanStackVirtualization({
  data,
  getTableRowTitle,
  getClassName,
  disableFunctionality,
  tableName,
  renderTableCells,
  parentRef,
  //  rowRenderKey,
  overScan,
  rowHeight,
  doNotDisableRow,
}) {
  // Create a ref to store measured heights
  const measuredHeights = useRef(new Map());

  // Memoized measurement function
  const measureElement = useCallback(
    (element) => {
      if (!element) return rowHeight;
      return element.offsetHeight || rowHeight;
    },
    [rowHeight]
  );

  // Function to get estimated size based on previous measurements
  const getEstimatedSize = useCallback(
    (index) => measuredHeights.current.get(index) || rowHeight,
    [rowHeight]
  );
  const rowVirtualizer = useVirtualizer({
    count: data?.length || 0,
    getScrollElement: () => parentRef?.current,
    overscan: overScan,
    estimateSize: getEstimatedSize,
    measureSize: measureElement,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  // Calculate padding
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? Math.max(0, rowVirtualizer.getTotalSize() - virtualRows[virtualRows.length - 1].end)
      : 0;

  // Handler to update measurements
  const handleMeasure = useCallback(
    (index, element) => {
      if (element) {
        const height = element.offsetHeight;
        if (height && height !== measuredHeights.current.get(index)) {
          measuredHeights.current.set(index, height);
          rowVirtualizer.measure(index);
        }
      }
    },
    [rowVirtualizer]
  );

  return (
    <>
      {/* Top Padding - Only render if needed */}
      {paddingTop > 0 && (
        <tr aria-hidden="true">
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}

      {/* Virtualized Rows */}
      {virtualRows.map((virtualRow) => {
        const row = data?.[virtualRow.index];
        // const tableRowKey = rowRenderKey
        //  ? `${row?.[rowRenderKey]}`
        //  : `row_${virtualRow.index}_random_${Math.random() * 1000}`;

        return (
          <tr
            key={virtualRow.key}
            ref={(element) => {
              handleMeasure(virtualRow.index, element);
            }}
            title={getTableRowTitle(row)}
            className={getClassName(row, disableFunctionality, tableName, doNotDisableRow)}
            style={{
              // Add minimum height to prevent collapse
              minHeight: getEstimatedSize(virtualRow.index),
            }}
          >
            {renderTableCells(row, virtualRow.index)}
          </tr>
        );
      })}

      {/* Bottom Padding - Only render if needed */}
      {paddingBottom > 0 && (
        <tr aria-hidden="true">
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </>
  );
}

export default TanStackVirtualization;
