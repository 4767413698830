import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Container from "./container-export";
import DialogComponent from "../Modal/Dialog";
import "./style.scss";

function Draggable(props) {
  const {
    listOrder,
    onCardUpdate,
    readOnly,
    value,
    handleChange,
    name,
    dialogeBody,
    dialogWrapperClass,
    dialogHeaderText,
    handleSubmit,
    cardClass,
    buttonText,
    hasWritePermission,
  } = props;
  const list = listOrder || value;
  const onChange = onCardUpdate || handleChange;

  const [modal, setModal] = useState({
    data: {},
    isOpen: false,
  });

  const handleOnClick = (item) => {
    setModal({
      data: item,
      isOpen: true,
    });
  };

  const handleModalClose = () => {
    setModal({
      data: {},
      isOpen: false,
    });
  };

  const handleModalSubmit = async (values, rowItem) => {
    if (handleSubmit && typeof handleSubmit === "function") {
      const updatedList = await handleSubmit(values, rowItem, list);
      if (updatedList) {
        onChange({
          target: {
            name,
            value: updatedList,
          },
        });
        handleModalClose();
      }
    }
  };

  return list?.length ? (
    <div className={`draggable ${readOnly ? "readOnly" : null}`}>
      <DndProvider backend={HTML5Backend}>
        <Container
          data={list}
          onOrderUpdate={onChange}
          readOnly={readOnly}
          onClick={(item) => handleOnClick(item)}
          name={name}
          showButton={!!dialogeBody}
          cardClass={cardClass}
          buttonText={buttonText}
        />
      </DndProvider>
      {modal?.isOpen && dialogeBody && (
        <DialogComponent
          className={dialogWrapperClass ?? ""}
          id=""
          open={modal?.isOpen}
          bodyClass="!border-0"
          headerClass="!font-medium !bg-[#EFF4FF]"
          header={dialogHeaderText ?? ""}
          showCloseButton
          handleClose={handleModalClose}
        >
          {dialogeBody(modal?.data, handleModalClose, handleModalSubmit, hasWritePermission)}
        </DialogComponent>
      )}
    </div>
  ) : (
    <div className="no_data">
      <p>No Data Available</p>
    </div>
  );
}

export default Draggable;
