import React from "react";
import { ReactComponent as EditIcon } from "../../../../../Assets/ckTuner/icons/v3-edit.svg";

const AccountType = {
  PROD: "Production",
  NON_PROD: "Non-Production",
};

export default function AccountTypeTag({ item, setOpenAccountTypeModal, isAllowed }) {
  const accountEnvironment = AccountType[item?.environment] || "Non-Production";
  return (
    <div
      className={`account_type_tag_container flex justify-center items-center gap-1 ${
        isAllowed ? "add_hover" : ""
      }`}
    >
      <div className="account_type_tag px-2 py-[2px] ">{accountEnvironment}</div>
      {isAllowed && (
        <button
          type="button"
          className="edit_icon_container"
          onClick={() =>
            setOpenAccountTypeModal({
              accountEnvironment: item?.environment || "NON_PROD",
              accountId: item?.accountId,
            })
          }
        >
          <EditIcon />
        </button>
      )}
    </div>
  );
}
