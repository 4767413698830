import getApiNameFromUrl from "./util";

export const strapiBasePath = process.env.REACT_APP_STRAPI_BASE_PATH;

export default {
  LOGIN: `${strapiBasePath}/api/publicroutes?filters[key][$eq]=LOGIN&populate[0]=routelink&populate[1]=formComponent.form&populate[3]=formComponent.form.rules&populate[4]=formComponent.form.messages&populate[7]=logo&populate[8]=signupEnabled&populate[9]=signupEnabled.button&populate[10]=signupEnabled.button.image&populate[11]=publicFooter&populate[12]=pageFooter`,
  SIGNUP: `${strapiBasePath}/api/publicroutes?filters[key][$eq]=SIGNUP&populate[0]=routelink&populate[1]=formComponent.form&populate[3]=formComponent.form.rules&populate[4]=formComponent.form.messages&populate[7]=logo&populate[8]=pageFooter&populate[9]=publicFooter&populate[10]=signupEnabled&populate[11]=signupEnabled.button&populate[12]=signupEnabled.button.image`,
  FORGOT_PASSWORD: `${strapiBasePath}/api/publicroutes?filters[key][$eq]=FORGOT_PASSWORD&populate[0]=routelink&populate[1]=formComponent.form&populate[3]=formComponent.form.rules&populate[4]=formComponent.form.messages&populate[5]=AfterFormSuccess&populate[6]=AfterFormSuccess.link&populate[7]=logo&populate[8]=pageFooter&populate[9]=publicFooter&populate[10]=formComponent.form&populate[11]=AfterFormSuccess.image`,
  RESEND_EMAIL: `${strapiBasePath}/api/resend-email?populate[0]=logo&populate[1]=Button&populate[3]=resendImage&populate[4]=publicFooter&populate[5]=pageFooter`,
  CONFIRM_USER: `${strapiBasePath}/api/publicroutes?filters[key][$eq]=CONFIRM_USER`,
  PAGE_NOT_FOUND: `${strapiBasePath}/api/page-not-found?populate[0]=InternalError503&populate[1]=InternalError503.logo&populate[2]=image`,
  RESET_PASSWORD: () =>
    `${strapiBasePath}/api/publicroutes?filters[key][$eq]=${getApiNameFromUrl()}&populate[0]=routelink&populate[1]=formComponent.form&populate[3]=formComponent.form.rules&populate[4]=formComponent.form.messages&populate[5]=formComponent.form.iconList&populate[7]=logo&populate[8]=AfterFormSuccess&populate[9]=AfterFormSuccess.link&populate[10]=AfterFormSuccess.image&populate[11]=tooltip&populate[12]=tooltip.icon&populate[13]=pageFooter&populate[14]=publicFooter&populate[15]=formComponent.form.iconList.password&populate[16]=formComponent.form.iconList.text&populate[17]=formComponent.form.selectedVisibilityIcon&populate[18]=formComponent.form.iconList.selected`,
  VERIFICATION_LINK_EXPIRED: `${strapiBasePath}/api/publicroutes?filters[key][$eq]=VERIFICATION_EXPIRED&populate[0]=routelink`,
  STRAPI_CREATE_TOKEN: `${strapiBasePath}/api/auth/local`,
};
