import React, { useEffect, useState, useMemo } from "react";
import { getCurrencySymbol, numberWithCommas } from "../../../../../../../Utils/commonUtils";
import { ReactComponent as TotalIcon } from "./Assets/money.svg";
import Loader from "../../../../../../Common/Loader";
import ExcelDownload from "../../../../../../Common/ExcelDownload";
import { sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import SingleLevelTable from "../../../../CostExplorer/table";
import "./style.scss";

export default function CardTable(props) {
  const { data, className, headers = [], exportServiceCall, selected, lensVersion = false } = props;
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState(data?.tableData || []);
  const [ascending, setAscending] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [footer, setTablefooter] = useState({});
  const [updatedHeaders, setUpdatedHeaders] = useState(headers);

  useEffect(() => {
    setUpdatedHeaders(headers.filter((header) => data.cardsKeyArr?.includes(header.key)));
  }, []);

  const sortHandlerParent = (sortBy) => {
    const updatedData = sortHandler(tableData, ascending, sortBy);
    setTableData(updatedData);
    setAscending(!ascending);
  };

  const cardItems = useMemo(
    () =>
      data?.cardData?.map(
        (item) =>
          item?.column !== "Total Cost" && (
            <div
              key={item?.column}
              className="ml-[10px] py-[16px] px-[10px] bg-[#ffffff] rounded-[5px]"
            >
              <p className="font-medium text-[12px] text-[#6F7584] leading-3">{item?.column}</p>
              <p className="text-[14px] font-semibold text-[#0C477E] mt-[5px]">{`${getCurrencySymbol()}${
                item?.total ? numberWithCommas(item?.total?.toFixed(2)) : "0.00"
              }`}</p>
            </div>
          )
      ),
    [data]
  );

  const cardTotal = useMemo(
    () =>
      data?.cardData?.find((item) => item?.column === "Total Cost")?.total?.toFixed(2) || "0.00",
    [data]
  );

  useEffect(() => {
    if (data?.tableData) {
      setTableData(data?.tableData);
      const tableFooter = {};

      headers?.forEach((item, index) => {
        if (index === 0) tableFooter[item?.key] = "Total";
        else if (index === headers.length - 1) {
          tableFooter[item?.key] = data?.cardData?.find((_) => _.column === item?.key)?.total;
        } else tableFooter[item?.key] = "";
      });
      setTablefooter(tableFooter);
    }
    setLoader(false);
  }, [data]);

  return (
    <div className={`table-wrapper bg-[#fff] ${className}`}>
      <div className="flex items-center table-header-top border-b-[1px] border-[#D9D9D9] h-[42px] px-[12px] justify-between">
        <div className="text-[16px] text-[#2B2B2B] font-bold">Top 20 Resources</div>
        <div className="ml-[16px]">
          <ExcelDownload
            downloadHandler={() => exportServiceCall(selected, setExcelLoading)}
            hasData={!data?.tableData?.length}
            excelLoading={excelLoading}
          />
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {data?.cardData?.length ? (
            <div className="bg-[#F5F8FA] rounded py-[8px] px-[12px] my-[14px] mx-[12px] overflow-x-auto">
              <div className="flex w-max">
                <div className="p-[10px] rounded-[5px] bg-[#ffffff] w-[142px]">
                  <p>
                    <span className="py-[4px] px-[4.5px] bg-[#E5F3FF] rounded-full">
                      <TotalIcon className="inline-block w-[16px] h-[15px]" />
                    </span>
                    <span className="text-[12px] font-semibold text-[#0C477E] ml-[7px]">
                      Total Cost
                    </span>
                  </p>
                  <p className="text-[22px] text-[#000000] font-regular mt-[12px] leading-4">{`${getCurrencySymbol()} ${cardTotal}`}</p>
                </div>
                {cardItems}
              </div>
            </div>
          ) : null}
          <div
            className={`amazon_relation_table_wrapper overflow-auto ${
              !tableData?.length ? "s3-cost-no-data-wrapper" : ""
            }`}
          >
            <SingleLevelTable
              headings={updatedHeaders}
              data={tableData || []}
              footer={footer}
              usageFooter={false}
              sortHandler={(sortBy) => sortHandlerParent(sortBy)}
              sortedIcon={false}
              GraphQuantity={false}
              message="No Data Available"
              currencyRequired
              className="table-width"
              lensVersion={lensVersion}
            />
          </div>
        </>
      )}
    </div>
  );
}
