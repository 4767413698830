/* eslint-disable complexity */
import Axios from "axios";
import React from "react";
import {
  checkIfReloadNeeded,
  getLocalStorageUserData,
  getModuleFromurl,
  getSessionStorageUserData,
  // getMavFromurl,
  // getXAuthUserFromurl,
  getToast,
  isRecommendationsOnFinops,
  logout,
} from "../../Utils/commonUtils";
// import { getLensBaseURL } from "./urls";
import store from "../../store";
import actions from "../../Actions";
import Routes from "../../Config/Routes";
import { AzureContactUs, CONTACT_US } from "../../Constants/Constant";

const AxiosInstance = Axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

const reloadNeededOrNot = (reloadNeeded, inprogressReducer) =>
  checkIfReloadNeeded() && reloadNeeded && inprogressReducer && !inprogressReducer?.inprogress;

// Do something before request is sent
// AxiosInstance.defaults.withCredentials = true;
let showToast = true;

AxiosInstance.interceptors.request.use(
  // eslint-disable-next-line max-statements
  (config) => {
    const reqConfig = { ...config, headers: { ...config.headers } };
    const currentSession = getSessionStorageUserData();
    const checkModuleCondition = (current, parameter, moduleName, overRideNameCheck = false) => {
      if (overRideNameCheck) return current;
      if (moduleName === "legacy-lens") {
        return current;
      }
      const dashboard = getModuleFromurl();
      if (
        dashboard?.id &&
        dashboard?.name &&
        dashboard[parameter] !== current &&
        reqConfig.url.includes(dashboard?.name.toLowerCase())
      ) {
        return dashboard[parameter];
      }
      return current;
    };
    if (window.location.hostname === "localhost") {
      reqConfig.headers["x-auth-origin"] = "https://qa1.cloudonomic.net";
    }
    const { reloadNeeded = true } = config;
    const state = store.getState();
    const inprogressReducer = state?.dashbaordReducer?.inprogress;
    if (reloadNeededOrNot(reloadNeeded, inprogressReducer)) {
      store.dispatch(actions.DashboardActions("RELOAD", { reload: true }));
      return false;
    }
    if (getLocalStorageUserData()) {
      const {
        sendIdTokenOnly,
        doNotSendModule,
        doNotSendMav,
        isPartnerCustomerAuthRequired = true,
        overRideNameCheck = false,
      } = config;
      const userData = inprogressReducer?.inprogress
        ? getLocalStorageUserData()
        : state.userReducer?.userData;

      const { tokens: { idToken, partnerId, customerId } = {}, currentUser: { type, id } = {} } =
        userData || {};
      const { tokens: { moduleName, moduleId, mavId } = {} } = currentSession || {};
      if (type === "CUSTOMER" && !sendIdTokenOnly) {
        reqConfig.headers["Auth-Customer"] = id || customerId;
        // if (!doNotSendModule) reqConfig.headers["Auth-Module"] = moduleId;
        if (moduleId && !doNotSendModule) {
          const updatedModuleId = checkModuleCondition(
            moduleId,
            "id",
            moduleName,
            overRideNameCheck
          );
          reqConfig.headers["Auth-Module"] = updatedModuleId;
        }
        if (!doNotSendMav) reqConfig.headers["Auth-Mav"] = mavId;
        reqConfig.headers["Auth-Partner"] = partnerId;
      } else if (type === "PARTNER" && !sendIdTokenOnly) {
        reqConfig.headers["Auth-Partner"] = id;
      }
      reqConfig.headers.authorization = `Bearer ${idToken}`;

      if (type === "PARTNER" && moduleId && !sendIdTokenOnly) {
        const updatedModuleId = checkModuleCondition(moduleId, "id", moduleName);
        reqConfig.headers["Auth-Module"] = updatedModuleId;
      }
      const { customerId: partnerLevelCustomerId, isdashboardOnFinops } =
        isRecommendationsOnFinops();
      if (isdashboardOnFinops) {
        reqConfig.headers["Finops-Customer"] = partnerLevelCustomerId;
        reqConfig.headers["Finops-Mav"] = mavId;
      }
      // auth partner and auth customer, authorization not required for polling api of Instance Cost
      if (!isPartnerCustomerAuthRequired) {
        delete reqConfig.headers["Auth-Partner"];
        delete reqConfig.headers["Auth-Customer"];
        delete reqConfig.headers.authorization;
      }
    }

    return reqConfig;
  },
  (error) => {
    throw new Error(error.request.data.message);
  }
);

const handle704ErrorHTML = () => (
  <>
    <p>Tag Not Available.</p>
    <br />
    <p>
      Your request for a tag report has been accepted. Please wait 48-72 hours for the data to
      appear.
    </p>
    <br />
    <p>
      If you need assistance, please contact{" "}
      <a href={CONTACT_US} style={{ display: "inline-block" }}>
        <span style={{ color: "#0a3ca2", cursor: "pointer" }}>{AzureContactUs}</span>
      </a>{" "}
    </p>
  </>
);

const handle401Errors = (message) => {
  if (
    window.location.pathname !== Routes.LOGIN &&
    window.location.pathname !== Routes.FORGOTPASSWORD &&
    window.location.pathname !== Routes.CONFIRMUSER &&
    window.location.pathname !== Routes.RESENDEMAIL
  ) {
    if (showToast) {
      getToast("error", "Your session has expired, please re-login");
    }
    showToast = false;
    logout();
  } else if (window.location.pathname !== "/") {
    getToast("error", message);
  }
};

const handle400And403Errors = (message) => {
  if (
    ![Routes.LOGIN, Routes.CONFIRMUSER, Routes.CONFIRM_CUSTOMER, Routes.CONFIRM_USER_OLD].includes(
      window.location.pathname
    )
  ) {
    getToast("error", message);
  }
};
const handle500Errors = () => {
  if (
    [Routes.LOGIN, Routes.RESETPASSWORD, Routes.FORGOTPASSWORD].includes(window.location.pathname)
  )
    getToast("error", "We are currently experiencing technical difficulties. Please try later.");
};

const handle700Error = (message) => {
  if (
    ![
      Routes.PREFERENCES_USER,
      Routes.CONFIRM_USER_OLD,
      Routes.CONFIRMUSER,
      Routes.CONFIRM_CUSTOMER,
    ].includes(window.location.pathname)
  ) {
    getToast("error", message);
  }
};

// Define a function to encapsulate the condition logic for scenarios in which application level error handling should be prevented
const shouldHandleErrorFromAxios = (error, status) => {
  // Check if there's an error and the status is truthy
  if (!status) return false;

  // Safely access nested properties
  const url = error?.config?.url || "";

  // check for logout api
  if (url && url?.includes("/auth/logout")) {
    return false;
  }
  if (url && (url?.includes("/comments/fetch") || url?.includes("/comments/add"))) {
    return false;
  }
  // Check if the URL does not include "authbridge"
  return !url.includes("authbridge");
};

AxiosInstance.interceptors.response.use(
  (config) => config,
  // eslint-disable-next-line complexity
  (error) => {
    const { response: { status, data: { message } = {} } = {} } = error;
    if (shouldHandleErrorFromAxios(error, status)) {
      store.dispatch(actions.ErrorAction(status, { status, errorMessage: message }));

      switch (status) {
        case 401:
          handle401Errors(message);
          break;
        case 400:
        case 403:
          handle400And403Errors(message);
          break;
        case 500:
        case 503:
          handle500Errors();
          break;
        case 404:
        case 786:
        case 715:
        case 709:
        case 785:
        case 710: // CK-Auto- Same payer account validation (Step 2 on verify arn)
        case 418:
          break;
        case 704:
          getToast("success", handle704ErrorHTML());
          break;
        case 705:
          getToast("error", message);
          break;
        case 504:
          getToast(
            "error",
            "We are currently experiencing technical difficulties. Please try later."
          );
          break;
        case 700:
          handle700Error(message);
          break;
        default:
          if (
            ![Routes.CONFIRMUSER, Routes.CONFIRM_CUSTOMER, Routes.CONFIRM_USER_OLD].includes(
              window.location.pathname
            )
          ) {
            getToast("error", message);
          }
      }
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
