import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  numberWithCommas,
  numberWithCommasandDecimal,
} from "../../../../../../../Utils/commonUtils";
import ExcelDownload from "../../../../../../Common/ExcelDownload";
import { getstorageCostTableTotal, getTableHeaders } from "../../../helper/utils";

import Tabs from "../../../S3/graph/Tabs";
import { getTotalAccountsPayload, sortHandler } from "../../../../CostBreakup/costbreakupUtils";
import commonService from "../../../../../../../Services/commonService";
import Loader from "../../../../../../Common/Loader";
import "./style.scss";
import MultiLevelHeaderTable from "../../../Components/MultilevelTable";
import { getHeaderValues } from "../../../cudosUtil";
import GrandTotalCard from "./GrandTotalCard";

function CostBreakdown(props) {
  const {
    data,
    onExcelDownload,
    updateConfig,
    className = "",
    heading = "",
    tabs = [],
    selectedService = "",
    configuration = {},
    configuration: {
      hideGrandTotalCards = false,
      headers = [],
      showAllTotalCost = false,
      isPollingExcel = false,
    },
    lensVersion = false,
    headerComponent = {},
  } = props;
  const [selected, setSelected] = useState(
    tabs?.find((_) => _?.value === configuration?.selectedTab)?.label || tabs?.[0]?.label
  );
  const [showLoader, setShowLoader] = useState(false);
  const [grandTotal, setGrandTotal] = useState(getstorageCostTableTotal(data) || []);
  const [tableData, setTableData] = useState(data);
  const [ascending, setAscending] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);
  const fileLoaderReducer = useSelector((state) => state.FileLoaderReducer);
  const dispatch = useDispatch();

  const tableLegend = (item) => (
    <div className="item-container">
      <div className="item-heading">
        <span>{item?.column_Label}</span>
      </div>
      <div className="flex">
        <div className="item-content">
          <p>{item?.column_Inter}</p>
          <p>{`${numberWithCommas(item?.inter_az_usage?.toFixed(2))}`}</p>
        </div>
        <div className="item-content">
          <p>{item?.column_Cost}</p>

          <p>{`${numberWithCommasandDecimal(item?.inter_az_cost)}`}</p>
        </div>
      </div>
    </div>
  );

  const tabClicked = async (item) => {
    if (item?.label === selected) {
      return;
    }
    const config = { ...configuration, ...{ selectedTab: item?.value } };
    updateConfig(configuration?.name, config);
    setShowLoader(true);
    if (item?.label === selected?.label) return;
    setSelected(item?.label);
    const payload = getTotalAccountsPayload({
      service: item?.value,
    });
    const resp = await commonService.getTopResources(payload);
    setTableData(resp?.data?.data);
    setGrandTotal(getstorageCostTableTotal(resp?.data?.data));
    setShowLoader(false);
  };

  const tableSortHandler = (tableSortBy) => {
    const sortedData = sortHandler(tableData?.data, ascending, tableSortBy);
    setAscending(!ascending);
    setSortBy(tableSortBy);
    setTableData({
      ...tableData,
      data: sortedData,
    });
  };

  const downloadSheethandler = () => {
    onExcelDownload(
      tabs?.find((_) => _?.label === selected)?.value || selectedService,
      setExcelLoading,
      isPollingExcel
        ? { ...getHeaderValues(headerComponent), dispatch }
        : getHeaderValues(headerComponent)
    );
  };

  const disableDownload = () => {
    const service = tabs?.find((_) => _?.label === selected)?.value || selectedService;
    const payload = getTotalAccountsPayload();
    const key = `${service?.toUpperCase()}_${payload?.year}_${payload?.month}`;
    return fileLoaderReducer?.files?.[key]?.percentage < 100;
  };

  return (
    <div className={`data-transfer-cost-breakdown bg-[#fff] ${className}`}>
      <div className="breakdown-heading">
        <p className="dataTransferheading">{heading}</p>
        {(tabs?.length !== 0 || tableData?.data?.length) && (
          <ExcelDownload
            downloadHandler={() => {
              downloadSheethandler();
            }}
            hasData={!tableData?.data?.length}
            excelLoading={isPollingExcel ? disableDownload() : excelLoading}
          />
        )}
      </div>
      <Tabs
        tabs={tabs}
        tabClicked={tabClicked}
        selected={selected}
        wrapperClass="mt-[16px] justify-center "
        isLoading={showLoader}
        lensVersion={lensVersion}
      />
      {!showLoader ? (
        <div className="min-h-[250px] relative">
          <GrandTotalCard
            grandTotal={grandTotal}
            hideGrandTotalCards={hideGrandTotalCards}
            tableLegend={tableLegend}
          />
          {tableData?.data?.length ? (
            <MultiLevelHeaderTable
              columns={getTableHeaders({ tableData, headers, selected })}
              data={tableData?.data}
              total={
                showAllTotalCost
                  ? { ...tableData?.totalCosts }
                  : { total: tableData?.totalCosts?.total }
              }
              totalVisible
              sortHandlerParent={tableSortHandler}
              selectedSortHeader={sortBy}
              ascending={ascending}
              V2
              lastColumnSticky
              lensVersion={lensVersion}
            />
          ) : (
            <div className="no_data_found">
              <p>No Data Available</p>
            </div>
          )}
        </div>
      ) : (
        <div className="min-h-[400px]">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default CostBreakdown;
