import React from "react";
import { noop } from "../../../../Utils/commonUtils";
import { TabV2, TabsV2 } from "../../../CK-Lens/Common/TabsV2";

function TabsWrapper({
  isLoading,
  tabs = [],
  selected = "",
  wrapperClass = "",
  tabClicked = noop,
  children,
}) {
  return (
    <TabsV2 disabled={isLoading} wrapperClass={wrapperClass}>
      <div className="flex">
        {tabs?.map((item, index) => (
          <TabV2
            onClick={(e) => {
              tabClicked(e, item);
            }}
            selectedTab={selected}
            tab={item}
            index={index}
            tabs={tabs}
          >
            {item}
          </TabV2>
        ))}
      </div>
      {children}
    </TabsV2>
  );
}

export default TabsWrapper;
