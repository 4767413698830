import React from "react";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as ExcelIcon } from "../../../../Assets/icons/others/excel-icon.svg";
import { ReactComponent as ExcelLoader } from "../../../../Assets/icons/others/excel-loader.svg";
import { ReactComponent as DownloadWhite } from "../../../../Assets/icons/others/download-outlineWhite.svg";
// V2 icons
import { ReactComponent as ExcelIconV2 } from "../../../../Assets/icons/others/ic-xls-v2.svg";
import { ReactComponent as ExcelDisableIconV2 } from "../../../../Assets/icons/excel-disabled.svg";
import { ReactComponent as DownloadIconV2 } from "../../../../Assets/icons/others/download.svg";
import { ReactComponent as ExcelLoaderV2 } from "../../../../Assets/icons/others/excel-loader-blue.svg";
import "./style.scss";

function ExcelDropDownV2(props) {
  const {
    innerRef,
    disable,
    excelLoading,
    dropDownToggler,
    toggleDropdown,
    DownlaodTabs,
    downloadList,
    downlaodHandler,
    groupDetails,
    icon = true,
    wraperClass = "",
    btnClass = "",
  } = props;

  const handleChange = (e) => {
    groupDetails(e.target.value);
  };

  return (
    <div className={`billing_console_header ${wraperClass}`} ref={innerRef}>
      <Tooltip title="Download xlsx" arrow placement="top">
        {icon ? (
          <Button
            variant="outlined"
            onClick={() => dropDownToggler()}
            id="download"
            disabled={disable}
            className="downloadxBtn"
          >
            <span className="xcelIcon">
              <ExcelIcon />
            </span>
            <span className="xcelDownloadIc">
              {excelLoading ? <ExcelLoader className="excel-loading-gif" /> : <DownloadWhite />}
            </span>
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              dropDownToggler();
            }}
            className={`download_v2 download_button_style !px-[5px] !h-[30px] ${
              disable ? "disable_v2" : ""
            } ${excelLoading ? "download_loading" : ""} ${btnClass || ""} `}
            disabled={disable || excelLoading}
            id="download"
          >
            {disable ? (
              <ExcelDisableIconV2 className="btn_icon_style download_excel_v2" />
            ) : (
              <ExcelIconV2 className="btn_icon_style download_excel_v2" />
            )}
            Download
            {excelLoading ? (
              <ExcelLoaderV2 className="excel-loading-gif mr_5 !ml-[5px] !mr-0" />
            ) : (
              <DownloadIconV2 className="btn_icon_style !ml-[5px] !mr-0 excel-dwnld-custom" />
            )}
          </Button>
        )}
      </Tooltip>
      {toggleDropdown && (
        <div className="options_list">
          {DownlaodTabs.map((item) => (
            <p
              className={
                (downloadList.length === DownlaodTabs.length - 1 ||
                  downloadList.includes(item.value)) &&
                "selected"
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.value}
                    id={item.value}
                    onClick={(e) => handleChange(e)}
                    size="small"
                    checked={
                      item.value === "ALL"
                        ? downloadList.length === DownlaodTabs.length - 1
                        : downloadList.includes(item.value)
                    }
                  />
                }
                label={item.label}
              />{" "}
            </p>
          ))}

          <Button
            variant="contained"
            className="w-100"
            onClick={() => downlaodHandler()}
            id="download_now"
            disabled={disable || excelLoading}
          >
            Download Now
          </Button>
        </div>
      )}
    </div>
  );
}

export default ExcelDropDownV2;
