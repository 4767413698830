import React from "react";
import chroma from "chroma-js";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import isEqual from "lodash.isequal";
import { ReactComponent as SortedIcon } from "../../../../Assets/icons/sortingIcon.svg";
import {
  getSessionStorageUserData,
  noop,
  numberWithCommasandDecimal,
  checkModule,
  getLocalStorageUserData,
} from "../../../../Utils/commonUtils";
import { ModuleNameAzureInsights } from "../../../../Constants/textConstant";
import InfoBar from "./InfoBar";
import { decodeAndParseQueryString } from "./utils/isSaveReport";

export const showUsageGraphHEading = (heading) => (
  <div className="d_flex heading_wrapper">
    <h3 className="usageHeading">{!!heading && heading}</h3>
  </div>
);

export const childFormControlLabel = (Child, item, index, childValue, onChange) => (
  <FormControlLabel
    control={<Checkbox size="small" />}
    label={Child}
    value={item?.tagList[childValue]?.data[Child]}
    checked={item?.tagList[childValue]?.data[Child]}
    name={Child}
    onChange={(event) => onChange(event, index, childValue)}
  />
);

export const innerCheckBoxListLessThan4 = (key, item, index, onChange) => (
  <div>
    {key !== "" && (
      <div className="inner_checkbox_list">
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={key}
          value={item.childList[key]}
          checked={item.childList[key]}
          name={key}
          onChange={(event) => onChange(event, index)}
        />
      </div>
    )}
  </div>
);

export const getCostExplorerName = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      name: "Cost Explorer",
      path: "costexplorer",
      breadcrumsData: ["Cost Analysis", "Cost Explorer"],
      savedReports: true,
    },

    [ModuleNameAzureInsights]: {
      name: "Cost Analysis",
      path: "costanalysis",
      breadcrumsData: ["Cost Analysis"],
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

const getSelected = (data) => {
  const dataObj = {};
  Object.keys(data || {})
    .filter((_) => data?.[_])
    .forEach((_) => {
      dataObj[_] = true;
    });
  return dataObj;
};
export const manipulateFilterstoURL = (filters, stringify, newState) => {
  const res = [];
  filters?.forEach((key) => {
    const isInclude = key?.include === "Include Only" ? "true" : "false";
    if (!!newState && (!newState || key?.isApplied)) {
      if (key?.arrayList?.length > 0 && key?.key !== "tags") {
        const selectedListObj = {
          keys: key?.key,
          list: key?.arrayList,
          include: isInclude,
        };
        res.push(stringify ? JSON.stringify(selectedListObj) : selectedListObj);
      } else if (Object.keys(key?.tagList).length > 0) {
        const selectedTags = {};
        Object.keys(key?.tagList)?.forEach((item) => {
          if (key?.tagList?.[item]?.arrayList?.length) {
            selectedTags[item] = key?.tagList[item];
            selectedTags[item].data = getSelected(key?.tagList[item]?.data);
            selectedTags[item].filteredData = getSelected(key?.tagList[item]?.filteredData);
            selectedTags[item].searchTagText = "";
          }
        });
        const tagListObj = {
          keys: key?.key,
          list: selectedTags || {},
          include: isInclude,
        };
        res.push(stringify ? JSON.stringify(tagListObj) : tagListObj);
      }
    }
  });
  return res;
};
export const selectDateRange = {
  "Custom Range": "CUSTOM",
  "7D": "SEVEN_DAY",
  "3M": "THREE_MONTH",
  "6M": "SIX_MONTH",
  "1Y": "ONE_YEAR",
  MTD: "MTD",
  YTD: "YTD",
};

export const filterZeroData = (tableData = []) => tableData;

const getDataValue = (data, currencyRequired, defaultValue = "---", decimalPlaces = 2) => {
  if (typeof data === "string") {
    return data;
  }
  if (typeof data === "number") {
    return `${numberWithCommasandDecimal(data, decimalPlaces, currencyRequired)}`;
  }
  return defaultValue;
};

export const tableData = (row, item, currencyRequired) =>
  typeof row?.[item?.key] === "undefined"
    ? item?.defaultValue || "NA"
    : getDataValue(row?.[item?.key], currencyRequired, item?.defaultValue, item?.decimalPlaces);

export const selectDateRangeReverse = {
  CUSTOM: "Custom Range",
  SEVEN_DAY: "7D",
  THREE_MONTH: "3M",
  SIX_MONTH: "6M",
  ONE_YEAR: "1Y",
  MTD: "MTD",
  YTD: "YTD",
};

export const returnBreadCrumbsData = (reportName) => {
  if (reportName) {
    return [...getCostExplorerName().breadcrumsData, reportName];
  }
  return getCostExplorerName()?.breadcrumsData;
};

export const manipulateRecentReportList = (data, reportId) => {
  if (reportId) {
    const firstObj = data.filter((item) => item.id === +reportId);
    const restArr = data.filter((item) => item.id !== +reportId);
    return [...firstObj, ...restArr.splice(0, 4)];
  }
  return data?.splice(0, 5) || [];
};
export const getInforBar = (text, isChartLoading) => (
  <InfoBar barText={text} isChartLoading={isChartLoading} />
);

export const getNewState = (state) => ({
  selectedgroupBy: state.selectedgroupBy,
  toggler: state.toggler,
  chartType: state.graph.chartType,
  granualityType: state.graph.granualityType,
  start: moment(state.start).format("YYYY/MM/DD"),
  end: moment(state.end).format("YYYY/MM/DD"),
  filters: manipulateFilterstoURL(state.filters, false, "newState"),
});

export const disableSaveBtn = (initState, newState, reportId) => {
  if (reportId) {
    return isEqual(initState, newState);
  }
  return false;
};

export const getReportOptions = (item) => ({ label: item?.reportName, value: item });

export const saveModalConfig = {
  openModal: false,
  heading: "Save to report library",
  touched: false,
  key: "save",
  reportName: "",
};

export const saveAsNewConfig = {
  openModal: false,
  heading: "Save as new report",
  touched: false,
  key: "saveAsNew",
  reportName: "",
};

export const isResource = (item) => item.key === "resource" && item.searchText?.length < 4;

export const replaceMatchingObjects = (arr1 = [], arr2 = [], key = "") =>
  arr1.map((obj1) => {
    const findObj = arr2.find((obj2) => obj1[key] === obj2[key]);
    if (findObj) {
      return findObj;
    }
    return obj1;
  });

export const getAppliedFillterList = (list = [], updatedElement = {}, tagName = "") => {
  const findIndex = list?.findIndex((ele) => ele?.key === updatedElement?.key);
  const newlist = [...list];
  if (findIndex !== -1 && tagName) {
    newlist[findIndex] = {
      ...newlist[findIndex],
      include: updatedElement?.include,
      tagList: {
        ...newlist[findIndex]?.tagList,
        [tagName]: updatedElement?.tagList?.[tagName],
      },
    };
    return newlist;
  }

  if (findIndex !== -1) {
    newlist[findIndex] = { ...updatedElement };
    return newlist;
  }
  return [...list, updatedElement];
};

export const heatMapColorGeneratorUtility = (minValue, maxValue, value) => {
  // background color
  const chromaScale = chroma.scale(["#F3F8FF", "#71A6F0"]).domain([0, 1]);

  // text color
  const text = chroma("black");
  const heatMapRatio = (value - minValue) / (maxValue - minValue);

  // text-color and background color set
  return Number.isNaN(value)
    ? { backgroundColor: "#ecf5fb" }
    : {
        backgroundColor: Number.isNaN(heatMapRatio) ? "#F3F8FF" : chromaScale(heatMapRatio).hex(),
        color: text.hex(),
      };
};
export const getHeaderContent = ({
  sortHandler = noop,
  heading = {},
  data = [],
  GraphQuantity = "",
  sortedIcon = true,
}) => {
  const userData = getLocalStorageUserData();
  return (
    <>
      {" "}
      <div
        className="cursor-pointer inline-block"
        aria-hidden
        onClick={() => {
          sortHandler(heading?.key, data);
        }}
      >
        {heading?.value === "api operation" ? "API Operation" : heading?.value}
      </div>
      {(GraphQuantity === "" || sortedIcon) && userData?.currentUser.type === "PARTNER" && (
        <span
          style={{ marginLeft: "4px", cursor: "pointer" }}
          aria-hidden
          onClick={() => {
            sortHandler(heading?.key, data);
          }}
        >
          <SortedIcon />
        </span>
      )}
    </>
  );
};

export const getGraphHeading = (graph) => (
  <div className="d_flex heading_wrapper">
    {!graph.showUsageGraph && (
      <h3 className="heading main-text-bold">{!!graph.heading && graph.heading}</h3>
    )}
  </div>
);

function updateObjectValuesBasedOnArray(obj, keysArray) {
  try {
    const updatedObject = {};
    Object.keys(obj).forEach((key) => {
      updatedObject[key] = keysArray.includes(key);
    });
    return updatedObject;
  } catch {
    return {};
  }
}

export const updateFilterUsingUrl = (filter = [], url = "") => {
  const urlFilters = decodeAndParseQueryString(url)?.Filters ?? [];
  return filter.map((singleFilter) => {
    const singleSelectedFilter = urlFilters?.filter(
      (singleFilterItem) => singleFilter?.key === singleFilterItem?.keys
    );
    const item = singleFilter;
    const selectedFilterList = singleSelectedFilter?.[0]?.list ?? [];
    if (singleSelectedFilter?.length) {
      if (Array.isArray(selectedFilterList)) {
        item.arrayList = selectedFilterList;
        item.childList = updateObjectValuesBasedOnArray(item?.childList ?? {}, selectedFilterList);
        item.filteredChildList = updateObjectValuesBasedOnArray(
          item?.filteredChildList ?? {},
          selectedFilterList
        );
        item.isApplied = true;
        if (singleSelectedFilter?.[0]?.include === "true") item.include = "Include Only";
        else item.include = "Exclude Only";
      } else {
        if (singleSelectedFilter?.[0]?.include === "true") item.include = "Include Only";
        else item.include = "Exclude Only";
        item.tagList = selectedFilterList;
        item.isApplied = true;
      }
    } else {
      item.arrayList = [];
      item.childList = updateObjectValuesBasedOnArray(item?.childList ?? {}, []);
      item.filteredChildList = updateObjectValuesBasedOnArray(item?.filteredChildList ?? {}, []);
      item.isApplied = false;
      if (item?.tagList) {
        item.tagList = {};
      }
    }
    return item;
  });
};

export const getCustomTooltipForCustomBar = (
  data,
  item,
  index,
  totalValues,
  internalItem,
  toggler,
  usageCheck = false
) => {
  const categoryLabel = data?.category?.[index]?.label || "";
  const formattedLabel = categoryLabel.replace(/ /g, "-");

  const seriesName = item?.seriesname || "";
  const percentage =
    item.percentageDates?.[index] && !toggler
      ? Math.max(item.percentageDates[index], 0)
      : item.percentageDates?.[index] ?? 0;

  const formattedValue = numberWithCommasandDecimal(internalItem, 2, !usageCheck, usageCheck);
  const totalValue =
    numberWithCommasandDecimal(totalValues[formattedLabel], 2, !usageCheck, usageCheck) ?? 0;

  return `
    <div class="tooltip_wrapper cudos_tooltip">
      <div class="headingCont">${categoryLabel}</div>
      <span class="row">
        <label>${seriesName}</label>
        <b>${formattedValue} (${percentage}%)</b>
      </span>
      <div class="totalStyle">
        <b>Total</b>
        ${totalValue}
      </div>
    </div>
  `;
};

export const getResourcesInitialData = ({
  updatedFilter = [],
  index = "",
  selectedObj = {},
  resourceInitialData = {},
}) =>
  (updatedFilter?.[index]?.key === "resource" &&
    updatedFilter?.[index]?.searchText === "" &&
    checkModule() &&
    !Object?.keys(resourceInitialData)?.length &&
    selectedObj?.childList) ||
  resourceInitialData;

export const updateResourceSearchText = ({
  updatedFilter = [],
  index = "",
  selectResourceSubMenu = "",
  payload = {},
}) => {
  const updatePayload = payload;
  if (updatedFilter?.[index]?.key === "resource") {
    if (selectResourceSubMenu !== "" && !checkModule()) {
      updatePayload.searchText =
        updatedFilter?.[index]?.tagList?.[selectResourceSubMenu]?.searchTagText;
      updatePayload.servicesList = [selectResourceSubMenu];
    }

    if (checkModule()) {
      updatePayload.searchText = updatedFilter?.[index]?.searchText || "";
    }
  }
};
