import { getCurrencySymbol } from "../../../../../../Utils/commonUtils";

const totalCostHeader = {
  Header: "Total Cost",
  accessor: "total",
  headerClass: "text-right !sticky top-[0px] right-[-1px] z-[11]",
  colSpan: 1,
  rowSpan: 2,
};

const accountIdHeader = {
  Header: "Account ID",
  accessor: "linked_account_id",
  colSpan: 1,
  rowSpan: 2,
  headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
};

const interAzHeader = {
  Header: "Inter AZ ",
  id: "inter_az_usage",
  rowSpan: 1,
  columns: ["inter_az_cost", "inter_az_usage"],
  sortingDisabled: true,
  colSpan: 2,
};

const internetOutHeader = {
  Header: "Internet (Out) ",
  sortingDisabled: true,
  id: "internet_out_usage",
  columns: ["internet_out_usage", "internet_out_cost"],
  rowSpan: 1,
  colSpan: 2,
};

const regionToRegionHeader = {
  Header: "Region to Region ",
  sortingDisabled: true,
  columns: ["region_to_region_usage", "region_to_region_cost"],
  id: "region_to_region_usage",
  rowSpan: 1,
  colSpan: 2,
};

const regionHeader = {
  Header: "Region",
  accessor: "region",
  colSpan: 1,
  rowSpan: 2,
  headerClass: "text-left",
};

const interAzUsage = {
  Header: "Quantity (GB)",
  accessor: "inter_az_usage",
  headerClass: "!text-right",
};
const interAzCost = {
  Header: "Cost",
  accessor: "inter_az_cost",
  headerClass: "!text-right",
};

const internetOutUsage = {
  Header: "Quantity (GB)",
  accessor: "internet_out_usage",
  headerClass: "!text-right",
};

const internetOutCost = {
  Header: "Cost",
  accessor: "internet_out_cost",
  headerClass: "!text-right",
};

const regionToRegionUsage = {
  Header: "Quantity (GB)",
  accessor: "region_to_region_usage",
  headerClass: "!text-right",
};

const regionToRegionCost = {
  Header: "Cost",
  accessor: "region_to_region_cost",
  headerClass: "!text-right",
};
const DATA_EC2_BODY_HEADERS = [
  {
    accessor: "linked_account_id",
    headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
    defaultValue: "",
  },
  {
    accessor: "name",
    defaultValue: "",
  },
  {
    accessor: "instance_id",
    defaultValue: "",
  },
  {
    accessor: "region",
    defaultValue: "",
  },
  {
    accessor: "inter_az_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "inter_az_cost",
    defaultValue: "",
    decimalPlaces: 2,
    prefix: getCurrencySymbol(),
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_cost",
    defaultValue: "",
    prefix: getCurrencySymbol(),
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_cost",
    defaultValue: "",
    prefix: getCurrencySymbol(),
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "total",
    defaultValue: "",
    prefix: getCurrencySymbol(),
    decimalPlaces: 2,
    headerClass: "text-right !sticky top-[0px] right-[-1px] z-[11]",
  },
];
const DATA_EC2_HEADERS = [
  [
    accountIdHeader,
    {
      Header: "Name",
      accessor: "name",
      colSpan: 1,
      rowSpan: 2,
      headerClass: "text-left",
    },
    {
      Header: "Instance ID",
      accessor: "instance_id",
      colSpan: 1,
      headerClass: "text-left",
      rowSpan: 2,
    },
    regionHeader,
    interAzHeader,
    internetOutHeader,
    regionToRegionHeader,
    totalCostHeader,
  ],
  [
    interAzUsage,
    interAzCost,
    internetOutUsage,
    internetOutCost,
    regionToRegionUsage,
    regionToRegionCost,
  ],
];

const DATA_ELB_BODY_HEADERS = [
  {
    accessor: "linked_account_id",
    defaultValue: "",
    headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
  },
  {
    accessor: "load_balancer",
    defaultValue: "",
    headerClass: "text-left",
  },
  { accessor: "region", defaultValue: "", headerClass: "text-left" },
  {
    accessor: "inter_az_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "inter_az_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "total",
    defaultValue: "",
    headerClass: "!text-right !sticky top-[0px] right-[-1px] z-[11]",
    prefix: "$",
    decimalPlaces: 2,
  },
];
const DATA_ELB_HEADERS = [
  [
    accountIdHeader,
    {
      Header: "Load Balancer",
      accessor: "load_balancer",
      colSpan: 1,
      rowSpan: 2,
      headerClass: "text-left",
    },
    regionHeader,
    interAzHeader,
    internetOutHeader,
    regionToRegionHeader,
    totalCostHeader,
  ],
  [
    interAzUsage,
    interAzCost,
    internetOutUsage,
    internetOutCost,
    regionToRegionUsage,
    regionToRegionCost,
  ],
];

const DATA_VPC_BODY_HEADERS = [
  {
    accessor: "linked_account_id",
    defaultValue: "",
    headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
  },
  { accessor: "vpc", defaultValue: "", headerClass: "text-left" },
  { accessor: "region", defaultValue: "", headerClass: "text-left" },
  {
    accessor: "inter_az_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "inter_az_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "total",
    defaultValue: "",
    headerClass: "!text-right !sticky top-[0px] right-[-1px] z-[11]",
    prefix: "$",
    decimalPlaces: 2,
  },
];
const DATA_VPC_HEADERS = [
  [
    accountIdHeader,
    {
      Header: "VPC",
      accessor: "vpc",
      colSpan: 1,
      rowSpan: 2,
      headerClass: "text-left",
    },
    regionHeader,
    interAzHeader,
    internetOutHeader,
    regionToRegionHeader,
    totalCostHeader,
  ],
  [
    interAzUsage,
    interAzCost,
    internetOutUsage,
    internetOutCost,
    regionToRegionUsage,
    regionToRegionCost,
  ],
];

const DATA_S3_BODY_HEADERS = [
  {
    accessor: "linked_account_id",
    defaultValue: "",
    headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
  },
  {
    accessor: "bucket_name",
    defaultValue: "",
    headerClass: "text-left",
  },
  { accessor: "region", defaultValue: "", headerClass: "text-left" },
  {
    accessor: "inter_az_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "inter_az_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "others_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "others_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "total",
    defaultValue: "",
    headerClass: "!text-right !sticky top-[0px] right-[-1px] z-[11]",
    prefix: "$",
    decimalPlaces: 2,
  },
];
const DATA_S3_HEADERS = [
  [
    accountIdHeader,
    {
      Header: "Bucket Name",
      accessor: "bucket_name",
      colSpan: 1,
      rowSpan: 2,
      headerClass: "text-left",
    },
    regionHeader,
    interAzHeader,
    internetOutHeader,
    regionToRegionHeader,
    {
      Header: "Others ",
      id: "others_usage",
      columns: ["others_usage", "others_cost"],
      rowSpan: 1,
      colSpan: 2,
      sortingDisabled: true,
    },
    totalCostHeader,
  ],
  [
    interAzUsage,
    interAzCost,
    internetOutUsage,
    internetOutCost,
    regionToRegionUsage,
    regionToRegionCost,
    {
      Header: "Quantity (GB)",
      accessor: "others_usage",
      headerClass: "!text-right",
    },
    {
      Header: "Cost",
      accessor: "others_cost",
      headerClass: "!text-right",
    },
  ],
];

const DATA_RDS_BODY_HEADERS = [
  {
    accessor: "linked_account_id",
    defaultValue: "",
    headerClass: "text-left !sticky top-[0px] left-[0px] z-[11]",
  },
  {
    accessor: "rds_instance_name",
    defaultValue: "",
    headerClass: "text-left",
  },
  { accessor: "region", defaultValue: "", headerClass: "text-left" },
  {
    accessor: "inter_az_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "inter_az_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "internet_out_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_usage",
    defaultValue: "",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "region_to_region_cost",
    defaultValue: "",
    prefix: "$",
    decimalPlaces: 2,
    headerClass: "!text-right",
  },
  {
    accessor: "total",
    defaultValue: "",
    headerClass: "!text-right !sticky top-[0px] right-[-1px] z-[11]",
    prefix: "$",
    decimalPlaces: 2,
  },
];
const DATA_RDS_HEADERS = [
  [
    accountIdHeader,
    {
      Header: "Instance Name",
      accessor: "rds_instance_name",
      colSpan: 1,
      rowSpan: 2,
      headerClass: "text-left",
    },
    regionHeader,
    interAzHeader,
    internetOutHeader,
    regionToRegionHeader,
    totalCostHeader,
  ],
  [
    interAzUsage,
    interAzCost,
    internetOutUsage,
    internetOutCost,
    regionToRegionUsage,
    regionToRegionCost,
  ],
];

export const DATA_EC2 = {
  headers: DATA_EC2_HEADERS,
  bodyHeaders: DATA_EC2_BODY_HEADERS,
};

export const DATA_ELB = {
  headers: DATA_ELB_HEADERS,
  bodyHeaders: DATA_ELB_BODY_HEADERS,
};

export const DATA_RDS = {
  headers: DATA_RDS_HEADERS,
  bodyHeaders: DATA_RDS_BODY_HEADERS,
};

export const DATA_S3 = {
  headers: DATA_S3_HEADERS,
  bodyHeaders: DATA_S3_BODY_HEADERS,
};

export const DATA_VPC = {
  headers: DATA_VPC_HEADERS,
  bodyHeaders: DATA_VPC_BODY_HEADERS,
};

export const DATA_TRANSFER_TOP_20_HEADERS = {
  "Elastic Load Balancing": DATA_ELB,
  "Elastic Compute Cloud": DATA_EC2,
  "Simple Storage Service": DATA_S3,
  "Virtual Private Cloud": DATA_VPC,
  "Relational Database Service": DATA_RDS,
};
