/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, Fragment, useRef } from "react";
import "./style.scss";
import { useNavigate } from "react-router";
import cloneDeep from "lodash.clonedeep";
import { Checkbox } from "@mui/material";
import { noop, isBilldeskModule } from "../../../Utils/commonUtils";
import { ReactComponent as SortedIcon } from "../../../Assets/icons/sortingIcon.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
import Loader from "../Loader";
import TableFilter from "../FilterMultiselect/TableFilter";
import SearchBar from "../../FormConfig/Form/Components/SearchBar";
import Button from "../../FormConfig/Form/Components/Button";
import {
  applyFilters,
  cancelFilterHandler,
  createTableFiltersUpdated,
  disableHandling,
  handleFilters,
  OnIncludeChange,
  onRangeChange,
  sortedData,
  onDateChangeFilter,
} from "../../../Utils/filterUtils";
import { ResetIcon, LensResetIcon } from "../../../Constants/icons";
import { getSortIcon, getClassName, handleItemAccessor } from "./utils";
import ColumnSelector from "../Multiselect/columnSelector";
import { addDataToDb, getDataFromDb, openDB } from "../../../Utils/indexedDBUtils";
import { PERMISSIONS_ENUM } from "../../../Constants/permissions";
import AccessDeniedMessage from "../AccessDeniedMessage";
// eslint-disable-next-line import/no-cycle
import { getDownloadXLSButton } from "../../Dashboard/CustomerManagement/CreateCustomer/utils";
import BasicSelect from "../../FormConfig/Form/Components/DropDown";
import { bindEvent, getIcons } from "./service/util";
import { unsubscribe } from "../../../Utils/events";
import VirtualizedData from "./VirtualizedData";
import HighLightText from "./HighLightText";
import TanStackVirtualization from "./TannerVirtualizer";

function FilterTable(props) {
  const navigate = useNavigate();
  const {
    config: { onIconClick, currentParent = {} },
    config,
    hasPermission = noop,
    disableFunctionality = false,
    disableResetButton,
    isLoading,
    searchEnabled,
    searchHandler,
    searchTerm,
    removeTablePadding = false,
    tableStyle,
    wrapperClass,
    heading,
    btnArray,
    renderTooltipHTML,
    onAddCart,
    onInputChange,
    filterSorting = true,
    disableHeadersClass = "",
    noDataHTML = null,
    asChild,
    addDataToDB,
    appliedFilters,
    deleteData,
    updateParentFilterHandler,
    tableWrapperClass = "",
    columnSelector = false,
    min = Number.NEGATIVE_INFINITY,
    selectedTab = "ec2",
    columnSelectorKey,
    minDate,
    maxDate,
    showFooter = false,
    footerConfig,
    V2 = false,
    filterRefClass = "filter_div",
    tableHeading = "",
    tableHeadingStyle = "",
    TopRightPlacementComponent = null,
    searchPlaceHolder = "",
    handleChange,
    createFiltersFromWebWorker = false,
    rowRenderKey = "",
    tableHeadingId = "",
    allowCalendarFilter = false,
    V3 = false,
    isExpandable = false,
    expandedRows = noop,
    expandingRowsAccessor = "id",
    expandedBodyHtml = noop,
    accessDeniedWrapperClass = "",
    mouseOverFilter = false,
    showClearFilterSeparately = false,
    viewportAwareDatePopover = false,
    stickyHeader = "",
    maxTableHeight = "",
    shadowAsBorder,
    disableFilterAndSorting = false,
    tableResetIconWrapper = "",
    filterTableConfiguration = null,
    showReset = true,
    singleLevelSelectAll = false,
    dntShowSelector = false,
    filterWrapperClass = "",
    searchResetEnabled = false,
    squeezeTable = false,
    overrideTopRightComponentPermissions = false,
    tableName = "",
    searchIconPostion = "",
    handleChecked = null,
    applyVirtualization = false,
    filterClass = "",
    headerSticky = false,
    checkBoxEnabled = noop,
    checkBoxFilter = false,
    tanstackVirtulization = false,
    overScan = 10,
    rowHeight = 21,
    minTableWidth = "",
    lensVersion = false,
    backgroundOnHover = false,
    isPreCheckedAndDisabled = () => {},
    doNotDisableRow = false,
  } = props;

  const hasReadPermission = hasPermission(PERMISSIONS_ENUM.READ);
  const hasWritePermission = hasPermission(PERMISSIONS_ENUM.WRITE);
  const hasDeletePermission = hasPermission(PERMISSIONS_ENUM.DELETE);

  const [upadteConfig, setUpdatedConfig] = useState(cloneDeep(config));
  const [filters, setFilters] = useState([]);
  const [applyDefaultFilters, setApplyDefaultFilters] = useState(true);
  const [ascending, setAscending] = useState(true);

  const [filtersCopy, setFiltersCopy] = useState([]);
  const [filterSelected, setFilterSelected] = useState([]);
  const [data, setData] = useState(config.data);
  const [dataCopy, setDataCopy] = useState([]);
  const [selectedSortHeader, setSelectedSortHeader] = useState("");
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [resetClicked, setResetClicked] = useState(true);
  const [searchTermFromPrompt, setSearchTermFromPrompt] = useState("");
  const [checkedRows, setCheckedRows] = useState(new Set([]));

  const parentRef = useRef(null);
  const dataRef = useRef(config?.data);
  const searchRef = useRef("");
  const configRef = useRef(config?.headers);
  const tableRef = useRef();

  const areAllRowsChecked = (rows) => {
    // Early return if rows array is empty
    if (!rows.length) return false;

    // Get all IDs from rows array
    const rowIds = rows.map((obj) => obj.id);

    // Check if every ID from rows exists in checkedRows Set
    return rowIds.every((id) => checkedRows.has(id));
  };

  const handleCheckboxClick = (rowId, mode = "single", objects = []) => {
    if (mode === "single") {
      setCheckedRows((prev) => {
        const updatedCheckedRows = new Set(prev);
        if (updatedCheckedRows.has(rowId)) {
          updatedCheckedRows.delete(rowId);
        } else {
          updatedCheckedRows.add(rowId);
        }
        if (handleChecked) handleChecked(data, updatedCheckedRows);
        return updatedCheckedRows;
      });
    } else if (mode === "all") {
      if (areAllRowsChecked(objects)) {
        setCheckedRows(new Set());
        if (handleChecked) handleChecked(data, new Set());
      } else {
        setCheckedRows((prev) => {
          const updatedCheckedRows = new Set(prev);
          // Get all rowIds from the objects array
          const rowIds = objects.map((obj) => obj.id);

          // Add all rowIds to the Set
          rowIds.forEach((id) => updatedCheckedRows.add(id));

          if (handleChecked) handleChecked(data, updatedCheckedRows);
          return updatedCheckedRows;
        });
      }
    }
  };

  useEffect(() => {
    setData(config.data);
    setDataCopy(config.data);
  }, [config.data]);

  useEffect(() => {
    dataRef.current = config?.data || [];
    searchRef.current = "";
    configRef.current = config?.headers || [];
    setSearchTermFromPrompt("");
    if (parentRef?.current && applyVirtualization) {
      parentRef.current.scrollTop = 0;
    }
  }, [config?.data, applyVirtualization]);

  const [isExpanded, setIsExpanded] = useState(false);

  const filterData = (arrayObj) => {
    const newData = applyFilters(dataCopy, filtersCopy, arrayObj || filterSelected);
    const updatedUserList = { ...data };
    updatedUserList.data = newData?.filteredData;
    setData(updatedUserList.data);
    setFilterSelected(newData?.filters);
    setFilters(newData?.filters);
    if (addDataToDB) {
      addDataToDB(newData?.filters);
    }
    if (updateParentFilterHandler) {
      updateParentFilterHandler(newData?.filters);
    }
  };

  const onIncludeChange = (name, value) => {
    const includeChangeValue = OnIncludeChange(name, value, filterSelected, filters);
    setFilterSelected([...includeChangeValue]);
    if (updateParentFilterHandler) {
      updateParentFilterHandler([...includeChangeValue]);
    }
  };

  const createFilters = async (updatedData) => {
    try {
      const updatedFilters = createFiltersFromWebWorker
        ? await createTableFiltersUpdated(
            updatedData,
            createFiltersFromWebWorker,
            allowCalendarFilter
          )
        : createTableFiltersUpdated(
            updatedData,
            createFiltersFromWebWorker,
            allowCalendarFilter,
            config
          );
      setFilters(updatedFilters);
      setFiltersCopy(updatedFilters);
      setFilterSelected(updatedFilters);
    } catch (error) {
      console.error("Error creating filters:", error);
    }
  };

  const getFilteredHeadersForColumnSelector = (headers, selectedIndexDbHeaders = []) => {
    const updatedHeaders = headers.filter(
      (item) =>
        selectedIndexDbHeaders.includes(item.accessor) ||
        item?.props?.doNotAddColumnSelector ||
        item?.props?.disabledMenuItem
    );
    return updatedHeaders;
  };

  const restructureHeaders = (configurationHeaders, configureChildHeaders) => {
    const updatedColumnHeaders = [];
    const dbOpen = openDB();
    if (dbOpen) {
      getDataFromDb(columnSelectorKey, (d) => {
        const obj = { ...d };
        configurationHeaders.forEach((item) => {
          if (!item?.props?.doNotAddColumnSelector) {
            updatedColumnHeaders.push({
              id: item.accessor,
              name: item.Header,
              checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
              disabledMenuItem: item?.props?.disabledMenuItem || false,
            });
          }
        });
        if (configureChildHeaders) {
          configureChildHeaders.forEach((item) => {
            if (!item?.props?.doNotAddColumnSelector) {
              updatedColumnHeaders.push({
                id: item.accessor,
                name: item.Header,
                checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
                disabledMenuItem: item?.props?.disabledMenuItem || false,
              });
            }
          });
        }
        const configClone = cloneDeep(config);
        setUpdatedConfig({
          ...config,
          headers: getFilteredHeadersForColumnSelector(
            configClone.headers,
            obj[selectedTab]?.columHeaders
          ),
          ...(configClone.innerChildHeaders && {
            innerChildHeaders: getFilteredHeadersForColumnSelector(
              configClone.innerChildHeaders,
              obj[selectedTab]?.columHeaders
            ),
          }),
        });
        setColumnHeaders(updatedColumnHeaders);
      });
    }
  };

  const updateFilters = () => {
    const filter = [...filters];
    const { defaultSelectedFilter } = config;
    const updatedFilter = filter.map((item) => {
      const selectedFilter = defaultSelectedFilter.find((_) => _.name === item.name);
      if (selectedFilter) {
        return { ...item, value: [...selectedFilter.value] };
      }
      return { ...item };
    });

    if (updatedFilter.length) {
      const newData = applyFilters(config.data, updatedFilter, updatedFilter);
      const updatedUserList = { ...data };
      updatedUserList.data = newData?.filteredData;
      setData(updatedUserList.data);
      setFilterSelected(updatedFilter);
      setFilters(updatedFilter);
      setApplyDefaultFilters(false);
    }
  };

  const calculateCustomSearchItems = () =>
    configRef?.current?.filter((item) => item?.enableCustomSearch)?.map((keys) => keys?.accessor);

  function dataSearchHandler(userConfirmed = "") {
    const term = userConfirmed?.toLowerCase();
    const customSearchKeys = calculateCustomSearchItems();
    const updatedData = dataRef?.current?.filter((item) => {
      const customSearchItem = customSearchKeys?.map((key) => item?.[key]);
      return Object.values(customSearchItem)?.some((value) =>
        String(value)?.toLowerCase()?.includes(term)
      );
    });

    if (updatedData?.length) {
      setData(updatedData);
    } else {
      setData(dataRef?.current);
    }
  }

  const setStatesForVirtualization = (userConfirmed = "") => {
    searchRef.current = userConfirmed;
    setSearchTermFromPrompt(userConfirmed);
    dataSearchHandler(userConfirmed);
  };

  const crtlHandler = (event) => {
    if ((event?.ctrlKey || event?.metaKey) && event?.key === "f") {
      event?.preventDefault();
      const userConfirmed = window.prompt("Search anything on this table", searchRef?.current);
      if (userConfirmed) {
        setStatesForVirtualization(userConfirmed);
      } else {
        setStatesForVirtualization("");
      }
    }
  };

  const bindCustomFindEvent = () => {
    document.addEventListener("keydown", crtlHandler);
  };

  const unBindCustomFindEvent = () => {
    document.removeEventListener("keydown", crtlHandler);
  };

  useEffect(() => {
    if (applyVirtualization) {
      bindCustomFindEvent();
    } else {
      unBindCustomFindEvent();
    }
    return () => {
      unBindCustomFindEvent();
    };
  }, [applyVirtualization]);

  // eslint-disable-next-line max-statements
  useEffect(() => {
    // Fixing checkbox after filter applied only for V3 (tuner) to prevent application level impact
    if (!dataCopy?.length && checkBoxFilter) {
      createFilters(config.data);
      setDataCopy(config.data);
      setData(config.data);
      if (columnSelector) {
        restructureHeaders(config.headers);
        if (Object.keys(currentParent).length === 0) {
          restructureHeaders(config?.headers, config?.innerChildHeaders);
        }
      } else {
        setUpdatedConfig(config);
      }
      if (appliedFilters?.length > 0) {
        const newData = applyFilters(config.data, appliedFilters, appliedFilters);
        const updatedUserList = { ...data };
        updatedUserList.data = newData?.filteredData;
        setData(updatedUserList.data);
        setFilterSelected(appliedFilters);
        setFilters(appliedFilters);
      }
    } else if (!checkBoxFilter) {
      createFilters(config.data);
      setDataCopy(config.data);
      setData(config.data);
      if (columnSelector) {
        restructureHeaders(config.headers);
        if (Object.keys(currentParent).length === 0) {
          restructureHeaders(config?.headers, config?.innerChildHeaders);
        }
      } else {
        setUpdatedConfig(config);
      }
      if (appliedFilters?.length > 0) {
        const newData = applyFilters(config.data, appliedFilters, appliedFilters);
        const updatedUserList = { ...data };
        updatedUserList.data = newData?.filteredData;
        setData(updatedUserList.data);
        setFilterSelected(appliedFilters);
        setFilters(appliedFilters);
      }
    }
  }, [config.data, config.headers, resetClicked]);

  useEffect(() => {
    if (config?.defaultSelectedFilter && applyDefaultFilters && data?.length && dataCopy?.length) {
      unsubscribe("filter-applied");
      updateFilters();
      bindEvent(config, dataCopy, filtersCopy, filterSelected, setData);
    }

    const allExpanded = data?.every((element) => element?.expanded);
    setIsExpanded(allExpanded);
  }, [data, dataCopy]);

  const handleFiltersTable = (value, name) => {
    const updatedFilterSelected = handleFilters(value, name, filterSelected, filters);
    setFilterSelected(updatedFilterSelected);
  };

  const onRangeChangeTable = (value, name) => {
    const arrayObj = onRangeChange(value, name, filterSelected);
    setFilterSelected(arrayObj);
    filterData(arrayObj);
  };

  const onDateChangeTable = (startDate, endDate, name) => {
    const arrayObj = onDateChangeFilter(startDate, endDate, name, filterSelected);
    setFilterSelected(arrayObj);
    filterData(arrayObj);
  };

  const cancelFilter = (selectedList) => {
    const newFilterSelected = cancelFilterHandler(filterSelected, selectedList);
    setFilterSelected(newFilterSelected);
    setFilters(newFilterSelected);
  };

  const sortHandler = (sortBy, dateFormat = "DD/MM/YYYY HH:mm A", item = "") => {
    const updatedData = sortedData(sortBy, data, ascending, dateFormat, item);
    setData(updatedData);
    setSelectedSortHeader(sortBy);
    setAscending(!ascending);
  };

  const showSortIcon = (arr, key) => {
    const firstEle = arr[0];
    return arr.every((v) => {
      const value = v[key];
      return value === firstEle[key];
    });
  };

  const renderTableFilters = (item) => (
    <TableFilter
      filters={appliedFilters || filters}
      heading={item.accessor}
      filterType={item.filterType}
      disableResetButton={disableResetButton}
      filterSelected={filterSelected}
      handleFilters={handleFiltersTable}
      onIncludeChange={onIncludeChange}
      filterData={filterData}
      cancelFilter={cancelFilter}
      onRangeChange={onRangeChangeTable}
      showSortIcon={!showSortIcon(data, item.accessor)}
      filterRefClass={filterRefClass}
      onDateChange={onDateChangeTable}
      showClearFilterSeparately={showClearFilterSeparately}
      viewportAwareDatePopover={viewportAwareDatePopover}
      tableRef={tableRef}
      lensVersion={lensVersion}
    />
  );
  const renderSortFilter = (item) =>
    V2 || V3 ? (
      <>
        <span
          className={`sorting-data ${item?.sortedIcon ? "" : "!cursor-default"} !inline-block	`}
          onClick={() =>
            item?.sortedIcon
              ? sortHandler(item?.sortKey || item?.accessor, item?.dateFormat, item)
              : noop
          }
          aria-hidden
        >
          {item.Header}
        </span>
        <span
          className={`mr-[4px] !mb-[3px] absolute right-0 inline-flex ${
            mouseOverFilter ? "ck--filter-block" : ""
          } ${filterClass}`}
        >
          {!item.hideFilters && renderTableFilters(item)}
          <span
            onClick={() => sortHandler(item.sortKey || item.accessor, item?.dateFormat, item)}
            aria-hidden
            className="cursor-pointer"
          >
            {item.sortedIcon &&
              !V3 &&
              getSortIcon(
                item?.sortKey === selectedSortHeader || item?.accessor === selectedSortHeader,
                ascending,
                lensVersion
              )}
          </span>
        </span>
      </>
    ) : (
      <span id="header_name">
        <span className={`${item?.sortedIcon ? "sorting-data " : "cursor-default"}`}>
          {item.Header === "Email" ? "Email ID" : item.Header}
        </span>
        {item.sortedIcon && filterSorting && (
          <span className={`show-sort-filter ${disableHeadersClass}`}>
            {item?.sortedIcon && !showSortIcon(data, item?.accessor) && (
              <SortedIcon
                onClick={() => sortHandler(item?.sortKey || item?.accessor, item?.dateFormat, item)}
              />
            )}
            {item?.Header !== "Last Login" &&
              item?.Header !== "Sign Up Date" &&
              item?.Header !== "Last Month Bill" &&
              item?.Header !== "Last 3 Month Average" &&
              item?.Header !== "Account Creation DateTime" &&
              renderTableFilters(item)}
          </span>
        )}
      </span>
    );

  const updateData = (updatedRow, id) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return updatedRow;
      }
      return row;
    });
    setData(updatedData);
  };

  const renderBodyIcons = (tableBody, icons, index) => {
    const permissionsMap = {
      WRITE: hasWritePermission,
      READ: hasReadPermission,
      DELETE: hasDeletePermission,
    };
    const dataObj = {
      min,
      minDate,
      maxDate,
    };
    const changeHandlers = {
      onIconClick,
      onInputChange,
      onAddCart,
    };
    const iconList = icons?.map((icon) =>
      getIcons(
        icon,
        tableBody,
        index,
        permissionsMap,
        hasWritePermission,
        changeHandlers,
        dataObj,
        lensVersion
      )
    );

    return iconList;
  };

  const tableHeaders = upadteConfig.headers?.filter((header) => {
    if (header.accessor === "actions") {
      if (header?.overwritePermissions) {
        return true;
      }
      if (!hasWritePermission && !hasDeletePermission) {
        const firstRow = upadteConfig?.data?.[0];
        // check for show or not
        if (isBilldeskModule() && firstRow) {
          const actionArray = renderBodyIcons(firstRow, firstRow?.[header.accessor], 0);
          const allNull = actionArray?.every((value) => value === null);
          return !allNull;
        }
        return false;
      }
    }
    return true;
  });

  const checkChecked = () => {
    let checked;
    if (tanstackVirtulization) {
      checked = areAllRowsChecked(dataCopy);
    } else {
      checked = data.every(
        (item) => dataCopy?.find((row) => row?.id === item?.id)?.checked === true
      );
    }
    return checked;
  };

  const checkIndeterminate = () => {
    const checked = data.some(
      (item) => dataCopy?.find((row) => row?.id === item?.id)?.checked === true
    );
    return checkChecked() ? false : checked;
  };

  const handleAll = (event, operation) => {
    const {
      target: { checked },
    } = event;

    let updatedDataCopy;

    switch (operation) {
      case "toggleAll":
        updatedDataCopy = dataCopy.map((item) => ({ ...item, expanded: !isExpanded }));
        setIsExpanded(!isExpanded);
        setData(updatedDataCopy);
        break;
      default:
        updatedDataCopy = dataCopy.map((item) => {
          if (data.find((row) => row.id === item.id)) {
            return { ...item, checked };
          }
          return item;
        });
        setDataCopy(updatedDataCopy);
        if (handleChecked && !tanstackVirtulization) handleChecked(updatedDataCopy);
        if (tanstackVirtulization) {
          handleCheckboxClick(null, "all", updatedDataCopy);
        }
        break;
    }
  };

  const renderHeader = () => {
    const calcCheck = () => {
      if (currentParent && currentParent.childAccounts) {
        return currentParent.childAccounts
          .filter((item) => item.status === "Active" && !item.endDate)
          .every((item) => item.checked === true);
      }

      if (!data || data.length === 0) {
        return false;
      }

      const filteredData = data.filter((item) =>
        item?.childAccounts
          ? item?.childAccounts.some((child) => child?.status === "Active" && !child?.endDate)
          : item?.status === "Active"
      );

      if (filteredData.length === 0) {
        return false;
      }
      return filteredData.every((item) => item.checked === true);
    };

    const headers = [];
    if (Array.isArray(tableHeaders) && tableHeaders.length) {
      for (let i = 0; i < tableHeaders.length; i += 1) {
        const item = tableHeaders[i];
        const key = item.accessor + item.icons;

        if (!item.noRender) {
          headers.push(
            <th
              key={key}
              className={`text-center ${item.headerClass || ""} ${
                item.Header === "Actions" ? "w-[10%]" : ""
              } ${item?.customClass} ${!item.sortedIcon ? "ck-no-sorting" : ""} `}
            >
              {handleItemAccessor(
                { item, data, currentParent, singleLevelSelectAll, isExpanded },
                {
                  checkChecked,
                  calcCheck,
                  handleAll,
                  handleChange,
                  renderSortFilter,
                  setIsExpanded,
                  checkIndeterminate,
                }
              )}
            </th>
          );
        }
      }
    }
    return headers;
  };

  const renderSearchBar = () => (
    <SearchBar
      searchTerm={searchTerm}
      onChangeHandler={searchHandler}
      placeholder={searchPlaceHolder}
      styleSearch=" !mt-0 !mx-0"
      searchResetEnabled={searchResetEnabled}
      searchIconPostion={searchIconPostion}
    />
  );

  const renderCellValue = (tableHeader, tableBody) => {
    if (tableHeader?.tooltip) {
      return (
        <span className="filterTable_tooltip">
          {tableBody[tableHeader.accessor]}
          <div className="infoTooltip">
            <span>
              <InfoIcon />
            </span>
            {renderTooltipHTML && renderTooltipHTML(tableBody)}
          </div>
        </span>
      );
    }

    if (tableHeader?.checkBox && tanstackVirtulization) {
      return (
        <Checkbox
          name="name"
          className="table_checkbox"
          checked={
            isPreCheckedAndDisabled(tableBody) ||
            (checkedRows.has(tableBody?.id) && checkBoxEnabled(tableBody))
          }
          size="small"
          disabled={!checkBoxEnabled(tableBody)}
          onClick={(e) => e.stopPropagation()} // Prevents scrolling issue
          onChange={() => {
            handleCheckboxClick(tableBody?.id);
          }}
        />
      );
    }

    if (tableHeader?.checkBox && !tanstackVirtulization) {
      return (
        <Checkbox
          name="name"
          className="table_checkbox"
          checked={
            dataCopy?.find((item) => tableBody?.id === item?.id)?.checked &&
            checkBoxEnabled(dataCopy?.find((item) => tableBody?.id === item?.id))
          }
          size="small"
          disabled={!checkBoxEnabled(dataCopy?.find((item) => tableBody?.id === item?.id))}
          onChange={() => {
            let updatedItems;
            setDataCopy((prevList) => {
              updatedItems = prevList.map((item) =>
                item.id === tableBody?.id ? { ...item, checked: !item?.checked } : item
              );
              return [...updatedItems];
            });
            if (handleChecked) handleChecked(updatedItems);
          }}
        />
      );
    }
    if (tableHeader?.render) {
      return tableHeader?.render(tableBody);
    }
    if (applyVirtualization && config?.data?.length !== data?.length) {
      return (
        <HighLightText
          text={tableBody?.[tableHeader?.accessor]}
          highlightText={searchTermFromPrompt}
          customHighLight={tableHeader?.enableCustomSearch}
        />
      );
    }
    return tableBody[tableHeader.accessor];
  };

  const renderValue = (tableHeader, tableBody) =>
    renderCellValue(tableHeader, tableBody) === 0
      ? 0
      : renderCellValue(tableHeader, tableBody) || (
          <span className="text-center">{lensVersion ? "-" : "- - -"}</span>
        );

  const renderComponent = (tableHeader, tableBody) =>
    tableHeader.component ? (
      <tableHeader.component
        data={tableBody[tableHeader.accessor]}
        tableRow={tableBody}
        header={tableHeader.Header}
        handleEditclick={(value) => onIconClick("cell_edit", value, tableBody)}
        updateData={updateData}
        hasPermission={hasPermission}
        id={tableBody.id}
        {...(tableHeader?.props || {})}
      />
    ) : (
      renderValue(tableHeader, tableBody)
    );
  const getColumnData = (tableHeader, tableBody) =>
    tableHeader?.customComponent ? (
      <tableHeader.component
        data={tableBody[tableHeader.accessor]}
        tableRow={tableBody}
        header={tableHeader.Header}
        updateData={updateData}
        id={tableBody?.id}
        // handleEditclick={(value) => onIconClick("cell_edit", value, tableBody)}
        {...(tableHeader?.props || {})}
      />
    ) : (
      renderComponent(tableHeader, tableBody)
    );

  const renderTableCells = (tableBody, index) => {
    const tableCells = [];
    if (Array.isArray(tableHeaders) && tableHeaders.length) {
      for (let i = 0; i < tableHeaders.length; i += 1) {
        const tableHeader = tableHeaders[i];
        if (!tableHeader?.noRender) {
          tableCells.push(
            <td
              key={tableHeader.accessor + i}
              className={`text-center ${tableHeader.bodyClass || ""}  ${
                applyVirtualization && index % 2 === 0 ? "!bg-[#FFF]" : "!bg-[#F7F7F7]"
              } ${V3 && index % 2 === 0 ? "tuner-odd-child" : "tuner-even-child"} ${
                applyVirtualization || tanstackVirtulization ? "ck-filter-table-virtualization" : ""
              }`}
            >
              {tableHeader.icons ? (
                <div className="tableBodyIcons">
                  {renderBodyIcons(tableBody, tableBody[tableHeader.accessor], index)}
                </div>
              ) : (
                getColumnData(tableHeader, tableBody)
              )}
            </td>
          );
        }
      }
    }
    return tableCells;
  };

  const handleNestedFilterChange = (updatedFilters) => {
    setFilterSelected(updatedFilters);
  };

  const getTableRowTitle = (tableBody) => {
    if (
      (isBilldeskModule() &&
        typeof tableBody?.status === "string" &&
        !["active", "associated"].includes(tableBody?.status?.toLowerCase())) ||
      (typeof tableBody?.state === "string" &&
        !["active", "associated"].includes(tableBody?.state?.toLowerCase()))
    ) {
      return "This record is inactive";
    }
    return "";
  };

  const renderBody = () =>
    data?.map((tableBody, index) => {
      let tableRowKey;
      if (rowRenderKey) {
        tableRowKey = `${tableBody[rowRenderKey]}`;
      } else {
        tableRowKey = `${tableBody?.id}_random_${Math.random() * 1000}`;
      }
      return (
        <>
          <tr
            title={getTableRowTitle(tableBody)}
            key={tableRowKey}
            className={getClassName(tableBody, disableFunctionality, tableName)}
          >
            {renderTableCells(tableBody, index)}
          </tr>
          {isExpandable && expandedRows.includes(tableBody[expandingRowsAccessor]) && (
            <tr>
              <td colSpan={tableHeaders.length}>
                <div>{expandedBodyHtml(tableBody, index)}</div>
              </td>
            </tr>
          )}
          {Array.isArray(tableBody.childAccounts) && tableBody?.expanded && (
            <tr>
              <td colSpan={tableHeaders.length} className="inner-table">
                {config?.additionalContent(tableBody)}
                <FilterTable
                  config={{
                    data: tableBody.childAccounts,
                    headers: upadteConfig.innerChildHeaders,
                    currentParent: tableBody,
                  }}
                  // hasPermission={hasPermission}
                  // selectedTab={selectedTab?.value}
                  handleChange={handleChange}
                  updateParentFilterHandler={handleNestedFilterChange}
                  filterRefClass="filter_div_1"
                />
              </td>
            </tr>
          )}
        </>
      );
    });

  const resetButton = () => {
    if (applyVirtualization || tanstackVirtulization) {
      parentRef.current.scrollTop = 0;
      setSearchTermFromPrompt("");
      searchRef.current = "";
    }
    if (deleteData) {
      deleteData();
    }
    if (updateParentFilterHandler) {
      updateParentFilterHandler();
    }
    setData(dataCopy);
    createFilters(dataCopy);
    setResetClicked(!resetClicked);
  };

  // Function to set IndexDB column selector
  const setIndexDbColumnSelector = (updatedColumnHeaders) => {
    const dbOpen = openDB();
    if (dbOpen) {
      // Step 1: Retrieve data from IndexDB using columnSelectorKey
      getDataFromDb(columnSelectorKey, (d) => {
        // Step 2: Filter updatedColumnHeaders to get selected headers from column selector dropdown
        const selectedHeaders = updatedColumnHeaders
          .filter((item) => item?.checked)
          ?.map((item) => item.id);

        // Step 3: Create a copy of the retrieved data to avoid mutation and update with selected headers
        const obj = {
          ...d,
          [selectedTab]: { columHeaders: selectedHeaders },
        };

        // Step 4: Add updated data back to IndexDB with key "virtualRiColumnSelector"
        addDataToDb(columnSelectorKey, obj);
      });
    }
  };

  const setUpdatedHeaders = (list) => {
    const checkedIds = list.filter((item) => item.checked).map((item) => item.id);
    const checkedItems = config?.headers.filter(
      (item) =>
        item?.props?.doNotAddColumnSelector ||
        item?.props?.disabledMenuItem ||
        checkedIds.includes(item.accessor)
    );
    const newConfig = {
      ...config,
      headers: checkedItems,
      ...(config?.innerChildHeaders && {
        innerChildHeaders: config?.innerChildHeaders.filter(
          (item) =>
            item?.props?.doNotAddColumnSelector ||
            item?.props?.disabledMenuItem ||
            checkedIds.includes(item.accessor)
        ),
      }),
    };
    setIndexDbColumnSelector(list);
    setUpdatedConfig(newConfig);
    setColumnHeaders(list);
  };

  const onReset = () => {
    const dbOpen = openDB();
    if (dbOpen) {
      // Step 1: Retrieve data from IndexDB
      getDataFromDb(columnSelectorKey, (d) => {
        // Step 2: Get selected headers from table config
        const selectedHeaders = config?.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor);

        const selectedInnerHeaders = config.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor);

        // Step 3: Create an updated object with selected headers
        const obj = {
          ...d,
          [selectedTab]: { columHeaders: selectedHeaders },
        };

        // Step 4: Generate updated column headers
        const updatedColumnHeaders = [];
        config.headers.forEach((item) => {
          if (!item?.props?.doNotAddColumnSelector) {
            updatedColumnHeaders.push({
              id: item.accessor,
              name: item.Header,
              checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
              disabledMenuItem: item?.props?.disabledMenuItem || false,
            });
          }
        });

        if (config.innerChildHeaders) {
          config?.innerChildHeaders?.forEach((item) => {
            if (!item?.props?.doNotAddColumnSelector) {
              updatedColumnHeaders.push({
                id: item?.accessor,
                name: item?.Header,
                checked: obj?.[selectedTab]?.innerChildHeaders?.includes(item?.accessor) || false,
                disabledMenuItem: item?.props?.disabledMenuItem || false,
              });
            }
          });
        }

        // Step 5: Update data in IndexDB
        addDataToDb(columnSelectorKey, obj);

        // Step 6: Update config with filtered headers for table
        setUpdatedConfig({
          ...config,
          headers: getFilteredHeadersForColumnSelector(config?.headers, selectedHeaders),
          ...(config?.innerChildHeaders && {
            innerChildHeaders: getFilteredHeadersForColumnSelector(
              config?.innerChildHeaders,
              selectedInnerHeaders
            ),
          }),
        });
        // Step 7: Set column headers state for column manager
        setColumnHeaders(updatedColumnHeaders);
      });
    }
  };

  const checkResetEnable = () => {
    const checkedColumnHeaderIds = columnHeaders
      .filter((item) => item.checked)
      .map((item) => item.id);

    const checkedConfigHeaderIds = config?.headers
      ? config?.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor)
      : [];

    return (
      checkedColumnHeaderIds?.sort()?.toString() === checkedConfigHeaderIds?.sort()?.toString()
    );
  };

  const renderColumnSelector = (showColumnSelector, show) => {
    if (showColumnSelector && show) {
      return (
        <ColumnSelector
          data={columnHeaders}
          searchable
          handleEditclick={setUpdatedHeaders}
          deleteChip={false}
          name="columnSelector"
          noDataMessage="No Record Found"
          placeholder="Column"
          columnManager
          onReset={onReset}
          resetEnable={checkResetEnable()}
        />
      );
    }
    return null;
  };

  const getFooter = () => {
    if (!(showFooter && footerConfig)) {
      return "";
    }
    const footerData = footerConfig(data);
    return (
      <tr className="table-body-row">
        {tableHeaders?.map((internalItem) => (
          <td className={`${footerData?.align || "!text-center"} !font-bold`}>
            {footerData?.[internalItem?.accessor] ? footerData?.[internalItem?.accessor] : ""}
          </td>
        ))}
      </tr>
    );
  };

  const resetHandlerDisable = () => {
    if (searchTermFromPrompt && config?.data?.length !== data?.length && applyVirtualization) {
      return "";
    }
    if (disableHandling(filterSelected)) {
      return "text-[#7c7d7e] pointer-events-none";
    }
    if (searchTermFromPrompt && config?.data?.length === data?.length) {
      return "text-[#7c7d7e] pointer-events-none";
    }
    return (searchTermFromPrompt && config?.data?.length === data?.length) ||
      !disableHandling(filterSelected)
      ? ""
      : "text-[#7c7d7e] pointer-events-none";
  };

  const renderReset = (showResetButton, configuration) =>
    showResetButton &&
    Object.keys(currentParent)?.length === 0 && (
      <span
        aria-hidden="true"
        className={`flex justify-end ${
          configuration?.class
        } gap-x-1 text-[#0A3CA2] text-xs items-center font-medium cursor-pointer pb-[4px] reset-icon-btn ${resetHandlerDisable()}  ${
          lensVersion
            ? "!font-[Inter] !font-semibold !gap-[5px] leading-[14.52px] !text-[#7c7c7c]"
            : ""
        }`}
        onClick={() => resetButton()}
      >
        {lensVersion ? <LensResetIcon /> : <ResetIcon className="w-2 h-2" />} Reset Filters
      </span>
    );

  const renderButton = (configuration) => {
    let isDisabled = false;
    if (configuration?.isDisabledHandler) {
      isDisabled = configuration?.isDisabledHandler(dataCopy);
    }
    if (!configuration?.noRender) {
      return (
        <button
          type="button"
          className={`${configuration?.class} ${isDisabled && "disabled"}`}
          disabled={isDisabled}
          onClick={() => configuration?.onClick(configuration, dataCopy)}
        >
          {configuration.label}
        </button>
      );
    }
    return null;
  };

  const renderDropDown = (configuration, originalData) => {
    const {
      value,
      options,
      name,
      className,
      onChange = noop,
      placeHolder,
      disabled,
      extraDisabledChecks = [],
    } = configuration;
    let shouldRender = true;
    if (extraDisabledChecks?.length > 0) {
      shouldRender = extraDisabledChecks.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        originalData[0]
      );
    }
    if (!shouldRender) {
      return null;
    }
    return (
      <BasicSelect
        name={name}
        value={value}
        className={className}
        options={options}
        handleChange={(e) => onChange(e)}
        placeHolder={placeHolder}
        disabled={disabled}
      />
    );
  };

  const renderCustomComponent = (CustomComponent, originalData = null) => (
    <CustomComponent data={originalData} />
  );

  const renderComponentsFromType = (configuration, originalData = null) => {
    switch (configuration.type) {
      case "reset":
        return renderReset(configuration?.showReset, configuration);
      case "columnManager":
        return renderColumnSelector(configuration?.config?.columnSelector, true);
      case "downloadButton":
        return getDownloadXLSButton(config, configuration.url, configuration.class);
      case "button":
        return renderButton(configuration);
      case "dropdown":
        return renderDropDown(configuration, originalData);
      case "component":
        return renderCustomComponent(configuration?.component, originalData);
      case "text":
        return <p className="table_configuration_text">{configuration?.text}</p>;
      default:
        return null;
    }
  };

  const maxTableHeightStyle = maxTableHeight
    ? {
        maxHeight: maxTableHeight,
        overflow: "auto",
        maxWidth: "fit-content",
      }
    : {};

  const minTableWidthStyle = minTableWidth?.length
    ? {
        minWidth: minTableWidth,
      }
    : {};

  const applyVirtualizationStyle =
    applyVirtualization || tanstackVirtulization
      ? {
          overflowAnchor: "none",
        }
      : {};

  return (
    <div
      className={`${V2 ? "table-wrapper-V2" : ""} ${filterWrapperClass} ${
        shadowAsBorder ? "ck-table--shadow-as-border" : ""
      } ${disableFilterAndSorting ? "ck-table--disable-filter-sorting" : ""} ${
        V3 ? "table-wrapper-V3" : ""
      }
        ${squeezeTable ? "ck-filter-table-squeeze" : ""}
      `}
    >
      {filterTableConfiguration && (
        <div className="table_configuration_wrapper">
          <div className="flex items-center">
            {filterTableConfiguration?.leftConfig?.map((item) => (
              <div className="flex items-center">{renderComponentsFromType(item, config.data)}</div>
            ))}
          </div>

          <div className="flex items-center">
            {filterTableConfiguration?.rightConfig?.map((item) => (
              <div className="flex">{renderComponentsFromType(item, config.data)}</div>
            ))}
          </div>
        </div>
      )}

      <div className={`flex gap-x-2 reset-icon item-center ${tableResetIconWrapper}`}>
        {hasWritePermission &&
          !asChild &&
          btnArray?.map((item) => (
            <Button
              variant={item?.variant}
              className={item.class}
              image={item?.image}
              text={item?.label}
              disabled={item?.disabled || false}
              handleClick={() => {
                if (item?.link) {
                  navigate(item?.link);
                } else {
                  item.handleClick();
                }
              }}
            />
          ))}
        {!!tableHeading && hasReadPermission && (
          <div className="relative pb-[6px]">
            <span className={(!!tableHeadingStyle && tableHeadingStyle) || ""} id={tableHeadingId}>
              {tableHeading}
            </span>
            <div className="absolute top-0 right-0 h-[20px] w-[1px] bg-gray-300" />
          </div>
        )}
        {filterSorting && hasReadPermission && !asChild && (
          <>
            {renderReset(showReset)}
            {TopRightPlacementComponent &&
              ((hasDeletePermission && hasWritePermission) ||
                overrideTopRightComponentPermissions) && <TopRightPlacementComponent />}
          </>
        )}
        {hasReadPermission && renderColumnSelector(columnSelector, !dntShowSelector)}
      </div>
      {!hasReadPermission ? (
        <>
          {" "}
          <AccessDeniedMessage wrapperClass={accessDeniedWrapperClass} />
        </>
      ) : (
        <>
          {" "}
          {isLoading ? (
            <div className="relative mt-9 h-16">
              <Loader />
            </div>
          ) : (
            <div
              className={`table-container bg-white cld-Table ${
                removeTablePadding && "!p-0 !border-none"
              } ${
                tableStyle === "table-grid-ui style-dashboard-grid" ? "table-scrollbar" : ""
              } ${wrapperClass} ${!data?.length ? "no-data-table-container" : ""}`}
              ref={tableRef}
            >
              <p className="heading">{heading}</p>
              {searchEnabled && (
                <div className={`display_flex_between ${!removeTablePadding && "mb-2"}`}>
                  <div className="search-wrapper w-[45%]">{renderSearchBar()}</div>
                </div>
              )}
              <div
                className={`${tableWrapperClass} ${V2 ? "Striped-Table" : ""} ${
                  V3 ? "striped-table" : ""
                } ${lensVersion ? "lens-table" : ""}`}
                ref={applyVirtualization || tanstackVirtulization ? parentRef : {}}
                style={{
                  //  height: applyVirtualization ? "500px" : "auto",
                  //  overflowY: applyVirtualization ? "auto" : "visible",
                  ...applyVirtualizationStyle,
                  ...maxTableHeightStyle,
                  ...minTableWidthStyle,
                }}
              >
                <table
                  className={` ${mouseOverFilter ? "ck-table--mouse-over-filter" : ""} ${
                    disableFilterAndSorting ? "ck-table-disable-filter" : ""
                  }  table ${removeTablePadding && "!rounded-none"} ${tableStyle && tableStyle}  ${
                    backgroundOnHover ? "ck-table--background-on-hover" : ""
                  }`}
                >
                  <thead
                    className={`${stickyHeader ? "ck-table-header-sticky" : ""} ${
                      headerSticky ? "filter_table_sticky_header" : ""
                    }`}
                  >
                    <tr className="table-header">{renderHeader()}</tr>
                  </thead>
                  {data?.length ? (
                    <>
                      <tbody>
                        {tanstackVirtulization ? (
                          <TanStackVirtualization
                            data={data}
                            getTableRowTitle={getTableRowTitle}
                            getClassName={getClassName}
                            disableFunctionality={disableFunctionality}
                            tableName={tableName}
                            renderTableCells={renderTableCells}
                            parentRef={parentRef}
                            rowRenderKey={rowRenderKey}
                            overScan={overScan}
                            rowHeight={rowHeight}
                            doNotDisableRow={doNotDisableRow}
                          />
                        ) : null}
                        {applyVirtualization && !tanstackVirtulization ? (
                          <VirtualizedData
                            data={data}
                            getTableRowTitle={getTableRowTitle}
                            getClassName={getClassName}
                            disableFunctionality={disableFunctionality}
                            tableName={tableName}
                            renderTableCells={renderTableCells}
                            parentRef={parentRef}
                            rowRenderKey={rowRenderKey}
                          />
                        ) : null}
                        {!applyVirtualization && !tanstackVirtulization ? renderBody() : null}
                      </tbody>
                      {getFooter()}
                    </>
                  ) : (
                    <tbody className="relative h-48 w-20">
                      {noDataHTML ? (
                        noDataHTML()
                      ) : (
                        <div className="no_data_found">No Data Available</div>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default FilterTable;
