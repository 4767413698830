import {
  exportCostByCluster,
  exportCostByInstanceType,
  exportCostByNamespace,
  exportCostByPurchaseType,
  exportCostByRegion,
  exportTop20CostUsageTableData,
  getCostByCluster,
  getCostByInstanceType,
  getCostByNameSpace,
  getCostByPurchase,
  getSummaryCardsData,
  getTop20InstanceTableData,
  getCudosDatabaseCards,
  getCostByRegion,
  getTotalAccountsData,
  exportCostByAccount,
  getCostEngine,
  exportCostByEngine,
  getRICoverageData,
  getCostByPricingType,
  getRecommendationSummary,
  exportCostByPricingType,
  getHighlightCardData,
  getCostByUsage,
  getCostByStorageType,
  exportCostByUsageType,
  exportCostByStorageType,
  getDataTransferCostBreakdown,
  // getCostByType,
  getCostByService,
  getCostOfTypeByService,
  getCostOfServiceByType,
  exportCostByService,
  exportCostOfTypeByService,
  exportByResources,
  exportCostOfServiceByType,
  getDataTransferSummaryCards,
  getS3TableData,
  getCostByOperation,
  exportCostByOperation,
  getDynamoDBTableData,
  getDynamoDBDataExport,
  getTopBucketByStorageCost,
  getTopBucketByOperationCost,
  getTopBucketByCost,
  exportTopBucketByCost,
  exportTopBucketByOperationCost,
  exportTopBucketByStorageCost,
  exportS3CostBreakUp,
  getRegionToRegion,
  getCostByInterAZ,
  getCostByInternetOut,
  exportCostByInterAZ,
  exportCostByRegionToRegion,
  exportCostByInternetOut,
  getTabData,
  getCostByOperatingSystem,
  getCostByProcessor,
  exportTotalCostByProcessor,
  exportCostByOperatingSystem,
  getOnDemandSplitCard,
  getUnusedRiSpCost,
  getCostByProcessorArchitecture,
  exportTotalCostByProcessorArchitecture,
  getCostByCPUAndMemory,
  exportCostByCPUAndMemory,
  getCPUAndMemoryCardData,
  exportPollingTop20CostUsageTableData,
} from "./service";
import MonthCards from "../Elasticache/components/MonthCards";
import ElasticSummaryCards from "../Elasticache/components/SummaryCards";
import SummaryCards from "../EKS/component/SummaryCards";
import {
  TOP_20_INSTANCE_HEADERS,
  TOP_20_INSTANCE_HEADERS_ELASTICACHE,
  TOP_20_INSTANCE_HEADERS_RDS,
  PALLETE_COLORS_CUDOS,
  TOP_20_S3_HEADERS,
  TOP_20_INSTANCE_HEADERS_OPENSEARCH,
  TOP_20_INSTANCE_HEADERS_REDSHIFT,
  TOP_20_INSTANCE_HEADERS_EC2,
  TOP_20_INSTANCE_HEADERS_LAMBDA,
  TOP_20_INSTANCE_HEADERS_FARGATE,
} from "../helper/constant";
import Routes from "../../../../../Config/Routes";
import TotalRiCoverageCost from "../Elasticache/components/TotalRICoverage";
import RecommendationSummary from "../Elasticache/components/RecommendationSummary";
import HighLightCards from "../Components/RDS/HighlightCards";
import DataTransferSummaryCards from "../DataTransfer/components/SummaryCards";
import CardTable from "../S3/component/CardTable";
import DynamoDB from "../Database/DynamoDB";
import { getDatabaseData } from "../../CostBreakup/constant";
import Tabs from "../Tabs";
import UnusedCard from "../Compute/Component/UnusedCard";
import { DATA_TRANSFER_TOP_20_HEADERS } from "../Components/MultilevelTable/constant";
import {
  SUMMARY_CARDS_IMAGES,
  SUMMARY_CARDS_IMAGES_ECS,
  SUMMARY_CARDS_IMAGES_FARGATE,
} from "../EKS/common/constant";
import InfoBox from "../../../Common/InfoBox";

export const commonProps = {
  tabs: false,
  hideScroll: true,
  showLabels: "1",
  showMoreIcon: true,
  showGraphExpandIcon: true,
  xaxislinecolor: "#EDEFF0",
  yaxislinecolor: "#EDEFF0",
  legendItemFont: "Inter",
  divLineDashed: "0",
  divLineColor: "#D7D7D7",
  numVDivLines: "0",
  legendIconSides: 0,
  labelFontColor: "#2B2B2B",
  labelFontSize: 9,
  rotateLabels: 0,
  yAxisValueFontColor: "#2B2B2B",
  yAxisValueFontSize: 9,
  height: 326,
  labelStep: "0",
  anchorRadius: "2",
  tooltip:
    "<div class=\"tooltip_wrapper cudos_tooltip\"> <div class='headingCont'> $label  </div>  <span class='row'> <label>$seriesName</label> <b>$dataValue ($percentValue)</b></span><div class='totalStyle'><b>Total</b>$sum</div></div>",
  palettecolors: PALLETE_COLORS_CUDOS.join(","),
};

const COST_BY_ACCOUNTS = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Account",
  name: "costByAccounts",
  serviceCall: getTotalAccountsData,
  exportServiceCall: exportCostByAccount,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Account Name",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_ENGINES = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Engine",
  name: "costByEngines",
  serviceCall: getCostEngine,
  exportServiceCall: exportCostByEngine,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Engine",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_REGION = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Region",
  name: "costByRegions",
  serviceCall: getCostByRegion,
  exportServiceCall: exportCostByRegion,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Region",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_INSTANCE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Instance Type",
  serviceCall: getCostByInstanceType,
  exportServiceCall: exportCostByInstanceType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Instance Type",
    align: "Left",
  },
  name: "costByInstanceType",
  ...commonProps,
});

const COST_BY_PRICING_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Pricing Type",
  serviceCall: getCostByPricingType,
  exportServiceCall: exportCostByPricingType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Pricing Type",
    align: "Left",
  },
  name: "costByPricingType",
  ...commonProps,
});

const COST_BY_CPU_AND_MEMORY = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by CPU and Memory",
  serviceCall: getCostByCPUAndMemory,
  exportServiceCall: exportCostByCPUAndMemory,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Computing Unit",
    align: "Left",
  },
  name: "costByCUPAndMemory",
  ...commonProps,
});

const COST_BY_PURCHASE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Purchase Type",
  name: "costByPurchaseType",
  serviceCall: getCostByPurchase,
  exportServiceCall: exportCostByPurchaseType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_USAGE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Usage",
  name: "costByUsage",
  serviceCall: getCostByUsage,
  exportServiceCall: exportCostByUsageType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Usage Type",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_STORAGE_TYPE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Storage Type",
  name: "costByStorageType",
  serviceCall: getCostByStorageType,
  exportServiceCall: exportCostByStorageType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Purchase Type",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_CLUSTER = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Cluster",
  name: "costByClusters",
  serviceCall: getCostByCluster,
  exportServiceCall: exportCostByCluster,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Cluster",
    align: "Left",
  },
  ...commonProps,
});

const TOP_20_TABLE_CONSTANT = (selected, isPollingExcel = true) => ({
  componentType: "table",
  heading: "Top 20 Node Instance Usage by Total Cost",
  headers: TOP_20_INSTANCE_HEADERS(selected),
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportPollingTop20CostUsageTableData,
  isPollingExcel,
});

const TOP_20_TABLE_CONSTANT_ELASTICACHE = {
  componentType: "table",
  headers: TOP_20_INSTANCE_HEADERS_ELASTICACHE,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const TOP_20_TABLE_CONSTANT_RDS = {
  componentType: "table",
  headers: TOP_20_INSTANCE_HEADERS_RDS,
  className: "",
  serviceCall: getTop20InstanceTableData,
  exportServiceCall: exportTop20CostUsageTableData,
};

const COST_BREAKDOWN = {
  name: "Top20InstanceTable",
  componentType: "multiLevel-table",
  heading: "Top 20 Resources",
  headers: DATA_TRANSFER_TOP_20_HEADERS,
  serviceCall: getDataTransferCostBreakdown,
  exportServiceCall: exportByResources,
};

const COST_BY_OPERATION = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Operation",
  name: "costByOperation",
  serviceCall: getCostByOperation,
  exportServiceCall: exportCostByOperation,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Operation",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_OPERATING_SYSTEM = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Operating System",
  name: "costByOperatingSystem",
  serviceCall: getCostByOperatingSystem,
  exportServiceCall: exportCostByOperatingSystem,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Operating System",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_PROCESSOR = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Processor",
  name: "costByProcessor",
  serviceCall: getCostByProcessor,
  exportServiceCall: exportTotalCostByProcessor,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Processor",
    align: "Left",
  },
  ...commonProps,
});

const COST_BY_PROCESSOR_ARCHITECTURE = (activeChartType) => ({
  componentType: "graph",
  type: activeChartType || "stackedcolumn2d",
  heading: "Cost by Processor Architecture",
  name: "costByProcessorArchitecture",
  serviceCall: getCostByProcessorArchitecture,
  exportServiceCall: exportTotalCostByProcessorArchitecture,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Processor Architecture",
    align: "Left",
  },
  ...commonProps,
});

const EKS_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[35%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: SummaryCards,
    className: "w-[48%]",
    serviceCall: getSummaryCardsData,
    cardImageData: SUMMARY_CARDS_IMAGES,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(100%-83%-8px)]",
    serviceCall: getRecommendationSummary,
  },
  costByClusters: {
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_CLUSTER(activeChartType),
  },
  costByNamespace: {
    componentType: "graph",
    type: activeChartType || "stackedcolumn2d",
    heading: "Cost by Namespace",
    name: "costByNamespace",
    serviceCall: getCostByNameSpace,
    exportServiceCall: exportCostByNamespace,
    isGranularityApplied: true,
    firstHeader: {
      key: "rowName",
      value: "Namespace",
      align: "Left",
    },
    wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...commonProps,
  },
  costByRegions: {
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_REGION(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "w-[calc(51%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPurchaseType: {
    wrapperClass: "w-[49%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_PURCHASE_TYPE(activeChartType),
  },
  Top20InstanceTable: {
    wrapperClass: "w-[49.8%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...TOP_20_TABLE_CONSTANT("EKS"),
  },
});

const EC2_CONSTANT = (activeChartType) => ({
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_REGION(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[calc(60%-4px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  costByOperatingSystem: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[59%]",
    ...COST_BY_OPERATING_SYSTEM(activeChartType),
  },
  costByProcessor: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(41%-4px)]",
    ...COST_BY_PROCESSOR(activeChartType),
  },
  Top20InstanceTable: { ...TOP_20_TABLE_CONSTANT_RDS, headers: TOP_20_INSTANCE_HEADERS_EC2 },
});

const LAMBDA_HEADER_CONSTANT = {
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[70%] rds",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[calc(30%-5px)] rds",
    serviceCall: getDataTransferSummaryCards,
  },
  cudosCostType: {
    component: Tabs,
    tabLabel: "Cost Type",
    serviceCall: getTabData,
    wrapperClass: "w-full !mt-[6px] !mb-[10px] !ml-[15px]",
    payloadKey: "cudosCostType",
  },
};
const LAMBDA_CONSTANT = (activeChartType) => ({
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  costByProcessorArchitecture: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_PROCESSOR_ARCHITECTURE(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-4px)]",
    ...COST_BY_REGION(activeChartType),
  },
  Top20InstanceTable: {
    ...COST_BREAKDOWN,
    serviceCall: getTop20InstanceTableData,
    exportServiceCall: exportTop20CostUsageTableData,
    hideGrandTotalCards: true,
    showAllTotalCost: true,
    headers: TOP_20_INSTANCE_HEADERS_LAMBDA,
  },
});

const FARGATE_HEADER_CONSTANT = {
  infoBox: {
    componentType: "custom",
    component: InfoBox,
    customClass: "w-full !justify-start !bg-transparent !pl-[0] cudos-info-box",
    message: "This cost includes both ECS and EKS costs for Fargate",
    serviceCall: () => {},
  },
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[70%] rds fargate",
    serviceCall: getCudosDatabaseCards,
  },
  CPUAndMemoryCards: {
    componentType: "custom",
    component: SummaryCards,
    className: "w-[calc(30%-5px)] fargate-cpu-memeory-card",
    serviceCall: getCPUAndMemoryCardData,
    cardImageData: SUMMARY_CARDS_IMAGES_FARGATE,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[60%] rds fargate",
    serviceCall: getDataTransferSummaryCards,
  },
  cudosCostType: {
    component: Tabs,
    tabLabel: "Cost Type",
    serviceCall: getTabData,
    wrapperClass: "w-full !mt-[6px] !mb-[10px] !ml-[15px]",
    payloadKey: "cudosCostType",
  },
};

const FARGATE_CONSTANT = (activeChartType) => ({
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_REGION(activeChartType),
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  costByProcessorArchitecture: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-4px)]",
    ...COST_BY_PROCESSOR_ARCHITECTURE(activeChartType),
  },
  costByCUPAndMemory: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[59%]",
    ...COST_BY_CPU_AND_MEMORY(activeChartType),
  },
  Top20InstanceTable: {
    ...COST_BREAKDOWN,
    serviceCall: getTop20InstanceTableData,
    exportServiceCall: exportPollingTop20CostUsageTableData,
    hideGrandTotalCards: true,
    showAllTotalCost: true,
    headers: TOP_20_INSTANCE_HEADERS_FARGATE,
    isPollingExcel: true,
  },
});

const EC2Header = {
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[45%]",
    serviceCall: getCudosDatabaseCards,
  },
  onDemandSplitCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[calc(35%-5px)] data-transfer",
    serviceCall: getOnDemandSplitCard,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[82%] data-transfer",
    serviceCall: getDataTransferSummaryCards,
  },
  UnusedRIandSPCard: {
    componentType: "custom",
    component: UnusedCard,
    className: "w-[calc(18%-5px)]",
    serviceCall: getUnusedRiSpCost,
  },
  cudosCostType: {
    component: Tabs,
    tabLabel: "Cost Type",
    serviceCall: getTabData,
    wrapperClass: "w-full !mt-[6px] !mb-[10px] !ml-[15px]",
    payloadKey: "cudosCostType",
  },
};

const ELASTICACHE_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[45%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: ElasticSummaryCards,
    className: "w-[35%] elasticache",
    serviceCall: getSummaryCardsData,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(54%-4px)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[46%]",
    ...COST_BY_REGION(activeChartType),
  },
  costByEngines: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(40%-4px)]",
    ...COST_BY_ENGINES(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[60%]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style grow !w-[calc(55%-4px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  Top20InstanceTable: TOP_20_TABLE_CONSTANT_ELASTICACHE,
});

const RDS_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "rds w-[63%] rds",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: ElasticSummaryCards,
    className: "w-[calc(37%-5px)] rds",
    serviceCall: getSummaryCardsData,
  },
  highlightCards: {
    componentType: "custom",
    component: HighLightCards,
    className: "w-[80%]",
    serviceCall: getHighlightCardData,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    type: activeChartType || "stackedcolumn2d",
  },
  costByEngines: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_ENGINES(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_REGION(activeChartType),
  },
  costByUsage: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-4px)]",
    ...COST_BY_USAGE(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[calc(55%-4px)]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  costByStorageType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[59%]",
    ...COST_BY_STORAGE_TYPE(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[calc(41%-4px)]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  Top20InstanceTable: TOP_20_TABLE_CONSTANT_RDS,
});

const COST_BY_SERVICE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost by Service",
  name: "costByService",
  serviceCall: getCostByService,
  exportServiceCall: exportCostByService,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Engine",
    align: "Left",
  },
  ...commonProps,
});
const COST_OF_TYPE_BY_SERVICE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost of Type by Service",
  name: "costOfTypeByService",
  tabArr: [],
  serviceCall: getCostOfTypeByService,
  exportServiceCall: exportCostOfTypeByService,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
});
const COST_OF_SERVICE_BY_TYPE = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Cost of Service by Type",
  name: "costOfServiceByType",
  serviceCall: getCostOfServiceByType,
  exportServiceCall: exportCostOfServiceByType,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  ...commonProps,
});

const INTER_AZ = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Inter AZ",
  name: "interAz",
  options: [],
  serviceCall: getCostByInterAZ,
  exportServiceCall: exportCostByInterAZ,
  wrapperClass: "w-[calc(30%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  xAxisName: "Region",
  showLabels: "1",
  ...commonProps,
});
const REGION_TO_REGION = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Region to Region",
  name: "regionToRegion",
  options: [],
  selectedOption: {},
  serviceCall: getRegionToRegion,
  exportServiceCall: exportCostByRegionToRegion,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  xAxisName: "From Region",
  showLabels: "1",
  wrapperClass: "w-[calc(40%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
  ...commonProps,
});
const INTER_OUT = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Internet Out",
  name: "internetOut",
  options: [],
  serviceCall: getCostByInternetOut,
  exportServiceCall: exportCostByInternetOut,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Regions",
    align: "Left",
  },
  xAxisName: "Region",
  showLabels: "1",
  wrapperClass: "w-[calc(30%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
  ...commonProps,
});

const TOP_BUCKET_BY_OPERATION_COST = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Top Bucket by Operation Cost",
  name: "topBucketByOperationCost",
  tabArr: [],
  serviceCall: getTopBucketByOperationCost,
  exportServiceCall: exportTopBucketByOperationCost,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Bucket",
    align: "Left",
  },
  ...commonProps,
});

const TOP_BUCKET_BY_STORAGE_COST = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Top Bucket by Storage Cost",
  name: "topBucketByStorageCost",
  tabArr: [],
  serviceCall: getTopBucketByStorageCost,
  exportServiceCall: exportTopBucketByStorageCost,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Bucket",
    align: "Left",
  },
  ...commonProps,
});

const TOP_BUCKET_BY_COST = () => ({
  componentType: "graph",
  type: "stackedcolumn2d",
  heading: "Top Bucket by Cost",
  name: "topBucketByCost",
  tabArr: [],
  serviceCall: getTopBucketByCost,
  exportServiceCall: exportTopBucketByCost,
  isGranularityApplied: true,
  firstHeader: {
    key: "rowName",
    value: "Bucket",
    align: "Left",
  },
  ...commonProps,
});

const DATA_TRANSFER = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[38%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[42%] data-transfer",
    serviceCall: getDataTransferSummaryCards,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(20%-20px)] data-transfer",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Account Name",
    },
  },
  costByUsage: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_USAGE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "DataTransfer Type",
    },
    heading: "Cost by Type",
  },
  costByService: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%] ",
    ...COST_BY_SERVICE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Service",
    },
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-5px)]",
    ...COST_BY_REGION(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Region",
    },
  },
  costOfTypeByService: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_OF_TYPE_BY_SERVICE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "DataTransfer Type",
    },
  },
  costOfServiceByType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_OF_SERVICE_BY_TYPE(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Service",
    },
  },
  interAz: {
    ...INTER_AZ(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Region",
    },
  },
  regionToRegion: {
    ...REGION_TO_REGION(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "From Region",
    },
  },
  internetOut: {
    ...INTER_OUT(activeChartType),
    firstHeader: {
      ...COST_BY_ACCOUNTS(activeChartType).firstHeader,
      value: "Region",
    },
  },
  Top20InstanceTable: COST_BREAKDOWN,
});

// S3

const S3 = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[39%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[44%] s3",
    serviceCall: getDataTransferSummaryCards,
  },
  recommendationSummary: {
    componentType: "custom",
    component: RecommendationSummary,
    className: "w-[calc(17%-20px)]",
    serviceCall: getRecommendationSummary,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_REGION(activeChartType),
  },
  costByOperation: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[40%]",
    ...COST_BY_OPERATION(activeChartType),
  },
  costByUsage: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(60%-5px)]",
    ...COST_BY_USAGE(activeChartType),
    heading: "Cost by UsageType",
  },
  topBucketByStorageCost: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[47%]",
    ...TOP_BUCKET_BY_STORAGE_COST(activeChartType),
  },
  topBucketByOperationCost: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%-5px)] ",
    ...TOP_BUCKET_BY_OPERATION_COST(activeChartType),
  },
  topBucketByCost: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[60%]",
    ...TOP_BUCKET_BY_COST(activeChartType),
  },
  Top20InstanceTable: {
    name: "Top20InstanceTable",
    componentType: "custom",
    component: CardTable,
    headers: TOP_20_S3_HEADERS,
    className: "s3-cost-breakup-wrapper",
    serviceCall: getS3TableData,
    exportServiceCall: exportS3CostBreakUp,
  },
});

const OPENSEARCH_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[37%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[calc(32%-5px)] data-transfer",
    serviceCall: getDataTransferSummaryCards,
  },
  highlightCards: {
    componentType: "custom",
    component: HighLightCards,
    className: "w-[calc(31%-5px)]",
    serviceCall: getHighlightCardData,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    type: activeChartType || "stackedcolumn2d",
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_REGION(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    subHeading: "RI Coverage per region | Instance Type/Family",
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(55%-4px)]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[45%]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  Top20InstanceTable: { ...TOP_20_TABLE_CONSTANT_RDS, headers: TOP_20_INSTANCE_HEADERS_OPENSEARCH },
});
const REDSHIFT_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[45%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: DataTransferSummaryCards,
    className: "w-[calc(20%-5px)] data-transfer",
    serviceCall: getDataTransferSummaryCards,
  },
  highlightCards: {
    componentType: "custom",
    component: HighLightCards,
    className: "w-[calc(35%-5px)]",
    serviceCall: getHighlightCardData,
  },
  costByAccounts: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(53%)]",
    ...COST_BY_ACCOUNTS(activeChartType),
    type: activeChartType || "stackedcolumn2d",
  },
  costByRegions: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(47%-5px)]",
    ...COST_BY_REGION(activeChartType),
  },
  RICoverageCost: {
    componentType: "custom",
    component: TotalRiCoverageCost,
    subHeading: "RI Coverage per region | Instance Type/Family",
    heading: "RI Coverage in Cost",
    name: "RICoverageCost",
    serviceCall: getRICoverageData,
  },
  costByInstanceType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style !w-[calc(55%-4px)]",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPricingType: {
    wrapperClass: "!mt-[10px] !mb-[0px] graph-wrapper-style  !w-[45%]",
    ...COST_BY_PRICING_TYPE(activeChartType),
  },
  Top20InstanceTable: { ...TOP_20_TABLE_CONSTANT_RDS, headers: TOP_20_INSTANCE_HEADERS_REDSHIFT },
});

export const DYNAMO_DB = {
  dynamoDBTable: {
    componentType: "custom",
    component: DynamoDB,
    className: "",
    name: getDatabaseData()?.selected || "",
    serviceCall: getDynamoDBTableData,
    exportServiceCall: getDynamoDBDataExport,
    showExcelButton: true,
  },
};

const ECS_CONSTANT = (activeChartType) => ({
  monthCards: {
    componentType: "custom",
    component: MonthCards,
    className: "w-[40%]",
    serviceCall: getCudosDatabaseCards,
  },
  summaryCards: {
    componentType: "custom",
    component: SummaryCards,
    className: "w-[calc(60%-5px)]",
    serviceCall: getSummaryCardsData,
    cardImageData: SUMMARY_CARDS_IMAGES_ECS,
  },
  costByClusters: {
    wrapperClass: "!w-[calc(53%)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_CLUSTER(activeChartType),
    firstHeader: {
      key: "rowName",
      value: "Cluster",
      align: "Left",
    },
  },
  costByService: {
    wrapperClass: "w-[calc(47%-5px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_SERVICE(activeChartType),
    firstHeader: {
      key: "rowName",
      value: "Service",
      align: "Left",
    },
  },
  costByRegions: {
    wrapperClass: "w-[40%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_REGION(activeChartType),
  },
  costByInstanceType: {
    wrapperClass: "w-[calc(60%-4px)] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_INSTANCE_TYPE(activeChartType),
  },
  costByPurchaseType: {
    wrapperClass: "w-[59%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...COST_BY_PURCHASE_TYPE(activeChartType),
  },
  Top20InstanceTable: {
    wrapperClass: "w-[49.8%] !mt-[10px] !mb-[0px] graph-wrapper-style",
    ...TOP_20_TABLE_CONSTANT("ECS"),
  },
});

export const SELECTED_DASHBOARD_ENUM = (activeChartType = "") => ({
  EKS: {
    name: "EKS",
    route: Routes.COST_BREAKUP_EKS,
    config: EKS_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  ECS: {
    name: "ECS",
    route: Routes.COST_BREAKUP_EKS,
    config: ECS_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  ElastiCache: {
    name: "ELASTICACHE",
    route: Routes.COST_BREAKUP_DATABASE,
    config: ELASTICACHE_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  RDS: {
    name: "RDS",
    route: Routes.COST_BREAKUP_DATABASE,
    config: RDS_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  OpenSearch: {
    name: "OpenSearch",
    route: Routes.COST_BREAKUP_DATABASE,
    config: OPENSEARCH_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  Redshift: {
    name: "Redshift",
    route: Routes.COST_BREAKUP_DATABASE,
    config: REDSHIFT_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  DynamoDB: {
    name: "DynamoDB",
    route: Routes.COST_BREAKUP_DATABASE,
    config: DYNAMO_DB,
    showGranularitySidebar: false,
  },
  dataTransferNew: {
    name: "DATA_TRANSFER",
    showGranularitySidebar: true,
    route: Routes.COST_BREAKUP_DATA_TRANSFER_NEW,
    config: DATA_TRANSFER(activeChartType),
  },
  s3: {
    name: "S3",
    route: Routes.ADMINCUDOSS3,
    config: S3(activeChartType),
    showGranularitySidebar: true,
  },
  EC2: {
    name: "EC2",
    route: Routes.CUDOS_COMPUTE,
    header: EC2Header,
    config: EC2_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  Lambda: {
    name: "Lambda",
    route: Routes.LAMBDA,
    header: LAMBDA_HEADER_CONSTANT,
    config: LAMBDA_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
  Fargate: {
    name: "Fargate",
    route: Routes.FARGATE,
    header: FARGATE_HEADER_CONSTANT,
    config: FARGATE_CONSTANT(activeChartType),
    showGranularitySidebar: true,
  },
});
