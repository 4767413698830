import React from "react";
import { ModuleNameAzureInsights, ModuleNameGCPInsights } from "../../../../Constants/textConstant";
import { getCurrencySymbol, getSessionStorageUserData } from "../../../../Utils/commonUtils";
import Routes from "../../../../Config/Routes";

export function Footer(info, key) {
  const { data, rows } = info;
  const total = React.useMemo(() => data.reduce((sum, row) => row[key] + sum, 0), [rows]);
  return (
    <>
      {getCurrencySymbol()}
      {Number(total).toFixed(2)}
    </>
  );
}
export const ROWS_NUMBER = 250;
export const DOLLAR_CHECKER = [
  "cost",
  "total",
  "message_cost",
  "inter_az_cost",
  "internet_out_cost",
  "region_to_region_cost",
  "s3_events_cost",
  "events_cb_cost",
  "events_bytes_cost",
  "v1_request_cost",
  "v1_webacl_cost",
  "v1_rule_cost",
  "v2_request_cost",
  "v2_webacl_cost",
  "v2_rule_cost",
  "amrbotcontrol_cost",
  "mail_attachement_cost",
  "dedicated_ip_cost",
  "mail_recipients_cost",
  "recieved_chunk_cost",
  "others_cost",
  "proconcurrency_cost",
  "compute_cost",
  "request_cost",
  "gb_second_cost",
  "procompute_cost",
  "others_cost",
  "requests_cost",
  "data_transfer_region_to_region_cost",
  "others_cost",
  "standard_storage",
  "standard_api",
  "internet_data_transfer_in",
  "internet_data_transfer_out",
  "glacier_storage",
  "glacier_staging_storage",
  "intelligent_tier_storage",
  "intelligent_tier_ia_storage",
  "reduced_redundancy_storage",
  "standard_ia",
  "timed_storage_gda_bytehrs_storage",
  "storage_zone_ia",
  "cloudfront_data_transfer",
  "regional_data_transfer_in",
  "regional_data_transfer_out",
  "glacier_api",
  "intelligent_tier_api_requests",
  "standard_ia_api",
  "must_others",
  "storageonezone_standard_cost",
  "storageonezone_infrequent_cost",
  "storage_standard_cost",
  "storage_infrequent_cost",
  "infrequentwrite_cost",
  "throughput_provisioned_cost",
  "others_cost",
  "running_hrs_cost",
  "storage_gp2_cost",
  "storage_cost",
  "fargate_vcpuhrs_cost",
  "fargate_gbhrs_cost",
  "capacity_write_cost",
  "capacity_read_cost",
  "request_write_cost",
  "request_read_cost",
  "storage_standard_cost",
  "storage_backup_cost",
  "storage_pitr_cost",
  "api_request_cost",
  "api_message_cost",
  "api_connection_minute_cost",
  "api_http_request_cost",
  "datatransfer_out",
  "requests_tier1",
  "requests_tier2",
  "requests_Proxy",
  "Lambda_Edge_Requests",
  "Lambda_Edge_GB_Second",
  "Invalidations",
  "SSL_Certificate",
  "Data_Transfer_Out_RTMP",
  "enhanced_fan_out_hr_cost",
  "extended_shared_hr_cost",
  "request_payload_units_cost",
  "read_bytes_cost",
  "storage_shared_hr_cost",
  "logs_data_processed_cost",
  "logs_s3_egress_cost",
  "logs_timed_storage_cost",
  "logs_vended_logs_cost",
  "alarms_cost",
  "custom_metrics_cost",
  "dashboards_cost",
  "synthetics_cost",
  "api_get_metric_data_cost",
  "api_requests_cost",
  "uh_cost",
  "others_cost",
  "channel_input_cost",
  "channel_output_cost",
  "inactive_channel_cost",
  "inactive_input_cost",
  "invalidations",
  "requests_proxy",
  "lambda_edge_gb_second",
  "lambda_edge_requests",
  "hot_read_operations",
  "hot_lrs_data_stored",
  "lrs_list_and_create_container_operations",
  "lrs_data_stored",
  "hot_lrs_write_operations",
  "read_operations",
  "gb_hrs_cost",
  "vcpu_hrs_cost",
  "routing_policy",
  "routing_rules",
  "included_routing_rules",
];

// Storage
export const STORAGE_S3 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Bucket Name",
    id: "bucket_name",
    accessor: "bucket_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Standard API",
    id: "standard_api",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.standard_api?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "standard_api"),
    align: "Right",
  },
  {
    Header: "Standard Storage",
    id: "standard_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.standard_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "standard_storage"),
    align: "Right",
  },
  {
    Header: "Reduced Redundancy Storage",
    id: "reduced_redundancy_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.reduced_redundancy_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "reduced_redundancy_storage"),
    align: "Right",
  },
  {
    Header: "Internet Data Transfer In",
    id: "internet_data_transfer_in",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.internet_data_transfer_in?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "internet_data_transfer_in"),
    align: "Right",
  },
  {
    Header: "Internet Data Transfer Out",
    id: "internet_data_transfer_out",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.internet_data_transfer_out?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "internet_data_transfer_out"),
    align: "Right",
  },
  {
    Header: "Regional Data Transfer Out",
    id: "regional_data_transfer_out",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.regional_data_transfer_out?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "regional_data_transfer_out"),
    align: "Right",
  },
  {
    Header: "Others",
    id: "must_others",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.must_others?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "must_others"),
    align: "Right",
  },
  {
    Header: "Standard IA",
    id: "standard_ia",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.standard_ia?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "standard_ia"),
    align: "Right",
  },
  {
    Header: "Glacier Storage",
    id: "glacier_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.glacier_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "glacier_storage"),
    align: "Right",
  },
  {
    Header: "Standard IA API",
    id: "standard_ia_api",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.standard_ia_api?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "standard_ia_api"),
    align: "Right",
  },
  {
    Header: "Glacier Staging Storage",
    id: "glacier_staging_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.glacier_staging_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,

    Footer: (info) => Footer(info, "glacier_staging_storage"),
    align: "Right",
  },
  {
    Header: "Intelligent Tier Storage",
    id: "intelligent_tier_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.intelligent_tier_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "intelligent_tier_storage"),
    align: "Right",
  },
  {
    Header: "Intelligent Tier IA Storage",
    id: "intelligent_tier_ia_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.intelligent_tier_ia_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "intelligent_tier_ia_storage"),
    align: "Right",
  },
  {
    Header: "Timed Storage GDA Byte Hrs Storage",
    id: "timed_storage_gda_bytehrs_storage",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.timed_storage_gda_bytehrs_storage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "timed_storage_gda_bytehrs_storage"),
    align: "Right",
  },
  {
    Header: " Storage Zone IA",
    id: "storage_zone_ia",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.storage_zone_ia?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "storage_zone_ia"),
    align: "Right",
  },
  {
    Header: "CloudFront Data Transfer",
    id: "cloudfront_data_transfer",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cloudfront_data_transfer?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cloudfront_data_transfer"),
    align: "Right",
  },

  {
    Header: "Regional Data Transfer In",
    id: "regional_data_transfer_in",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.regional_data_transfer_in?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "regional_data_transfer_in"),
    align: "Right",
  },

  {
    Header: "Glacier API",
    id: "glacier_api",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.glacier_api?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,

    Footer: (info) => Footer(info, "glacier_api"),
    align: "Right",
  },
  {
    Header: "Intelligent Tier API Requests",
    id: "intelligent_tier_api_requests",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.intelligent_tier_api_requests?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "intelligent_tier_api_requests"),
    align: "Right",
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];

export const STORAGE_EBS_SNAPSHOT = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Name",
    id: "name",
    accessor: "name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    id: "region",
    accessor: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];
export const STORAGE_EBS_VOLUMES = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Name",
    accessor: "name",
    id: "name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Volume Type",
    accessor: "volume_type",
    id: "volume_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Volume ID",
    accessor: "volume_id",
    id: "volume_id",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (GB-Mo)",
    id: "usage_amount",
    accessor: (data) =>
      data.usage_amount?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_amount"),
    align: "Right",
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];

export const STORAGE_EFS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Storage One Zone ",
    id: "storageonezone_standard_usage",
    accessor: (data) =>
      data.storageonezone_standard_usage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    columns: [
      {
        Header: "Standard",
        id: "storageonezone_standard_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            id: "storageonezone_standard_usage",
            accessor: (data) =>
              data.storageonezone_standard_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            sortedIcon: true,
            Footer: (info) => Footer(info, "storageonezone_standard_usage"),
            align: "Right",
          },
          {
            Header: "Cost",
            id: "storageonezone_standard_cost",
            accessor: (data) =>
              data.storageonezone_standard_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            sortedIcon: true,
            Footer: (info) => Footer(info, "storageonezone_standard_cost"),
            align: "Right",
          },
        ],
      },
      {
        Header: " Infrequent ",
        id: "storageonezone_infrequent_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            id: "storageonezone_infrequent_usage",

            accessor: (data) =>
              data.storageonezone_infrequent_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            sortedIcon: true,
            Footer: (info) => Footer(info, "storageonezone_infrequent_usage"),
            align: "Right",
          },
          {
            Header: " Cost",
            id: "storageonezone_infrequent_cost",
            accessor: (data) =>
              data.storageonezone_infrequent_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            sortedIcon: true,
            Footer: (info) => Footer(info, "storageonezone_infrequent_cost"),
            align: "Right",
          },
        ],
      },
    ],
  },
  {
    Header: "Storage",
    accessor: "storage_standard_usage",
    id: "storage_standard_usage",
    columns: [
      {
        Header: "Standard ",
        accessor: "storage_standard_usage",
        id: "storage_standard_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            id: "storage_standard_usage",
            accessor: (data) =>
              data.storage_standard_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_standard_usage"),
            align: "Right",
          },
          {
            Header: "Cost",
            id: "storage_standard_cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.storage_standard_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_standard_cost"),
            align: "Right",
          },
        ],
      },
      {
        Header: "Infrequent",
        id: "storage_infrequent_usage",
        accessor: "storage_infrequent_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            accessor: (data) =>
              data.storage_infrequent_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "storage_infrequent_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_infrequent_usage"),
            align: "Right",
          },

          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.storage_infrequent_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "storage_infrequent_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_infrequent_cost"),
            align: "Right",
          },
        ],
      },
    ],
  },

  {
    Header: "Infrequent-Write ",
    accessor: "infrequentwrite_usage",
    id: "infrequentwrite_usage",
    columns: [
      {
        Header: "Quantity (GB-Mo)",
        accessor: (data) =>
          data.infrequentwrite_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "infrequentwrite_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "infrequentwrite_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.infrequentwrite_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "infrequentwrite_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "infrequentwrite_cost"),
        align: "Right",
      },
    ],
  },
  // {
  //   Header: "Throughput Standard Usage ",
  //   accessor: "throughput_standard_usage",
  //   sortedIcon: true,
  //   Footer: (info) => {
  //     const total = React.useMemo(
  //       () => info.rows.reduce((sum, row) => row.values.cost + sum, 0),
  //       [info.rows]
  //     );

  //     return <> ${total.toFixed(2)}</>;
  //   },
  // },
  // {
  //   Header: "Thoughput Standard Cost",
  //   accessor: "throughput_standard_cost",
  //   sortedIcon: true,
  //   Footer: (info) => {
  //     const total = React.useMemo(
  //       () => info.rows.reduce((sum, row) => row.values.cost + sum, 0),
  //       [info.rows]
  //     );

  //     return <> ${total.toFixed(2)}</>;
  //   },
  // },

  // {
  //   Header: "Throughput Provisioned Usage",
  //   accessor: "throughput_provisioned_usage",
  //   sortedIcon: true,
  //   Footer: (info) => {
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.values.throughput_provisioned_usage + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <> ${total.toFixed(2)}</>;
  //   },
  // },
  // {
  //   Header: "Throughput Provisional Cost",
  //   accessor: "throughput_provisioned_cost",
  //   sortedIcon: true,
  //   Footer: (info) => {
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.values.throughput_provisioned_cost + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <> ${total.toFixed(2)}</>;
  //   },
  // },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Usage",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Instance Cost
export const INSTANCE_COST_EC2 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Instance ID",
    accessor: "instance_id",
    id: "instance_id",
    sortedIcon: true,
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Operating System",
    accessor: "operating_system",
    id: "operating_system",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (Hrs)",
    id: "usage_hours",
    accessor: (data) =>
      `${data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_hours"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    headerClass: "move-right",
  },
];

export const INSTANCE_COST_RDS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Instance Name",
    accessor: "instance_name",
    id: "instance_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "DB Engine",
    accessor: "db_engine",
    id: "db_engine",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Availability",
    accessor: "multi_az",
    id: "multi_az",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (Hrs)",
    accessor: (data) =>
      data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    id: "usage_hours",
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_hours"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "cost",
    sortedIcon: true,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "cost")}`,
    headerClass: "move-right",
  },
];

export const INSTANCE_COST_ELASTICACHE = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Instance Name",
    accessor: "instance_name",
    id: "instance_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Cache Engine",
    accessor: "cache_engine",
    id: "cache_engine",
    sortedIcon: true,
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (Hrs)",
    accessor: (data) =>
      data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    id: "usage_hours",
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_hours"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,

    sortedIcon: true,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "cost")}`,
    headerClass: "move-right",
  },
];

export const IINSTANCE_COST_RDS_HEADER_ELACTICACHE = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Instance Name",
    accessor: "instance_name",
    id: "instance_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (Hrs)",
    accessor: (data) =>
      data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    id: "usage_hours",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "cost",
    sortedIcon: true,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "cost")}`,
    headerClass: "move-right",
  },
];

export const INSTANCE_COST_REDSHIFT = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Cluster Name",
    accessor: "cluster_name",
    id: "cluster_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity (Hrs)",
    accessor: (data) =>
      data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    id: "usage_hours",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "cost",
    sortedIcon: true,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "cost")}`,
    headerClass: "move-right",
  },
];

// Serverless
export const SERVERLESS_SES = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    Footer: "",
    SecondaryFooter: "",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Message ",
    accessor: "message_usage",
    id: "message_usage",
    columns: [
      {
        Header: "Quantity (Count)",
        accessor: (data) =>
          data.message_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "message_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "message_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.message_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "message_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "message_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Mail Attachment ",
    accessor: "mail_attachement_usage",
    id: "mail_attachement_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.mail_attachement_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "mail_attachement_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "mail_attachement_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.mail_attachement_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "mail_attachement_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "mail_attachement_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Dedicated IP",
    accessor: "dedicated_ip_usage",
    id: "dedicated_ip_usage",
    columns: [
      {
        Header: "Quantity (Mo)",
        accessor: (data) =>
          data.dedicated_ip_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "dedicated_ip_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "dedicated_ip_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.dedicated_ip_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "dedicated_ip_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "dedicated_ip_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Mail Recipients",
    accessor: "mail_recipients_usage",
    id: "mail_recipients_usage",
    columns: [
      {
        Header: "Quantity (Count)",
        accessor: (data) =>
          data.mail_recipients_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "mail_recipients_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "mail_recipients_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.mail_recipients_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "mail_recipients_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "mail_recipients_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Recieved Chunk",
    accessor: "recieved_chunk_usage",
    id: "recieved_chunk_usage",
    columns: [
      {
        Header: "Quantity (ReceivedChunk)",
        accessor: (data) =>
          data.recieved_chunk_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "recieved_chunk_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "recieved_chunk_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.recieved_chunk_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "recieved_chunk_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "recieved_chunk_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "others_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const SERVERLESS_LAMBDA = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    Footer: "",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Provisioned Concurrency",
    accessor: "proconcurrency_usage",
    id: "proconcurrency_usage",
    columns: [
      {
        Header: "Quantity (Lambda-GB-Second)",
        accessor: (data) =>
          data.proconcurrency_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "proconcurrency_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "proconcurrency_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.proconcurrency_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "proconcurrency_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "proconcurrency_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Compute",
    accessor: "compute_usage",
    id: "compute_usage",
    columns: [
      {
        Header: "Quantity (Lambda-GB-Second)",
        accessor: (data) =>
          data.compute_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "compute_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "compute_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.compute_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "compute_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "compute_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Request",
    accessor: "request_usage",
    id: "request_usage",
    columns: [
      {
        Header: "Quantity (Requests)",
        accessor: (data) =>
          data.request_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "request_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "request_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.request_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "request_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "request_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "GB Second",
    accessor: "gb_second_usage",
    id: "gb_second_usage",
    columns: [
      {
        Header: "Quantity (Lambda-GB-Second)",
        accessor: (data) =>
          data.gb_second_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "gb_second_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "gb_second_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.gb_second_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "gb_second_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "gb_second_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Provisioned Compute",
    accessor: "procompute_usage",
    id: "procompute_usage",
    columns: [
      {
        Header: "Quantity (Lambda-GB-Second)",
        accessor: (data) =>
          data.procompute_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "procompute_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "procompute_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.procompute_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "procompute_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "procompute_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "others_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const SERVERLESS_SQS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    Footer: "",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Requests",
    accessor: "requests_usage",
    id: "requests_usage",
    columns: [
      {
        Header: "Quantity (Requests)",
        accessor: (data) =>
          data.requests_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "requests_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "requests_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.requests_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "requests_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "requests_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Data Transfer Region to Region",
    accessor: "data_transfer_region_to_region_usage",
    id: "data_transfer_region_to_region_usage",
    columns: [
      {
        Header: "Qty",
        accessor: (data) =>
          data.data_transfer_region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "data_transfer_region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "data_transfer_region_to_region_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.data_transfer_region_to_region_cost?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`,
        id: "data_transfer_region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "data_transfer_region_to_region_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "others_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
    align: "Right",
  },
];

// Security

export const SECURITY_GUARD_DUTY = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "S3 Data Events Analyzed",
    id: "s3_events_usage",
    accessor: "s3_events_usage",
    columns: [
      {
        Header: "Quantity (Events)",
        accessor: (data) =>
          data.s3_events_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "s3_events_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "s3_events_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.s3_events_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "s3_events_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "s3_events_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Events Analyzed",
    id: "events_cb_usage",
    accessor: "events_cb_usage",
    columns: [
      {
        Header: "Quantity (Events)",
        accessor: (data) =>
          data.events_cb_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "events_cb_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "events_cb_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.events_cb_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "events_cb_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "events_cb_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Events Analyzed bytes",
    id: "events_bytes_usage",
    accessor: "events_bytes_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.events_bytes_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "events_bytes_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "events_bytes_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.events_bytes_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "events_bytes_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "events_bytes_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Usage",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const SECURITY_WAF = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "V1",
    accessor: "v1_request_usage",
    id: "v1_request_usage",
    columns: [
      {
        Header: "Request",
        accessor: "v1_request_usage",
        id: "v1_request_usage",
        columns: [
          {
            Header: "Quantity (Request)",
            accessor: (data) =>
              data.v1_request_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v1_request_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_request_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v1_request_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v1_request_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_request_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Web ACL",
        accessor: "v1_webacl_usage",
        id: "v1_webacl_usage",
        columns: [
          {
            Header: "Quantity (Month)",
            accessor: (data) =>
              data.v1_webacl_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v1_webacl_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_webacl_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v1_webacl_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v1_webacl_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_webacl_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Rule",
        accessor: "v1_rule_usage",
        id: "v1_rule_usage",
        columns: [
          {
            Header: "Quantity (Month)",
            accessor: (data) =>
              data.v1_rule_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v1_rule_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_rule_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v1_rule_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v1_rule_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v1_rule_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "V2",
    accessor: "v2_request_usage",
    id: "v2_request_usage",
    columns: [
      {
        Header: "Request",
        accessor: "v2_request_usage",
        id: "v2_request_usage",
        columns: [
          {
            Header: "Quantity (Request)",
            accessor: (data) =>
              data.v2_request_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v2_request_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_request_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v2_request_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v2_request_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_request_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Web ACL",
        accessor: "v2_webacl_usage",
        id: "v2_webacl_usage",
        columns: [
          {
            Header: "Quantity (Month)",
            accessor: (data) =>
              data.v2_webacl_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v2_webacl_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_webacl_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v2_webacl_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v2_webacl_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_webacl_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Rule",
        accessor: "v2_rule_usage",
        id: "v2_rule_usage",
        columns: [
          {
            Header: "Quantity (Month)",
            accessor: (data) =>
              data.v2_rule_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "v2_rule_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_rule_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.v2_rule_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "v2_rule_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "v2_rule_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Amrbot Control",
    accessor: "amrbotcontrol_usage",
    id: "amrbotcontrol_usage",
    columns: [
      {
        Header: "Quantity (Month)",
        accessor: (data) =>
          data.amrbotcontrol_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "amrbotcontrol_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "amrbotcontrol_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "amrbotcontrol_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.amrbotcontrol_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "amrbotcontrol_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Usage",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Migration & Transfer

export const MIGRATION_DMS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Running Hrs",
    accessor: "running_hrs_usage",
    id: "running_hrs_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.running_hrs_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "running_hrs_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "running_hrs_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "running_hrs_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.running_hrs_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "running_hrs_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   align: "Right",
      //   Footer: (info) => Footer(info, "others_usage"),
      // },
      {
        Header: "Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        align: "Right",
        Footer: (info) => Footer(info, "others_cost"),
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Storage",
    accessor: "storage_gp2_usage",
    id: "storage_gp2_usage",
    columns: [
      {
        Header: "Quantity (GB-Mo)",
        accessor: (data) =>
          data.storage_gp2_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "storage_gp2_usage",
        sortedIcon: true,
        align: "Right",
        Footer: (info) => Footer(info, "storage_gp2_usage"),
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "storage_gp2_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.storage_gp2_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        align: "Right",
        Footer: (info) => Footer(info, "storage_gp2_cost"),
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Containers

export const CONTAINERS_ECR = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Storage",
    accessor: "storage_usage",
    id: "storage_usage",
    columns: [
      {
        Header: "Quantity (GB-Mo)",
        accessor: (data) =>
          data.storage_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "storage_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "storage_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "storage_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.storage_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "storage_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const CONTAINERS_EKS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Running Hrs",
    accessor: "running_hrs_usage",
    id: "running_hrs_usage",
    columns: [
      {
        Header: "Quantity (Hours)",
        accessor: (data) =>
          data.running_hrs_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "running_hrs_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "running_hrs_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "running_hrs_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.running_hrs_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "running_hrs_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const CONTAINERS_ECS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Fargate",
    accessor: "fargate_vcpuhrs_usage",
    id: "fargate_vcpuhrs_usage",
    columns: [
      {
        Header: "vCPU-Hrs",
        accessor: "fargate_vcpuhrs_usage",
        id: "fargate_vcpuhrs_usage",
        columns: [
          {
            Header: "Quantity (Hours)",
            accessor: (data) =>
              data.fargate_vcpuhrs_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "fargate_vcpuhrs_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "fargate_vcpuhrs_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.fargate_vcpuhrs_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "fargate_vcpuhrs_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "fargate_vcpuhrs_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "GB-Hrs",
        accessor: "fargate_gbhrs_usage",
        id: "fargate_gbhrs_usage",
        columns: [
          {
            Header: "Quantity (Hours)",
            accessor: (data) =>
              data.fargate_gbhrs_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "fargate_gbhrs_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "fargate_gbhrs_usage"),
            align: "Right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.fargate_gbhrs_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "fargate_gbhrs_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "fargate_gbhrs_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Database

export const DATABASE_DYNAMO_DB = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: " Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  { Header: "Region", accessor: "region", id: "region", sortedIcon: true, align: "Left" },
  {
    Header: "Capacity Units",
    accessor: "capacity_write_usage",
    id: "capacity_write_usage",
    columns: [
      {
        Header: "Write",
        accessor: "capacity_write_usage",
        id: "capacity_write_usage",
        columns: [
          {
            Header: "Quantity (ReplicatedWriteCapacityUnit-Hrs)",
            accessor: (data = "") =>
              data.capacity_write_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "capacity_write_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "capacity_write_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${data.capacity_write_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "capacity_write_cost",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "capacity_write_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Read",
        accessor: "capacity_read_usage",
        id: "capacity_read_usage",
        columns: [
          {
            Header: "Quantity (ReadCapacityUnit-Hrs)",
            accessor: (data = "") =>
              data.capacity_read_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "capacity_read_usage",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "capacity_read_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${data.capacity_read_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "capacity_read_cost",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "capacity_read_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Request Units",
    accessor: "request_write_usage",
    id: "request_write_usage",
    columns: [
      {
        Header: "Write",
        accessor: "request_write_usage",
        id: "request_write_usage",
        columns: [
          {
            Header: "Quantity (ReplicatedWriteRequestUnits)",
            accessor: (data = "") =>
              data.request_write_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "request_write_usage",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "request_write_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${data.request_write_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "request_write_cost",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "request_write_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Read",
        accessor: "request_read_usage",
        id: "request_read_usage",
        columns: [
          {
            Header: "Quantity (ReadRequestUnits)",
            accessor: (data = "") =>
              data.request_read_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "request_read_usage",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "request_read_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${data.request_read_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "request_read_cost",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "request_read_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Storage",
    accessor: "storage_standard_usage",
    id: "storage_standard_usage",
    columns: [
      {
        Header: "Standard",
        accessor: "storage_standard_usage",
        id: "storage_standard_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            accessor: (data = "") =>
              data.storage_standard_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "storage_standard_usage",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "storage_standard_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${data.storage_standard_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "storage_standard_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_standard_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Backup",
        accessor: "storage_backup_usage",
        id: "storage_backup_usage",
        columns: [
          {
            Header: "Quantity (GB-Month)",
            accessor: (data = "") =>
              // !!data.storage_backup_usage &&
              data.storage_backup_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "storage_backup_usage",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "storage_backup_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data = "") =>
              `${getCurrencySymbol()}${
                // !!data.storage_backup_cost &&
                data.storage_backup_cost?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }`,
            id: "storage_backup_cost",
            sortedIcon: true,
            Footer: (info = "") => Footer(info, "storage_backup_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Pitr",
        accessor: "storage_pitr_usage",
        id: "storage_pitr_usage",
        columns: [
          {
            Header: "Quantity (GB-Mo)",
            accessor: (data) =>
              // !!data.storage_pitr_usage &&
              data.storage_pitr_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "storage_pitr_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_pitr_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${
                // !!data.storage_pitr_cost
                data.storage_pitr_cost?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }`,
            id: "storage_pitr_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "storage_pitr_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Others",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      {
        Header: "Cost",
        id: "others_cost",
        accessor: (data = "") =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info = "") => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },

  {
    Header: "Total Cost",
    id: "total",
    accessor: (data = "") =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info = "") => Footer(info, "total"),
  },
];

// Networking & Content Delivery

export const NETWORKING_API_GATEWAY = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Requests ",
    accessor: "api_request_usage",
    id: "api_request_usage",
    columns: [
      {
        Header: "Quantity (Requests)",
        accessor: (data) =>
          data.api_request_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "api_request_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_request_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.api_request_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "api_request_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_request_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Message",
    accessor: "api_message_usage",
    id: "api_message_usage",
    columns: [
      {
        Header: "Quantity (Messages)",
        accessor: (data) =>
          data.api_message_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "api_message_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_message_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.api_message_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "api_message_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_message_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Connection Minute",
    accessor: "api_connection_minute_usage",
    id: "api_connection_minute_usage",
    columns: [
      {
        Header: "Quantity (Minutes)",
        accessor: (data) =>
          data.api_connection_minute_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "api_connection_minute_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_connection_minute_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.api_connection_minute_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "api_connection_minute_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_connection_minute_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Http Request",
    accessor: "api_http_request_usage",
    id: "api_http_request_usage",
    columns: [
      {
        Header: "Quantity (Requests)",
        accessor: (data) =>
          data.api_http_request_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "api_http_request_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_http_request_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.api_http_request_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "api_http_request_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "api_http_request_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Usage",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: "Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },

  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const NETWORKING_CLOUD_FRONT = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Distribution ID",
    accessor: "distribution_id",
    id: "distribution_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Data Transfer Out",
    id: "datatransfer_out",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.datatransfer_out?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "datatransfer_out"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Requests Tier-1",
    id: "requests_tier1",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.requests_tier1?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "requests_tier1"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Requests Tier-2",
    id: "requests_tier2",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.requests_tier2?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "requests_tier2"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "SSL Certificate",
    accessor: "ssl_certificate",
    id: "ssl_certificate",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Requests Proxy",
    id: "requests_proxy",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.requests_proxy?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "requests_proxy"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Lambda Edge Requests",
    id: "lambda_edge_requests",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.lambda_edge_requests?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "lambda_edge_requests"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Lambda Edge GB Second",
    id: "lambda_edge_gb_second",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.lambda_edge_gb_second?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "lambda_edge_gb_second"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Invalidations",
    id: "invalidations",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.invalidations?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "invalidations"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "SSL Certificate",
    id: "SSL_Certificate",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.SSL_Certificate?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "SSL_Certificate"),
    align: "Right",
    headerClass: "move-right",
  },
  {
    Header: "Data Transfer Out RTMP",
    id: "Data_Transfer_Out_RTMP",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.Data_Transfer_Out_RTMP?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "Data_Transfer_Out_RTMP"),
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Analytics
export const ANALYTICS_KINESIS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Enhanced Fan Out Hr ",
    accessor: "enhanced_fan_out_hr_usage",
    id: "enhanced_fan_out_hr_usage",
    columns: [
      {
        Header: "Quantity (ConsumerShardHour)",
        accessor: (data) =>
          data.enhanced_fan_out_hr_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "enhanced_fan_out_hr_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "enhanced_fan_out_hr_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.enhanced_fan_out_hr_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "enhanced_fan_out_hr_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "enhanced_fan_out_hr_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Extended Share Hr",
    accessor: "extended_shared_hr_usage",
    id: "extended_shared_hr_usage",
    columns: [
      {
        Header: "Quantity (ShardHour)",
        accessor: (data) =>
          data.extended_shared_hr_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "extended_shared_hr_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "extended_shared_hr_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.extended_shared_hr_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "extended_shared_hr_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "extended_shared_hr_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "PUT Payload Unit",
    accessor: "request_payload_units_usage",
    id: "request_payload_units_usage",
    columns: [
      {
        Header: "Quantity (PutRequest)",
        accessor: (data) =>
          data.request_payload_units_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "request_payload_units_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "request_payload_units_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.request_payload_units_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "request_payload_units_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "request_payload_units_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Read Bytes",
    accessor: "read_bytes_usage",
    id: "read_bytes_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.read_bytes_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "read_bytes_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "read_bytes_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.read_bytes_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "read_bytes_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "read_bytes_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Shared Storage",
    accessor: "storage_shared_hr_usage",
    id: "storage_shared_hr_usage",
    columns: [
      {
        Header: "Quantity (ShardHour)",
        accessor: (data) =>
          data.storage_shared_hr_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "storage_shared_hr_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "storage_shared_hr_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.storage_shared_hr_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "storage_shared_hr_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "storage_shared_hr_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Management & Governance

export const MANAGEMENT_CLOUD_WATCH_LOGS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Data Processed",
    accessor: "logs_data_processed_usage",
    id: "logs_data_processed_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.logs_data_processed_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "logs_data_processed_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_data_processed_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "logs_data_processed_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.logs_data_processed_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_data_processed_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "S3 Egress",
    accessor: "logs_s3_egress_usage",
    id: "logs_s3_egress_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.logs_s3_egress_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "logs_s3_egress_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_s3_egress_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "logs_s3_egress_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.logs_s3_egress_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_s3_egress_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Storage",
    accessor: "logs_timed_storage_usage",
    id: "logs_timed_storage_usage",
    columns: [
      {
        Header: "Quantity (GB-Mo)",
        accessor: (data) =>
          data.logs_timed_storage_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "logs_timed_storage_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_timed_storage_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "logs_timed_storage_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.logs_timed_storage_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_timed_storage_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Vended",
    accessor: "logs_vended_logs_usage",
    id: "logs_vended_logs_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.logs_vended_logs_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "logs_vended_logs_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_vended_logs_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "logs_vended_logs_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.logs_vended_logs_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "logs_vended_logs_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const MANAGEMENT_CLOUD_WATCH_METRICS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Alarms",
    accessor: "alarms_usage",
    id: "alarms_usage",
    columns: [
      {
        Header: "Quantity (Alarms)",
        accessor: (data) =>
          data.alarms_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "alarms_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "alarms_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "alarms_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.alarms_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "alarms_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Custom Metrics ",
    accessor: "custom_metrics_usage",
    id: "custom_metrics_usage",
    columns: [
      {
        Header: "Quantity (Metrics)",
        accessor: (data) =>
          data.custom_metrics_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "custom_metrics_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "custom_metrics_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "custom_metrics_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.custom_metrics_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "custom_metrics_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Dashboard",
    accessor: "dashboards_usage",
    id: "dashboards_usage",
    columns: [
      {
        Header: "Quantity (Dashboards)",
        accessor: (data) =>
          data.dashboards_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "dashboards_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "dashboards_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "dashboards_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.dashboards_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "dashboards_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Synthetics",
    accessor: "synthetics_usage",
    id: "synthetics_usage",
    columns: [
      {
        Header: "Quantity (Runs)",
        accessor: (data) =>
          data.synthetics_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "synthetics_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "synthetics_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: " Cost",
        id: "synthetics_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.synthetics_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "synthetics_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Metric API Calls",
    accessor: "api_get_metric_data_usage",
    id: "api_get_metric_data_usage",
    columns: [
      {
        Header: "GetMetricData",
        accessor: "api_get_metric_data_usage",
        id: "api_get_metric_data_usage",
        columns: [
          {
            Header: "Quantity (Metrics)",
            accessor: (data) =>
              data.api_get_metric_data_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "api_get_metric_data_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "api_get_metric_data_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.api_get_metric_data_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "api_get_metric_data_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "api_get_metric_data_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
      {
        Header: "Requests",
        accessor: "api_requests_usage",
        id: "api_requests_usage",
        columns: [
          {
            Header: "Quantity (Requests)",
            accessor: (data) =>
              data.api_requests_usage?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            id: "api_requests_usage",
            sortedIcon: true,
            Footer: (info) => Footer(info, "api_requests_usage"),
            align: "Right",
            headerClass: "move-right",
          },
          {
            Header: "Cost",
            accessor: (data) =>
              `${getCurrencySymbol()}${data.api_requests_cost?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`,
            id: "api_requests_cost",
            sortedIcon: true,
            Footer: (info) => Footer(info, "api_requests_cost"),
            align: "Right",
            headerClass: "move-right",
          },
        ],
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Usage",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Media Services
export const MEDIA_CONNECT = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource Id",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Usage Hours",
    accessor: "uh_usage",
    id: "uh_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.uh_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "uh_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "uh_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.uh_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "uh_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "uh_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: "Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const MEDIA_TAILOR = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource Id",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Usage Hours",
    accessor: "uh_usage",
    id: "uh_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.uh_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "uh_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "uh_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.uh_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "uh_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "uh_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const MEDIA_LIVE = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource Id",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Channel Input",
    accessor: "channel_input_usage",
    id: "channel_input_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.channel_input_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "channel_input_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "channel_input_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.channel_input_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "channel_input_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "channel_input_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Channel Output",
    accessor: "channel_output_usage",
    id: "channel_output_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.channel_output_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "channel_output_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "channel_output_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.channel_output_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "channel_output_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "channel_output_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Inactive Channel",
    accessor: "inactive_channel_usage",
    id: "inactive_channel_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.inactive_channel_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inactive_channel_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inactive_channel_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inactive_channel_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inactive_channel_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inactive_channel_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Inactive Input",
    accessor: "inactive_input_usage",
    id: "inactive_input_usage",
    columns: [
      {
        Header: "Quantity (Hrs)",
        accessor: (data) =>
          data.inactive_input_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inactive_input_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inactive_input_usage"),
        align: "Right",
        headerClass: "move-right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inactive_input_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inactive_input_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inactive_input_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      // {
      //   Header: "Qty",
      //   accessor: (data) =>
      //     data.others_usage?.toLocaleString(undefined, {
      //       minimumFractionDigits: 2,
      //       maximumFractionDigits: 2,
      //     }),
      //   id: "others_usage",
      //   sortedIcon: true,
      //   Footer: (info) => Footer(info, "others_usage"),
      //   align: "Right",
      // },
      {
        Header: " Cost",
        id: "others_cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
        headerClass: "move-right",
      },
    ],
  },
  {
    Header: "Total Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Data Transfer
export const DATA_EC2 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Instance ID",
    accessor: "instance_id",
    id: "instance_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Inter-AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => `${getCurrencySymbol()}${Footer(info, "inter_az_cost")}`,
        align: "Right",
      },
    ],
  },

  {
    Header: "Internet Out ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Region To Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => `${getCurrencySymbol()}${Footer(info, "total")}`,
    align: "Right",
  },
];
export const DATA_ELB = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Load Balancer",
    accessor: "load_balancer",
    id: "load_balancer",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },

  {
    Header: "Inter-AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Internet Out ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Region To Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_VPC = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "VPC",
    accessor: "vpc",
    id: "vpc",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Inter-AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Internet Out ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Region To Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_S3 = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Bucket Name",
    accessor: "bucket_name",
    id: "bucket_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Inter-AZ",
    id: "inter_az_usage",
    accessor: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Internet Out ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Region To Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Others ",
    accessor: "others_usage",
    id: "others_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.others_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "others_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.others_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "others_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "others_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_RDS = [
  {
    Header: "Account ID",
    accessor: "linked_account_id",
    id: "linked_account_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Instance Name",
    accessor: "rds_instance_name",
    id: "rds_instance_name",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },

  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },

  {
    Header: "Inter-AZ ",
    accessor: "inter_az_usage",
    id: "inter_az_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.inter_az_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "inter_az_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.inter_az_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "inter_az_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "inter_az_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Internet Out ",
    accessor: "internet_out_usage",
    id: "internet_out_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.internet_out_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "internet_out_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.internet_out_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "internet_out_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "internet_out_cost"),
        align: "Right",
      },
    ],
  },
  {
    Header: "Region To Region ",
    accessor: "region_to_region_usage",
    id: "region_to_region_usage",
    columns: [
      {
        Header: "Quantity (GB)",
        accessor: (data) =>
          data.region_to_region_usage?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        id: "region_to_region_usage",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_usage"),
        align: "Right",
      },
      {
        Header: "Cost",
        accessor: (data) =>
          `${getCurrencySymbol()}${data.region_to_region_cost?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        id: "region_to_region_cost",
        sortedIcon: true,
        Footer: (info) => Footer(info, "region_to_region_cost"),
        align: "Right",
      },
    ],
  },

  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "total",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

export const DATA_SUMMARY = [
  {
    Header: "Service",
    accessor: "productName",
    id: "productName",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
  },
  {
    Header: "Total Cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "cost",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: (info) => Footer(info, "total"),
  },
];
// Azure Table Headers

// Azure Storage
export const STORAGE_SSD = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Category",
    accessor: "category",
    id: "category",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: " Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];
export const STORAGE_TABLES = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: " Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];
export const STORAGE_BLOB = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Storage Type",
    id: "storage_type",
    accessor: "storage_type",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Hot Read Operations",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.hot_read_operations?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "hot_read_operations",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "hot_read_operations"),
  },
  {
    Header: "Hot LRS Data Stored",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.hot_lrs_data_stored?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "hot_lrs_data_stored",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "hot_lrs_data_stored"),
  },
  {
    Header: "LRS List and Create Container Operations",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.lrs_list_and_create_container_operations?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`,
    id: "lrs_list_and_create_container_operations",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "lrs_list_and_create_container_operations"),
  },
  {
    Header: "LRS Data Stored",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.lrs_data_stored?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "lrs_data_stored",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "lrs_data_stored"),
  },
  {
    Header: "Hot LRS Write Operations",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.hot_lrs_write_operations?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "hot_lrs_write_operations",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "hot_lrs_write_operations"),
  },
  {
    Header: "Read Operations",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.read_operations?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    id: "read_operations",
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "read_operations"),
  },
  {
    Header: "Others",
    id: "must_others",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.must_others?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "must_others"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];
export const STORAGE_FILES = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: " Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];
export const STORAGE_DATABASE = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Subscription Name",
  //   id: "subscription_name",
  //   accessor: "subscription_name",
  //   sortedIcon: true,
  //   Footer: "",
  //   SecondaryFooter: "",
  // },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Operating System",
    accessor: "operating_system",
    id: "operating_system",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Qty",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

// Azure ServerLess

export const SERVERLESS_FUNCTIONS = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
  },
  {
    Header: "Compute Qty",
    id: "compute_quantity",
    accessor: (data) =>
      `${data.compute_quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "compute_quantity"),
  },
  {
    Header: "Compute Cost",
    id: "compute_cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.compute_cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "compute_cost"),
  },
  {
    Header: "Requests Qty",
    id: "requests_quantity",
    accessor: (data) =>
      `${data.requests_quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "requests_quantity"),
  },
  {
    Header: "Requests Cost",
    id: "requests_cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.requests_cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "requests_cost"),
  },
];
export const SERVERLESS_SERVICEBUS = [
  {
    Header: "Subscription ID",
    accessor: "subscriptionid",
    id: "subscriptionid",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];

// GCP ServerLess

export const GCP_SERVERLESS_CLOUD_FUNCTIONS = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Resource ID",
  //   accessor: "resource_id",
  //   id: "resource_id",
  //   align: "Left",
  //   sortedIcon: true,
  // },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_SERVERLESS_CLOUD_PUB_SUB = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_SERVERLESS_APP_ENGINE = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Resource ID",
  //   accessor: "resource_id",
  //   id: "resource_id",
  //   align: "Left",
  //   sortedIcon: true,
  // },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

// Azure Instance Cost

export const INSTANCE_COST_VIRTUALMACHINE = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Subscription Name",
  //   id: "subscription_name",
  //   accessor: "subscription_name",
  //   sortedIcon: true,
  //   Footer: "",
  //   SecondaryFooter: "",
  // },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Operating System",
    accessor: "operating_system",
    id: "operating_system",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Hours",
    id: "usage_hours",
    accessor: (data) =>
      `${data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_hours"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const INSTANCE_COST_AZUREDATABASE = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Subscription Name",
  //   id: "subscription_name",
  //   accessor: "subscription_name",
  //   sortedIcon: true,
  //   Footer: "",
  //   SecondaryFooter: "",
  // },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Engine",
    accessor: "engine",
    id: "engine",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Hours",
    id: "usage_hours",
    accessor: (data) =>
      `${data.usage_hours?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_hours"),
    align: "Right",
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];

export const INSTANCE_COST_CACHE = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Cache Engine",
    accessor: "cache_engine",
    id: "cache_engine",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Instance Type",
    accessor: "instance_type",
    id: "instance_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];
export const INSTANCE_COST_APPSERVICES = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Operating System",
    accessor: "operating_system",
    id: "operating_system",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "ASP Tier",
    accessor: "meter_name",
    id: "meter_name",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Model",
    accessor: "pricing_model",
    id: "pricing_model",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
  {
    Header: "Total",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: false,
    align: "Right",
    Footer: (info) => Footer(info, "total"),
  },
];
export const GCP_STORAGE_COMPUTE_ENGINE = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_STORAGE_CLOUD_SQL = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_STORAGE_BIG_QUERY = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_INSTANCE_COST_COMPUTE_ENGINE = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_INSTANCE_COST_CLOUD_SQL = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_qty"),
    align: "Right",
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];

export const GCP_INSTANCE_COST_BIG_QUERY = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    accessor: "resource_id",
    id: "resource_id",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_qty"),
    align: "Right",
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];

export const GCP_INSTANCE_COST_CLOUD_BIGTABLE = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "usage_qty"),
    align: "Right",
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
    align: "Right",
  },
];

// Azure Networking and Content Delivery
export const NETWORKING_FRONT_DOOR = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: " Cost",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "total"),
  },
];

export const GCP_NETWORKING_CLOUD_CDN = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_NETWORKING_CLOUD_ARMOR = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

// Azure Security
export const SECURITY_AZURE_WAF = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Included Routing Rules Qty",
    id: "included_routing_rules_qty",
    accessor: (data) =>
      `${data.included_routing_rules_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "included_routing_rules_qty"),
  },
  {
    Header: "Included Routing Rules",
    id: "included_routing_rules",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.included_routing_rules?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "included_routing_rules"),
  },
  {
    Header: "Routing Policy Qty",
    id: "routing_policy_qty",
    accessor: (data) =>
      `${data.routing_policy_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "routing_policy_qty"),
  },
  {
    Header: "Routing Policy",
    id: "routing_policy",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.routing_policy?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "routing_policy"),
  },
  {
    Header: "Routing Rules Qty",
    id: "routing_rules_qty",
    accessor: (data) =>
      `${data.routing_rules_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "routing_rules_qty"),
  },
  {
    Header: "Routing Rules",
    id: "routing_rules",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.routing_rules?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "routing_rules"),
  },
  {
    Header: "Total",
    id: "total",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.total?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: false,
    Footer: (info) => Footer(info, "total"),
  },
];

// Azure Containers

export const CONTAINERS_KUBERNETES = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    SecondaryFooter: "",
    align: "Left",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];
export const CONTAINERS_AZURE_ECR = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    SecondaryFooter: "Total",
    align: "Left",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];
export const CONTAINERS_AZURE_CONTAINERS = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "VCPU-Hrs Qty",
    id: "vcpu_hrs_qty",
    accessor: (data) =>
      `${data.vcpu_hrs_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "vcpu_hrs_qty"),
  },
  {
    Header: "VCPU-Hrs Cost",
    id: "vcpu_hrs_cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.vcpu_hrs_cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "vcpu_hrs_cost"),
  },
  {
    Header: "GB-Hrs Qty",
    id: "gb_hrs_qty",
    accessor: (data) =>
      `${data.gb_hrs_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "gb_hrs_qty"),
  },
  {
    Header: "GB-Hrs Cost",
    id: "gb_hrs_cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.gb_hrs_cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "gb_hrs_cost"),
  },
];
// GCP Containers

export const GCP_CONTAINERS_ARTIFACT_REGISTRY = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_CONTAINERS_CLOUD_RUN = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Resource ID",
  //   accessor: "resource_id",
  //   id: "resource_id",
  //   align: "Left",
  //   sortedIcon: true,
  // },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

export const GCP_CONTAINERS_KUBERNETES_ENGINE = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  // {
  //   Header: "Resource ID",
  //   accessor: "resource_id",
  //   id: "resource_id",
  //   align: "Left",
  //   sortedIcon: true,
  // },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];

// Azure Database

export const DATABASE_COSMOSDB = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Qty",
    id: "qty",
    accessor: (data) =>
      `${data.qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "qty"),
  },
  {
    Header: "Total Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: false,
    Footer: (info) => Footer(info, "cost"),
  },
];

export const SQL_MANAGED_INSTANCE = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Model",
    accessor: "pricing_model",
    id: "pricing_model",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Storage Quantity (GB/Month)",
    accessor: "storage_quantity",
    id: "storage_quantity",
    align: "Right",
    sortedIcon: true,
  },
  {
    Header: "Storage Cost",
    accessor: "storage_cost",
    id: "storage_cost",
    align: "Right",
    sortedIcon: true,
  },
  {
    Header: "Compute Quantity (Hour)",
    accessor: "compute_quantity",
    id: "compute_quantity",
    align: "Right",
    sortedIcon: true,
  },
  {
    Header: "Compute Cost",
    accessor: "compute_cost",
    id: "compute_cost",
    align: "Right",
    sortedIcon: true,
  },
  {
    Header: "License Quantity (Hour)",
    accessor: "license_quantity",
    id: "license_quantity",
    align: "Right",
    sortedIcon: true,
  },
  {
    Header: "License Cost",
    accessor: "license_cost",
    id: "license_cost ",
    align: "Right",
    sortedIcon: false,
  },
];

// Azure Logging and Monitoring

export const LOGGING_MONITORING = [
  {
    Header: "Subscription ID",
    accessor: "subscription_id",
    id: "subscription_id",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Resource ID",
    id: "resource_id",
    accessor: "resource_id",
    sortedIcon: true,
    Footer: "",
    align: "Left",
    SecondaryFooter: "",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Quantity",
    id: "quantity",
    accessor: (data) =>
      `${data.quantity?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    align: "Right",
    sortedIcon: true,
    Footer: (info) => Footer(info, "quantity"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    Footer: (info) => Footer(info, "cost"),
  },
];

// GCP Logging and Monitoring

export const GCP_CLOUD_LOGGING = [
  {
    Header: "Project Name",
    accessor: "project_name",
    id: "project_name",
    sortedIcon: true,
    Footer: "Others",
    align: "Left",
    SecondaryFooter: "Total",
  },
  {
    Header: "Region",
    accessor: "region",
    id: "region",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Resource Group",
    accessor: "resource_group",
    id: "resource_group",
    align: "Left",
    sortedIcon: true,
  },
  {
    Header: "Pricing Type",
    accessor: "pricing_type",
    id: "pricing_type",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Pricing Unit",
    accessor: "usage_pricing_unit",
    id: "usage_pricing_unit",
    sortedIcon: true,
    align: "Left",
  },
  {
    Header: "Usage Qty",
    id: "usage_qty",
    accessor: (data) =>
      `${data?.usage_qty?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "usage_qty"),
  },
  {
    Header: "Cost",
    id: "cost",
    accessor: (data) =>
      `${getCurrencySymbol()}${data?.cost?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    sortedIcon: true,
    align: "Right",
    Footer: (info) => Footer(info, "cost"),
  },
];
// Static Data for the cost breakup sub dashboards

export const getStorageData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["S3", "EBS Snapshot", "EBS Volumes", "EFS"],
      breadcrumsData: ["Cost Breakup", "Storage"],
      selected: "S3",
      enum: {
        S3: "S3",
        "EBS Snapshot": "EBS_SNAPSHOT",
        "EBS Volumes": "EBS_VOLUMES",
        EFS: "EFS",
      },
      headerConstant: {
        S3: STORAGE_S3,
        EBS_SNAPSHOT: STORAGE_EBS_SNAPSHOT,
        EBS_VOLUMES: STORAGE_EBS_VOLUMES,
        EFS: STORAGE_EFS,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["SSD", "Tables", "Blob", "Files", "Database"],
      breadcrumsData: ["Cost Breakup", "Storage"],
      selected: "SSD",
      enum: {
        SSD: "SSD",
        Tables: "TABLES",
        Blob: "BLOB",
        Files: "FILES",
        Database: "DATABASE",
      },
      headerConstant: {
        SSD: STORAGE_SSD,
        TABLES: STORAGE_TABLES,
        BLOB: STORAGE_BLOB,
        FILES: STORAGE_FILES,
        DATABASE: STORAGE_DATABASE,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Compute Engine", "Cloud SQL", "Big Query"],
      breadcrumsData: ["Cost Breakup", "Storage"],
      selected: "Compute Engine",
      enum: {
        "Compute Engine": "COMPUTE_ENGINE",
        "Cloud SQL": "CLOUD_SQL",
        "Big Query": "BIG_QUERY",
      },
      headerConstant: {
        COMPUTE_ENGINE: GCP_STORAGE_COMPUTE_ENGINE,
        CLOUD_SQL: GCP_STORAGE_CLOUD_SQL,
        BIG_QUERY: GCP_STORAGE_BIG_QUERY,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getServerLessData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["SQS", "Lambda", "SES"],
      breadcrumsData: ["Cost Breakup", "Serverless"],
      selected: "SQS",
      enum: {
        SQS: "SQS",
        Lambda: "LAMBDA",
        SES: "SES",
      },
      headerConstant: {
        SQS: SERVERLESS_SQS,
        LAMBDA: SERVERLESS_LAMBDA,
        SES: SERVERLESS_SES,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["Functions", "Service Bus"],
      breadcrumsData: ["Cost Breakup", "Serverless"],
      selected: "Functions",
      enum: {
        Functions: "FUNCTIONS",
        "Service Bus": "SERVICE_BUS",
      },
      headerConstant: {
        FUNCTIONS: SERVERLESS_FUNCTIONS,
        SERVICE_BUS: SERVERLESS_SERVICEBUS,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Cloud Functions", "Cloud Pub/Sub", "App Engine"],
      breadcrumsData: ["Cost Breakup", "Serverless"],
      selected: "Cloud Functions",
      enum: {
        "Cloud Functions": "CLOUD_FUNCTIONS",
        "Cloud Pub/Sub": "CLOUD_PUB_SUB",
        "App Engine": "APP_ENGINE",
      },
      headerConstant: {
        CLOUD_FUNCTIONS: GCP_SERVERLESS_CLOUD_FUNCTIONS,
        CLOUD_PUB_SUB: GCP_SERVERLESS_CLOUD_PUB_SUB,
        APP_ENGINE: GCP_SERVERLESS_APP_ENGINE,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getInstanceCostData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["EC2", "RDS", "ElastiCache", "OpenSearch", "Redshift"],
      breadcrumsData: ["Cost Breakup", "Instance Cost"],
      selected: "EC2",
      enum: {
        EC2: "EC2",
        RDS: "RDS",
        ElastiCache: "ELASTICACHE",
        OpenSearch: "ELASTICSEARCH",
        Redshift: "REDSHIFT",
      },
      headerConstant: {
        EC2: INSTANCE_COST_EC2,
        RDS: INSTANCE_COST_RDS,
        ELASTICACHE: INSTANCE_COST_ELASTICACHE,
        ELASTICSEARCH: IINSTANCE_COST_RDS_HEADER_ELACTICACHE,
        REDSHIFT: INSTANCE_COST_REDSHIFT,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["Virtual Machine", "Database", "Cache", "App Service"],
      breadcrumsData: ["Cost Breakup", "Instance Cost"],
      selected: "Virtual Machine",
      enum: {
        "Virtual Machine": "VIRTUAL_MACHINE",
        Database: "AZURE_DATABASE",
        Cache: "CACHE",
        "App Service": "APP_SERVICES",
      },
      headerConstant: {
        VIRTUAL_MACHINE: INSTANCE_COST_VIRTUALMACHINE,
        AZURE_DATABASE: INSTANCE_COST_AZUREDATABASE,
        CACHE: INSTANCE_COST_CACHE,
        APP_SERVICES: INSTANCE_COST_APPSERVICES,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Compute Engine", "Cloud SQL", "Big Query", "Cloud Bigtable"],
      breadcrumsData: ["Cost Breakup", "Instance Cost"],
      selected: "Compute Engine",
      enum: {
        "Compute Engine": "COMPUTE_ENGINE",
        "Cloud SQL": "CLOUD_SQL",
        "Big Query": "BIG_QUERY",
        "Cloud Bigtable": "CLOUD_BIGTABLE",
      },
      headerConstant: {
        COMPUTE_ENGINE: GCP_INSTANCE_COST_COMPUTE_ENGINE,
        CLOUD_SQL: GCP_INSTANCE_COST_CLOUD_SQL,
        BIG_QUERY: GCP_INSTANCE_COST_BIG_QUERY,
        CLOUD_BIGTABLE: GCP_INSTANCE_COST_CLOUD_BIGTABLE,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getNetworkContentData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["API Gateway", "CloudFront"],
      breadcrumsData: ["Cost Breakup", "Networking & Content Delivery"],
      selected: "API Gateway",
      enum: {
        "API Gateway": "APIGATEWAY",
        CloudFront: "CLOUDFRONT",
      },
      headerConstant: {
        APIGATEWAY: NETWORKING_API_GATEWAY,
        CLOUDFRONT: NETWORKING_CLOUD_FRONT,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["Front Door"],
      breadcrumsData: ["Cost Breakup", "Networking & Content Delivery"],
      selected: "Front Door",
      enum: { "Front Door": "FRONTDOOR" },
      headerConstant: {
        FRONTDOOR: NETWORKING_FRONT_DOOR,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Cloud CDN", "Cloud Armor"],
      breadcrumsData: ["Cost Breakup", "Networking & Content Delivery"],
      selected: "Cloud CDN",
      enum: {
        "Cloud CDN": "CLOUD_CDN",
        "Cloud Armor": "CLOUD_ARMOR",
      },
      headerConstant: {
        CLOUD_CDN: GCP_NETWORKING_CLOUD_CDN,
        CLOUD_ARMOR: GCP_NETWORKING_CLOUD_ARMOR,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getSecurityData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["GuardDuty", "WAF"],
      breadcrumsData: ["Cost Breakup", "Security"],
      selected: "GuardDuty",
      enum: {
        GuardDuty: "GUARDDUTY",
        WAF: "WAF",
      },
      headerConstant: {
        GUARDDUTY: SECURITY_GUARD_DUTY,
        WAF: SECURITY_WAF,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["WAF"],
      breadcrumsData: ["Cost Breakup", "Security"],
      selected: "WAF",
      enum: {
        WAF: "AZURE_WAF",
      },
      headerConstant: {
        AZURE_WAF: SECURITY_AZURE_WAF,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getLoggingAndMonitoringData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    [ModuleNameAzureInsights]: {
      tabs: ["Log Analytics"],
      breadcrumsData: ["Cost Breakup", "Logging & Monitoring"],
      selected: "Log Analytics",
      enum: {
        "Log Analytics": "LOG_ANALYTICS",
      },
      headerConstant: {
        LOG_ANALYTICS: LOGGING_MONITORING,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Cloud Logging"],
      breadcrumsData: ["Cost Breakup", "Logging & Monitoring"],
      selected: "Cloud Logging",
      enum: {
        "Cloud Logging": "CLOUD_LOGGING",
      },
      headerConstant: {
        CLOUD_LOGGING: GCP_CLOUD_LOGGING,
      },
    },
  };
  return moduleData?.[moduleName] || moduleData?.[ModuleNameAzureInsights];
};

export const getContainersData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      tabs: ["ECR", "EKS", "ECS"],
      breadcrumsData: ["Cost Breakup", "Containers"],
      selected: "ECR",
      enum: {
        ECR: "ECR",
        EKS: "EKS",
        ECS: "ECS",
      },
      headerConstant: {
        ECR: CONTAINERS_ECR,
        EKS: CONTAINERS_EKS,
        ECS: CONTAINERS_ECS,
      },
    },

    [ModuleNameAzureInsights]: {
      tabs: ["Kubernetes", "Container Registry", "Container"],
      breadcrumsData: ["Cost Breakup", "Containers"],
      selected: "Kubernetes",
      enum: {
        Kubernetes: "AZURE_KUBERNETES",
        Container: "AZURE_ECS",
        "Container Registry": "AZURE_ECR",
      },
      headerConstant: {
        AZURE_KUBERNETES: CONTAINERS_KUBERNETES,
        AZURE_ECS: CONTAINERS_AZURE_CONTAINERS,
        AZURE_ECR: CONTAINERS_AZURE_ECR,
      },
    },
    [ModuleNameGCPInsights]: {
      tabs: ["Artifact Registry", "Cloud Run", "Kubernetes Engine"],
      breadcrumsData: ["Cost Breakup", "Containers"],
      selected: "Artifact Registry",
      enum: {
        "Artifact Registry": "ARTIFACT_REGISTRY",
        "Cloud Run": "CLOUD_RUN",
        "Kubernetes Engine": "KUBERNETES_ENGINE",
      },
      headerConstant: {
        ARTIFACT_REGISTRY: GCP_CONTAINERS_ARTIFACT_REGISTRY,
        CLOUD_RUN: GCP_CONTAINERS_CLOUD_RUN,
        KUBERNETES_ENGINE: GCP_CONTAINERS_KUBERNETES_ENGINE,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};

export const getDatabaseData = () => {
  const { tokens: { moduleName } = {} } = getSessionStorageUserData() || {};
  const moduleData = {
    lens: {
      headerName: "Database",
      tabs: ["DynamoDB"],
      breadcrumsData: ["Cost Breakup", "Database"],
      selected: "DynamoDB",
      url: Routes.COST_BREAKUP_DATABASE_OLD,
      enum: {
        DynamoDB: "DYNAMODB",
      },
      headerConstant: {
        DYNAMODB: DATABASE_DYNAMO_DB,
      },
    },

    [ModuleNameAzureInsights]: {
      headerName: "Managed Database",
      tabs: ["CosmosDB", "SQL Managed Instance"],
      breadcrumsData: ["Cost Breakup", "Managed Database"],
      selected: "CosmosDB",
      url: Routes.COST_BREAKUP_MANAGED_DATABASE,
      enum: {
        CosmosDB: "COSMOSDB",
        "SQL Managed Instance": "SQL_MANAGED_INSTANCE",
      },
      headerConstant: {
        COSMOSDB: DATABASE_COSMOSDB,
        SQL_MANAGED_INSTANCE,
      },
    },
  };
  return moduleData[moduleName] || moduleData.lens;
};
