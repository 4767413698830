import React from "react";
import getFilteredAccessType from "./getFilteredAccessType";

const getAccessType = (accessType) => {
  const filteredList = getFilteredAccessType();
  return filteredList.map((item) => (
    <span
      key={item.key}
      className={`access_type ck-pill ${accessType?.[item.key] ? "ck-pill-green" : ""}`}
    >
      {item.value}
    </span>
  ));
};

export default getAccessType;
