import React from "react";
import { ReactComponent as Verified } from "../../../../../Assets/ckTuner/icons/verified.svg";
import { ReactComponent as UnVerified } from "../../../../../Assets/ckTuner/icons/not-verified-1.1.svg";
import { getCurrentModule } from "../../../../../Utils/tunerUtils";

function StatusTooltip({ moduleSpecificMapping }) {
  return (
    <div className="lmt-2 w-64 rounded-lg bg-white shadow-lg border border-gray-200 p-3 text-sm text-gray-800">
      <div className="mb-4">
        <div className="flex gap-1 items-center">
          <Verified width={12} height={12} />
          <p className="font-medium text-xs text-[#2b2b2b]">Verified</p>
        </div>
        <p className="text-[11px] text-[#2b2b2b]">
          {moduleSpecificMapping[getCurrentModule()]?.statusTooltipText?.verified}
        </p>
      </div>

      <div className="flex gap-1 items-center">
        <UnVerified width={12} height={12} />
        <p className="font-medium text-xs text-[#2b2b2b]">Not Verified</p>
      </div>
      <p className="text-[11px] text-[#2b2b2b]">
        {moduleSpecificMapping[getCurrentModule()]?.statusTooltipText?.notVerified}
      </p>
    </div>
  );
}

export default StatusTooltip;
