import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card";

function Container({
  data,
  onOrderUpdate,
  readOnly,
  name,
  onClick,
  showButton,
  cardClass,
  buttonText,
}) {
  const [cards, setCards] = useState(data);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isDragging) {
      setCards(data);
    }
  }, [data, isDragging]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    onOrderUpdate({
      target: {
        name: name || "constructOrdering",
        value: cards,
      },
    });
  }, [cards, name, onOrderUpdate]);

  const renderCard = useCallback(
    (card, index) => (
      <Card
        key={card.order}
        index={index}
        order={card.order}
        text={card.text}
        moveCard={moveCard}
        readOnly={readOnly}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={onClick}
        data={card}
        showButton={showButton}
        cardClass={cardClass}
        buttonText={buttonText}
      />
    ),
    [moveCard, handleDragStart, handleDragEnd, readOnly]
  );

  return (
    <div className="flex flex-col gap-[2px]">{cards?.map((card, i) => renderCard(card, i))}</div>
  );
}

export default Container;
