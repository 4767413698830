import React from "react";
import ButtonGroupV2 from "../../../Common/ButtonGroupV2";
import "./style.scss";
import ExcelDropDownV2 from "../../../Common/ExcelDropdownV2";

function ReservationHeaderV2(props) {
  const {
    hasData,
    isLoading,
    excelLoading,
    customClass = "",
    renderToggleTabButtonData,
    excelButtonData,
  } = props;

  return (
    <div className={`billing_console_header ${customClass}`} id="dropdownMenu1">
      <ButtonGroupV2
        active={renderToggleTabButtonData.active}
        ActiveTableData={renderToggleTabButtonData.ActiveTableData}
        handleReservations={renderToggleTabButtonData.handleReservations}
        ExpireTableData={renderToggleTabButtonData.ExpireTableData}
        firstButton={renderToggleTabButtonData?.firstButton}
        secondButton={renderToggleTabButtonData?.secondButton}
        buttonGrpCustomClass="!p-[3px] buttonGrpCustomClass"
        isLoading={isLoading}
      />
      <ExcelDropDownV2
        className="!pt-[17px] !pb-[20px]"
        innerRef={excelButtonData.innerRef}
        isLoading={isLoading}
        hasData={hasData}
        excelLoading={excelLoading}
        dropDownToggler={excelButtonData?.dropDownToggler}
        toggleDropdown={excelButtonData?.toggleDropdown}
        DownlaodTabs={excelButtonData?.DownlaodTabs}
        groupDetails={excelButtonData?.groupDetails}
        downloadList={excelButtonData?.downloadList}
        downlaodHandler={excelButtonData?.downlaodHandler}
        icon={false}
        wraperClass="!block !pt-[0px] !pb-[0px] excelWrapperClass"
        btnClass="!font-medium"
        disable={hasData}
      />
    </div>
  );
}

export default ReservationHeaderV2;
