import { RECOMMENDATION, SCHEDULER, SPOTBOT } from "../../../constants/dynamicFeatures";

const accessTypeMapper = [
  {
    key: "recommendation",
    value: "Recommendations",
    tcgKey: RECOMMENDATION,
  },
  {
    key: "scheduler",
    value: "Scheduler",
    tcgKey: SCHEDULER,
  },
  {
    key: "spotbot",
    value: "SpotBot",
    tcgKey: SPOTBOT,
  },
];

export default accessTypeMapper;
