/* eslint-disable max-lines-per-function */
import React from "react";
import Switch from "@mui/material/Switch";
// import { ReactComponent as ExpandIcon } from "../../../../../Assets/ckTuner/icons/table-expand.svg";
import { ReactComponent as Verified } from "../../../../../Assets/ckTuner/icons/verified.svg";
import { ReactComponent as UnVerified } from "../../../../../Assets/ckTuner/icons/not-verified-1.1.svg";
// import { ReactComponent as NonOnboarded } from "../../../../../Assets/ckTuner/icons/non-onboarded.svg";
import { ReactComponent as Gear } from "../../../../../Assets/ckTuner/icons/gear.svg";
import { ReactComponent as InfoIcon } from "../../../../../Assets/images/ic-info.svg";
// import Routes from "../../../../../Config/Routes";
import TooltipV2 from "../../../../Common/TooltipV2";
import { getCurrentModule, isTunerFeatureEnabled } from "../../../../../Utils/tunerUtils";
import { REMEDIATION, SCHEDULER } from "../../../constants/dynamicFeatures";
import getAccessType from "./getAccessType";
import isAllAcceTypeEnabled from "./isAllAcceTypeEnabled";
import getFilteredAccessType from "./getFilteredAccessType";
import StatusTooltip from "./statusTooltip";
import AccountTypeTag from "./accountTypeTag";
import Routes from "../../../../../Config/Routes";

export const moduleSpecificMapping = {
  tuner: {
    name: "Accounts",
    id: "accountId",
    link: "Link Account",
    relink: "Re-Link Account",
    linkUrl: Routes.CKTUNER_ACCOUNTS_ADD,
    canRelink: true,
    notVerifiedReasons: true,
    statusTooltipText: {
      verified: "Tuner has the necessary access to optimize your infra.",
      notVerified: "Access issue detected—review IAM role and policies.",
    },
  },
  "gcp-tuner": {
    name: "Projects",
    id: "projectId",
    link: "Link Project",
    relink: "Re-Link Project",
    linkUrl: Routes.TUNER_GCP_LINK_PROJECT,
    canRelink: false,
    notVerifiedReasons: false,
    statusTooltipText: {
      verified: "Tuner has the necessary access to optimize your infra.",
      notVerified: "",
    },
  },
};

const remidiateButtons = {
  CONFIGURE: "Configure",
  UPGRADE: "Upgrade",
  UPDATED: "Updated",
};

const accountIdTooltip = (item, maxSize = 120) => (
  <div style={{ maxWidth: `calc(100% - ${maxSize}px)` }}>
    <TooltipV2
      popoverId="ck-bottom-tooltip"
      parentClass="tooltip_table_v2 stripped ck-config-tooltip tuner-account-id-name-tooltip"
      tooltipid="reset_default_state"
      showTooltipIcon={false}
      tooltipOnDisplayText
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      displayText={() => (
        <div className="text-ellipsis overflow-hidden">
          <span className="ck-account-name">{item?.accountId} </span>({item?.accountName})
        </div>
      )}
    >
      <div className="text-ellipsis overflow-hidden">
        <span className="ck-account-name">{item?.accountId} </span>({item?.accountName})
      </div>
    </TooltipV2>
  </div>
);

const statusColumnHeaderClass = {
  tuner: "!w-[125px] !min-w-[125px] !max-w-[125px]",
  "gcp-tuner": "!w-[30%] !min-w-[30%] !max-w-[30%]",
};

export const accountsHeaders = ({
  onClickToggleScheduler,
  setOpenSchedulerModal,
  setOpenVerificationModal,
  setOpenAccountTypeModal,
  setRemediateModal,
  isAllowed,
  handleUpgradeAccess,
  loadHandler,
  navigate,
}) => {
  const moduleType = getCurrentModule() || "tuner"; // e.g., "tuner", "gcp-tuner", or future modules
  const statusColumn = {
    Header: "Status",
    accessor: "isVerified",
    headerClass: statusColumnHeaderClass[moduleType],
    tooltipContent: () =>
      moduleSpecificMapping[getCurrentModule()]?.notVerifiedReasons ? (
        <StatusTooltip moduleSpecificMapping={moduleSpecificMapping} />
      ) : (
        false
      ),
    render: (item) => {
      if (item?.isVerified) {
        return (
          <div className="ck-tuner-accounts-verified">
            <Verified />
            Verified
          </div>
        );
      }
      if (item?.disabledInputs) {
        return "-";
      }
      return (
        <div className="ck-tuner-accounts-verified">
          <UnVerified />
          Not Verified{" "}
          {moduleSpecificMapping[getCurrentModule()]?.notVerifiedReasons ? (
            <button
              type="button"
              className="v3-link"
              onClick={() => setOpenVerificationModal(item)}
            >
              <InfoIcon />
            </button>
          ) : null}
        </div>
      );
    },
    sortedIcon: true,
  };

  const actionsColumn = {
    Header: "Actions",
    headerClass: "w-[10%]",
    accessor: "actions",
    icons: true,
  };

  const moduleHeadersConfig = {
    tuner: [
      {
        Header: "Account ID",
        accessor: "accountId",
        headerClass: "!w-[30%] !min-w-[30%] !max-w-[30%]",
        render: (item) => (
          <div className="flex gap-1">
            {accountIdTooltip(item, 126)}
            {!item?.disabledInputs && (
              <AccountTypeTag
                item={item}
                setOpenAccountTypeModal={setOpenAccountTypeModal}
                isAllowed={isAllowed}
              />
            )}
          </div>
        ),
        sortedIcon: true,
      },
      isTunerFeatureEnabled(REMEDIATION) && {
        Header: "Auto Remediation",
        accessor: "isVerified",
        headerClass: "!w-[128px] !min-w-[128px] !max-w-[128px]",
        render: (item) => {
          if (item?.accountRemediationStatus?.remediationStatus === "UPDATED") return "Updated";
          if (
            item?.accountRemediationStatus?.remediationStatus === "CONFIGURE" ||
            item?.accountRemediationStatus?.remediationStatus === "UPGRADE"
          )
            return (
              <button
                type="button"
                className="v3 outlined tuner-account-table-button"
                onClick={() => setRemediateModal(item)}
                disabled={!isAllowed}
              >
                {remidiateButtons[item?.accountRemediationStatus?.remediationStatus]}
              </button>
            );
          return "-";
        },
        sortedIcon: true,
      },
      isTunerFeatureEnabled(SCHEDULER) && {
        Header: "Scheduler",
        accessor: "is_wise_scheduler_enabled",
        headerClass: "!w-[118px] !min-w-[118px] !max-w-[118px]",
        render: (item) =>
          item?.disabledInputs ? (
            "-"
          ) : (
            <div className="ck-tuner-scheduler-cell">
              <Switch
                checked={item?.is_wise_scheduler_enabled}
                onChange={() =>
                  onClickToggleScheduler({ item, setOpenSchedulerModal, loadHandler })
                }
                inputProps={{ "aria-label": "controlled" }}
                classes="test"
                disabled={item?.disabledInputs || !isAllowed}
              />
              <button
                type="button"
                className="v3-link"
                onClick={() => setOpenSchedulerModal(item)}
                disabled={!isAllowed && !item?.access_type}
              >
                <Gear />
              </button>
            </div>
          ),
        sortedIcon: true,
      },
      getFilteredAccessType()?.length && {
        Header: "Access Type",
        accessor: "access_type",
        hideFilters: true,
        render: (item) =>
          item?.disabledInputs ? (
            "-"
          ) : (
            <div className="ck-pill-wrapper">
              {getAccessType(item?.access_type)}
              {isAllAcceTypeEnabled(item?.access_type) && isAllowed && item?.isVerified ? (
                <button
                  type="button"
                  className="v3 outlined ck-tuner-access-type-cell"
                  onClick={() => handleUpgradeAccess({ item, navigate })}
                  disabled={!isAllowed}
                >
                  Upgrade
                </button>
              ) : null}
            </div>
          ),
        sortedIcon: true,
      },
    ].filter(Boolean), // Remove any falsy (null/false) values

    "gcp-tuner": [
      {
        Header: "Project ID",
        accessor: "projectId",
        render: (item) => <div className="flex gap-1">{accountIdTooltip(item)}</div>,
        sortedIcon: true,
      },
    ],

    // Future module example
    "azure-tuner": [
      {
        Header: "AWS Account ID",
        accessor: "awsAccountId",
        sortedIcon: true,
      },
      {
        Header: "AWS Region",
        accessor: "awsRegion",
        sortedIcon: true,
      },
    ],
  };

  const selectedHeaders = moduleHeadersConfig[moduleType] || [];

  return [selectedHeaders[0], statusColumn, ...selectedHeaders.slice(1), actionsColumn];
};

export const buttonArray = (handleAddAccount) => [
  {
    label: moduleSpecificMapping[getCurrentModule()]?.link,
    class: "v3 filled !px-4 !py-2",
    // link: Routes.CKTUNER_ACCOUNTS_ADD,
    handleClick: handleAddAccount,
    image: {
      className: "mr-[5px]",
      src: "/static/media/add-icon.05b8bf14bcc5cc3d17972ebef89ac5af.svg",
      alt: "add icon",
    },
  },
  false,
];
