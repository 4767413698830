import React, { useState } from "react";
import "./style.scss";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withRouter from "../../../../Common/WithRouter";
import actions from "../../../../../Actions/index";
import { ReactComponent as HomeOutlinedIcon } from "../../../../../Assets/icons/others/iconHomeV2.svg";
import DateRangePick from "../../../../CK-Lens/Common/dateRangePicker/dateRangePickerV2";

function Header(props) {
  const {
    breadcrumsData,
    headerName,

    // isLoading,
    showMonthDropdown = true,
    onDateChangeHandler,
    defaultPath,
    className,
    subHeaderEnabled = false,
    subHeader,
    isLoading,
  } = props;

  //  const datePickerMaxDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
  const datePickerMaxDate = moment().endOf("day").format("MMM DD, YYYY");
  const [datePickerToggler, setDatePickerToggler] = useState(false);
  const [startDatee, setStartDatee] = useState(
    moment(moment(datePickerMaxDate)).subtract(1, "days").startOf("day")
  );
  const [endDatee, setEndDatee] = useState(datePickerMaxDate);
  const [chosenRange, setChosenRange] = useState("1D");

  const handleDateChange = (start, end) => {
    setStartDatee(start);
    setEndDatee(end);
    if (typeof onDateChangeHandler === "function") {
      onDateChangeHandler(start, end);
    }
  };

  const handleRangeChange = (range) => {
    setChosenRange(range);
  };

  const resetSelectedDateRange = () => {
    setStartDatee(moment(moment(datePickerMaxDate)).subtract(1, "days"));
    setChosenRange("1D");
    setEndDatee(datePickerMaxDate);
  };

  const range = {
    // "30min": [
    //  moment(moment(datePickerMaxDate)).subtract(30, "minutes"),
    //  moment(moment(datePickerMaxDate)),
    // ],
    // "1hr": [
    //  moment(moment(datePickerMaxDate)).subtract(1, "hours"),
    //  moment(moment(datePickerMaxDate)),
    // ],
    // "12hr": [
    //  moment(moment(datePickerMaxDate)).subtract(12, "hours"),
    //  moment(moment(datePickerMaxDate)),
    // ],
    "1D": [
      moment(moment(datePickerMaxDate)).subtract(1, "days"),
      moment(moment(datePickerMaxDate)),
    ],
    "7D": [
      moment(moment(datePickerMaxDate)).subtract(7, "days"),
      moment(moment(datePickerMaxDate)),
    ],
    "1M": [
      moment(moment(datePickerMaxDate)).subtract(1, "months"),
      moment(moment(datePickerMaxDate)),
    ],
    "3M": [
      moment(moment(datePickerMaxDate)).subtract(3, "months"),
      moment(moment(datePickerMaxDate)),
    ],
  };

  return (
    <div className={`Component_header_wrapper ck-tuner-homepage-header ${className}`}>
      <div>
        {!!breadcrumsData.length && (
          <div className="breadcrums_wrapper">
            <Breadcrumbs separator={<ChevronRightIcon />}>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                to={`/dashboard/${defaultPath}`}
              >
                <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              </Link>
              {!!breadcrumsData.length &&
                breadcrumsData.map((item) => <p className="small-text-breadcrum">{item}</p>)}
            </Breadcrumbs>
          </div>
        )}
        <div className="page_header_container flex gap-1 items-center">
          <h3 className="!text-[22px] !font-semibold !text-[#151E33] m-0">{headerName}</h3>
          {subHeaderEnabled && (
            <p className="!p-0 !m-0 !text-[16px] font-medium text-[#5F5F5F]">{`(${subHeader})`}</p>
          )}
        </div>
      </div>
      <div className="flex gap-[9px] items-center Calendar_V2">
        <div id="dateRangePicker" className="utilization_banner_left_heading " key={chosenRange}>
          {showMonthDropdown ? (
            <div id="dateRangePicker" className="utilization_banner_left_heading ">
              <DateRangePick
                onDateChange={handleDateChange}
                startDate={startDatee}
                endDate={endDatee}
                toggleHandler={() => setDatePickerToggler(!datePickerToggler)}
                datePickerToggler={datePickerToggler}
                maxDate={moment(datePickerMaxDate)}
                minDate={moment(moment(datePickerMaxDate)).subtract(2, "months")}
                handleEventHandler={handleRangeChange}
                selectedRange={chosenRange}
                resetSelectedDateRange={resetSelectedDateRange}
                datePickerMaxDate={datePickerMaxDate}
                givenRange={range}
                // dateFormat="MMM DD, YYYY, hh:mm:ssA"
                dateFormat="MMM DD, YYYY"
                disabled={isLoading}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  payerDetails: state.HeaderReducer,
  accountDate: state.AccountDateReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
