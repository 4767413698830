const initState = {
  accounts: {
    accountEnvironmentType: "NON_PROD",
  },
};

export default function CKTunerReducer(state = initState, action = {}) {
  switch (action.type) {
    case "CHANGE_ACC_ENV_TYPE":
      return {
        ...state,
        accounts: {
          ...state.accounts,
          accountEnvironmentType: action.payload.accountEnvironmentType,
        },
      };
    default:
      return state;
  }
}
