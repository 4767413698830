import React from "react";
import TruncatedTextWithPopUp from "../../../Components/TruncatedTextWithPopUp";

const getEventHistoryHeader = () => [
  {
    Header: "Customer",
    accessor: "customerName",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Account",
    accessor: "accountId",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Module",
    accessor: "module",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Region",
    accessor: "region",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Service",
    accessor: "service",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
    headerClass: "!w-[150px] !min-w-[150px] !max-w-[150px] ",
  },
  {
    Header: "Resource ID",
    accessor: "resourceId",
    render: (item) => <TruncatedTextWithPopUp text={item?.resourceId} charLimit={30} />,
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
    headerClass: "!w-[220px] !min-w-[220px] !max-w-[220px] ",
  },
  {
    Header: "Event",
    accessor: "event",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Actionable at",
    accessor: "actionableAt",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Message",
    accessor: "errorMessage",
    render: (item) => <TruncatedTextWithPopUp text={item?.errorMessage} charLimit={30} />,
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "ScheduledTime",
    accessor: "scheduledTime",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "ActualEventTime",
    accessor: "actualEventTime",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Retry",
    accessor: "retryCount",
    sortedIcon: true,
    props: {
      defaultSelectedHeader: true,
    },
  },
];

export default getEventHistoryHeader;
