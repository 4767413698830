import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalAccountsPayload, sortHandler } from "../../../CostBreakup/costbreakupUtils";
import ExcelDownload from "../../../../../Common/ExcelDownload";
import Loader from "../../../../../Common/Loader";
import SingleLevelTable from "../../../CostExplorer/table";
import { getHeaderValues } from "../../cudosUtil";

function CudosTable(props) {
  const {
    selected,
    data = {},
    headers,
    className = "",
    footer,
    onExcelDownload,
    headerComponent = {},
    lensVersion = false,
    configuration: { isPollingExcel = false },
  } = props;

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState(data?.data?.data || {});
  const [ascending, setAscending] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const files = useSelector((state) => state.FileLoaderReducer?.files);
  const sortHandlerParent = (sortBy) => {
    const updatedData = sortHandler(tableData?.data, ascending, sortBy);
    setTableData({ ...tableData, data: updatedData });
    setAscending(!ascending);
  };

  useEffect(() => {
    if (data?.data) {
      setTableData(data?.data?.data);
    }
    setLoader(false);
  }, [data]);
  const disableDownload = () => {
    const { month, year } = getTotalAccountsPayload();
    const key = `${selected}_${year}_${month}`;
    return files[key]?.percentage < 100;
  };

  return (
    <div className={`table-wrapper bg-[#fff] ${className}`}>
      <div className="flex items-center table-header-top border-b-[1px] border-[#D9D9D9] h-[42px] px-[12px] justify-between">
        <div className="text-[16px] text-[#2B2B2B] font-bold">Top 20 Resources</div>
        <div className="ml-[16px]">
          <ExcelDownload
            downloadHandler={() =>
              onExcelDownload(
                selected,
                setExcelLoading,
                isPollingExcel
                  ? {
                      ...getHeaderValues(headerComponent),
                      dispatch,
                    }
                  : { ...getHeaderValues(headerComponent) }
              )
            }
            hasData={!tableData?.data?.length}
            excelLoading={isPollingExcel ? disableDownload(selected) || excelLoading : excelLoading}
          />
        </div>
      </div>
      <div className="amazon_relation_table_wrapper">
        {loader ? (
          <Loader />
        ) : (
          <SingleLevelTable
            headings={headers}
            data={tableData?.data || []}
            usageFooter={false}
            sortHandler={(sortBy) => sortHandlerParent(sortBy)}
            sortedIcon={false}
            GraphQuantity={false}
            message="No Data Available"
            currencyRequired
            footer={footer}
            lensVersion={lensVersion}
          />
        )}
      </div>
    </div>
  );
}

export default CudosTable;
