import React from "react";
import { Link } from "react-router-dom";
import actions from "../Actions";
import commonService from "../Services/commonService";
import store from "../store";
import Routes from "../Config/Routes";
import TUNER_MODULE from "../Components/CK-tuner/constants/module";
import { getSessionStorageUserData } from "./commonUtils";

export const isTunerIndirectCustomer = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  if (!moduleList) return true; // Handle case where moduleList is undefined

  // Convert TUNER_MODULE values into an array
  const tunerModuleValues = Object.values(TUNER_MODULE);

  // Define possible tunerDirectModules combinations dynamically
  const tunerDirectModules = tunerModuleValues.map((module) => [module, "Preference"]);

  // Extract module names from moduleList
  const receivedModuleNames = moduleList.map((module) => module.name);

  // Check if receivedModuleNames matches any valid tunerDirectModules combination
  const isDirect = tunerDirectModules.some(
    (validModules) =>
      validModules.every((module) => receivedModuleNames.includes(module)) &&
      receivedModuleNames.length === validModules.length
  );

  return !isDirect;
};

export const hasTuner = () => {
  const moduleList = store?.getState()?.sidebarReducer?.moduleList;
  return moduleList?.some((item) => Object.values(TUNER_MODULE).includes(item?.name));
};

export const truncatedText = (text, charLimit = 20) =>
  text?.length > charLimit ? `${text.substring(0, charLimit)}...` : text;

// BELOW FUNCTIONS ARE USED TO CONTROL TUNER FEATURES FROM TCG

export const getEnabledTunerFeaturesByKey = (key, type = 0) => {
  const tabsArray = store.getState()?.TunerFeaturesReducer?.enabledFeatures?.[key]?.enabledFeatures;
  return type ? tabsArray.map((item) => item?.featureName) : tabsArray;
};

export const isTunerFeatureEnabled = (key) =>
  [key] in (store.getState().TunerFeaturesReducer.enabledFeatures || {});

export const getDashboardFeaturesByGroup = (menuData) => {
  const transformed = {};

  menuData.forEach((item) => {
    transformed[item.id] = {
      featureName: item.displayName,
      enabledFeatures:
        item.childDashboards && item.childDashboards.length > 0
          ? item.childDashboards.map((child) => ({
              featureName: child.displayName,
              featureKey: child.id,
            }))
          : [],
    };
  });

  return transformed;
};

export const getEnabledTunerFeatures = async (type) => {
  try {
    const response = await commonService.tuner.getEnabledTunerFeatures(type, {
      overRideNameCheck: true,
    });
    const flattenedArray = getDashboardFeaturesByGroup(response?.data?.data);
    store.dispatch(
      actions.TunerFeaturesAction("TUNER_FEATURES", { enabledFeatures: flattenedArray })
    );
  } catch {
    //
  }
};

export function formatJSONString(jsonString) {
  try {
    // First, remove any comments from the JSON string
    // Remove single-line comments
    let cleanJson = jsonString.replace(/\/\/.*$/gm, "");
    // Remove multi-line comments
    cleanJson = cleanJson.replace(/\/\*[\s\S]*?\*\//gm, "");

    // Remove trailing commas
    cleanJson = cleanJson.replace(/,(\s*[}\]])/g, "$1");

    // Parse the cleaned JSON string
    const parsedJSON = JSON.parse(cleanJson);

    // Stringify with proper formatting (2 spaces indentation)
    const formattedJSON = JSON.stringify(parsedJSON, null, 3);

    return {
      success: true,
      formatted: formattedJSON,
      parsed: parsedJSON,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
      original: jsonString,
    };
  }
}

export function countUniqueAccountsByAccessType(data, accessType) {
  return Object.entries(data).reduce(
    (totalCount, [, access]) => (access[accessType] ? totalCount + 1 : totalCount),
    0
  );
}

export function formatErrorMessage(response, name) {
  const accountIds = Object.keys(response); // Extract account IDs
  //  const firstAccount = accountIds[0]; // First account ID
  //  const secondAccount = accountIds[1]; // Second account ID

  //  // Construct the message
  //  if (accountIds.length > 2) {
  //    const additionalCount = accountIds.length - 2;
  //    return `Issue detected in ${firstAccount}, ${secondAccount} and +${additionalCount} more, for more details please check accounts`;
  //  }
  return (
    <div>
      Issues are found in {accountIds.length > 1 ? accountIds.length : "a"}
      {accountIds.length > 2 ? "+" : ""} account
      {accountIds.length > 1 ? "s" : ""}, affecting {name} updates{" "}
      <Link className="v3-link" to={Routes.CKTUNER_ACCOUNTS}>
        {" "}
        see more
      </Link>
    </div>
  );
}

export const getCurrentMonth = () => {
  const month = [
    "(Jan)",
    "(Feb)",
    "(Mar)",
    "(Apr)",
    "(May)",
    "(Jun)",
    "(Jul)",
    "(Aug)",
    "(Sep)",
    "(Oct)",
    "(Nov)",
    "(Dec)",
  ];
  const d = new Date();
  return month[d.getMonth()];
};

export const doesDashboardExist = (id) => {
  const availableDashboards = store.getState().sidebarReducer.dashboardRoutes;
  return availableDashboards.some((item) => item.id === id);
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  switch (true) {
    case userAgent.indexOf("chrome") > -1:
      return "chrome";
    case userAgent.indexOf("firefox") > -1:
      return "firefox";
    case userAgent.indexOf("safari") > -1:
      return "safari";
    default:
      return "";
  }
};

export const updateRouteAfterOnboarding = async (displayName = "Accounts") => {
  const menu = store.getState()?.sidebarReducer?.dashboardRoutes;
  console.log(menu);

  const route = menu?.find((item) => item?.props?.displayName === displayName);
  window.location.href = `/dashboard/${route.path}`;
};

export const getCurrentModule = () => {
  const currentSession = getSessionStorageUserData();
  return currentSession?.tokens?.moduleName;
};
