import React from "react";
import { TabV2, TabsV2 } from "../../../Common/TabsV2";
import "./style.scss";

export default function TabWrapper(props) {
  const {
    isLoading,
    tabs,
    tabClicked,
    selected,
    tooltip = [],
    isRoundedCorner = false,
    tabLabel = "",
    wrapperClass = "",
  } = props;
  const tabList = tabs?.map((item) => item?.label);

  return (
    <TabsV2
      disabled={isLoading}
      isRoundedCorner={isRoundedCorner}
      wrapperClass={`cudos-tabs-wrapper ${wrapperClass}`}
      lensVersion={false}
    >
      {tabLabel !== "" && <label className="mr-[5px]">{tabLabel} :</label>}
      {tabs.map((item, index) => (
        <TabV2
          onClick={(e) => {
            tabClicked(item, index, e);
          }}
          selectedTab={selected}
          tab={item?.label}
          index={index}
          tabs={tabList}
          customClass="!h-[33px] flex items-center"
          tooltip={tooltip?.length ? tooltip?.[index] || "" : ""}
        >
          <div className="flex !h-[33px]!p-[9px 14px] justify-center items-center gap-2">
            <div className="">{item?.label}</div>
          </div>
        </TabV2>
      ))}
    </TabsV2>
  );
}
