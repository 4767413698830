import React from "react";
import "./style.scss";

function ButtonGroupV2(props) {
  const {
    active,
    ActiveTableData = [],
    handleReservations,
    ExpireTableData = [],
    firstButton,
    secondButton,
    buttonGrpCustomClass = "",
    isLoading,
    lensVersion = false,
    showCount = true,
  } = props;
  const renderButtonData = (btnType, data) => `${btnType} ${showCount ? `(${data.length})` : ""}`;
  return (
    <div
      className={`${lensVersion ? "lens_tab" : "btn_group btn_group_border"} ml-auto ${
        buttonGrpCustomClass || ""
      }`}
    >
      <button
        data-testid="active-reservations-button"
        type="button"
        className={`${active ? "active" : ""} group_btn`}
        id="active"
        onClick={() => {
          handleReservations("active");
        }}
        disabled={isLoading}
      >
        {renderButtonData(firstButton, ActiveTableData)}
      </button>
      <button
        data-testid="expired-reservations-button"
        type="button"
        className={`${active ? "" : "active"} group_btn`}
        id="inActive"
        onClick={() => {
          handleReservations("inActive");
        }}
        disabled={isLoading}
      >
        {renderButtonData(secondButton, ExpireTableData)}
      </button>
    </div>
  );
}

export default ButtonGroupV2;
