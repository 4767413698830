import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import Loader from "../Loader";
import parseInitialValue from "./helper";

function CodeEditor(props) {
  const {
    name,
    label,
    labelClass,
    editorHeight = "200px",
    handleChange,
    error,
    initialValue,
    parentClass,
    readOnly = false,
  } = props;

  const [editorValue, setEditorValue] = useState(parseInitialValue(initialValue));

  const handleEditorChange = (value) => {
    setEditorValue(value);
    try {
      JSON.parse(value);
      handleChange({ target: { name, value } });
    } catch (e) {
      console.error("Invalid JSON:", e);
    }
  };

  useEffect(() => {
    setEditorValue(parseInitialValue(initialValue));
  }, [initialValue]);

  return (
    <div className={`flex-1 min-w-0 w-full ${parentClass}`}>
      <label className={`${labelClass || ""} text-[12px] text-black block`} htmlFor={name}>
        {label}
      </label>
      <div className="w-full flex-1 min-w-0">
        <Editor
          className="w-full code-editor"
          height={editorHeight}
          defaultLanguage="json"
          value={editorValue}
          onChange={handleEditorChange}
          options={{
            cursorStyle: "line",
            formatOnPaste: true,
            formatOnType: true,
            wordWrap: "on",
            scrollBeyondLastLine: false,
            minimap: {
              enabled: false,
            },
            automaticLayout: true,
            scrollbar: {
              vertical: "auto",
              horizontal: "auto",
            },
            readOnly,
          }}
          loading={<Loader />}
          onMount={(editor) => {
            editor.getAction("editor.action.formatDocument").run();
            setTimeout(() => {
              editor.layout();
            }, 100);
          }}
        />
      </div>
      {error && <p className="error_message pagination">{error}</p>}
    </div>
  );
}

export default CodeEditor;
