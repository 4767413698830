/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SELECTED_DASHBOARD_ENUM } from "./configuration";
import { dashboardAPICalls, updateRoutes } from "../helper/utils";
import { getTabs } from "../../Reservations/ReservedInstances/helper/constants";

const useCudos = ({ pathname, dashboardKey }) => {
  // State for dashboard name
  const url = new URLSearchParams(window.location.search);
  const selectedServiceFromUrl = url.get("selected") || undefined;
  const pathArray = window.location.pathname?.split("/");
  const dashboardName = pathArray[pathArray.length - 1];
  const tabs = getTabs(dashboardKey)?.map((item) => item.title);
  const getInitDasboardSelected = () => {
    if (tabs.length) {
      if (selectedServiceFromUrl && tabs?.findIndex((tab) => tab === selectedServiceFromUrl) !== -1)
        return selectedServiceFromUrl;
      return tabs[0];
    }
    return dashboardName;
  };
  const navigate = useNavigate();
  const accountDate = useSelector((state) => state.AccountDateReducer) || {};
  const [isLoading, setIsLoading] = useState(true);
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);
  const [route, setRoute] = useState(pathname);
  const [activeGranularity, setActiveGranularity] = useState("Monthly");
  const [activeChartType, setActiveChartType] = useState("stackedcolumn2d");
  const [selected, setSelected] = useState(
    SELECTED_DASHBOARD_ENUM(activeChartType)[getInitDasboardSelected()]?.name
  );
  const [dashboardConfig, setDashboardConfig] = useState(
    SELECTED_DASHBOARD_ENUM(activeChartType)[getInitDasboardSelected()]
  );
  const [granularityLoader, setGranularityLoader] = useState(false);

  const [isDropdownChanged, setIsDropdownChanged] = useState(false);

  const apiHandler = async (
    granularity = "MONTHLY",
    isGranularityUpdated = false,
    dashboardConfiguration = {}
  ) => {
    if (isGranularityUpdated) setGranularityLoader(true);
    else setIsLoading(true);

    try {
      const configObj = cloneDeep(dashboardConfiguration?.config);
      const configHeader = cloneDeep(dashboardConfiguration?.header);
      if (configObj) {
        const parameters = {
          config: configObj,
          isGranularityUpdated,
          dashboardConfiguration,
          granularity,
        };

        if (configHeader) {
          const headerPayload = {};
          const headerPayloadComponentList =
            Object?.keys(configHeader)?.filter((obj) => configHeader[obj]?.payloadKey) || [];
          headerPayloadComponentList?.forEach((obj) => {
            headerPayload[configHeader[obj]?.payloadKey] =
              configHeader[obj]?.data?.find((item) => item?.label === configHeader[obj]?.selected)
                ?.value || null;
          });
          parameters.headerPayload = headerPayload;
        }

        const callsObj = await dashboardAPICalls(parameters);

        setDashboardConfig({ ...dashboardConfiguration, config: callsObj?.config });
      }
    } finally {
      setIsLoading(false);
      setGranularityLoader(false);
    }
  };

  const callHeaderService = async (config) => {
    setIsHeaderLoading(true);
    const configHeader = config?.header;
    const apiArray = [];
    Object?.keys?.(configHeader)?.forEach((ele) => {
      apiArray?.push(
        configHeader?.[ele]?.serviceCall({
          selected: config?.name,
          name: ele,
        })
      );
    });
    const response = await Promise.all(apiArray);
    response.forEach((res) => {
      if (configHeader?.[res?.name]) {
        configHeader[res.name].data = res?.data;
        if (Array.isArray(res?.data)) {
          configHeader[res.name].selected =
            configHeader?.[res.name]?.selected || res?.data?.[0]?.label;
          configHeader[res.name].tooltip = res?.data?.map((obj) => obj?.title) || [];
        } else {
          configHeader[res.name].selected = selected;
        }
      }
    });
    setDashboardConfig({ ...dashboardConfig, header: configHeader });
    setIsHeaderLoading(false);
  };

  const checkForHeaders = async (config) => {
    const listOfHeaderComponets = Object?.keys(config?.header || {});
    if (
      listOfHeaderComponets?.length &&
      !listOfHeaderComponets?.every((obj) => config?.header?.[obj]?.selected)
    ) {
      setIsLoading(true);
      await callHeaderService(config);
    }
  };

  const tabClicked = async (selectedTab, index, e) => {
    if (!e.ctrlKey && selectedTab !== selected) {
      setSelected(selectedTab);
      const selectedDashboard = cloneDeep(SELECTED_DASHBOARD_ENUM(activeChartType)[selectedTab]);
      setDashboardConfig(selectedDashboard);
      const data = new URLSearchParams(window.location.search);
      const query = `month=${data.get("month")}&year=${data.get("year")}`;
      const currentRoute = `${pathname}?${query}&selected=${selectedTab}`;
      setRoute(currentRoute);
      await checkForHeaders(selectedDashboard);
      await apiHandler(activeGranularity, false, selectedDashboard);
    }
  };

  const headerhandler = (selectedValue, index, e, selectedHeader) => {
    if (!e.ctrlKey && selectedValue !== selected) {
      dashboardConfig.header[selectedHeader].selected = selectedValue?.label;
      apiHandler(activeGranularity, false, dashboardConfig);
    }
  };

  const onChartTypeChange = async (chartType) => {
    setGranularityLoader(true);
    const updatedObjects = [];
    Object?.keys(dashboardConfig?.config)?.forEach((ele) => {
      if (dashboardConfig.config[ele]?.isGranularityApplied) {
        updatedObjects?.push({ ...dashboardConfig.config[ele], type: chartType });
      }
    });
    const dashboardConfigClone = cloneDeep(dashboardConfig.config);
    updatedObjects?.forEach((ele) => {
      dashboardConfigClone[ele.name] = ele;
    });
    setDashboardConfig({ ...dashboardConfig, config: dashboardConfigClone });
    setActiveChartType(chartType);
    setTimeout(() => setGranularityLoader(false), 1000);
  };

  const onGranulityChange = (item) => {
    apiHandler(item?.toUpperCase(), true, dashboardConfig);
    setActiveGranularity(item);
  };

  const updateConfig = (name, updatedValue) => {
    const data = cloneDeep(dashboardConfig?.config);
    data[name] = updatedValue;
    setDashboardConfig({ ...dashboardConfig, config: data });
  };

  const initCall = async () => {
    const selectedDashbaord = getInitDasboardSelected();
    const config = cloneDeep(
      isDropdownChanged ? dashboardConfig : SELECTED_DASHBOARD_ENUM()[selectedDashbaord]
    );
    setIsLoading(true);
    setSelected(selectedDashbaord);
    if (!isDropdownChanged) {
      setActiveGranularity("Monthly");
      setActiveChartType("stackedcolumn2d");
    }
    setDashboardConfig(config);
    await checkForHeaders(config);
    apiHandler(!isDropdownChanged ? "Monthly" : activeGranularity, false, config);
    setIsDropdownChanged(false);
  };

  useEffect(() => {
    const currentRoute = updateRoutes(tabs, accountDate, pathname, navigate);
    if (url.get("month") && url.get("year") && currentRoute !== route) {
      setRoute(currentRoute);
      initCall();
    }
  }, [url.get("month"), url.get("year")]);

  const selectedDateProp = () => {
    setIsDropdownChanged(true);
  };

  return {
    isLoading,
    activeGranularity,
    dashboardConfig: cloneDeep(dashboardConfig?.config),
    selectedService: dashboardName,
    onGranulityChange,
    activeChartType,
    onChartTypeChange,
    selected,
    setSelected,
    tabClicked,
    granularityLoader,
    tabs,
    accountDate,
    updateConfig,
    apiKey: dashboardConfig?.name,
    selectedDateProp,
    showGranularitySidebar:
      SELECTED_DASHBOARD_ENUM(activeChartType)?.[selected]?.showGranularitySidebar,
    headerComponent: dashboardConfig?.header || {},
    headerhandler,
    isHeaderLoading,
  };
};
export default useCudos;
