import { getPayload, getTotalAccountsPayload } from "../../CostBreakup/costbreakupUtils";
import { manipulateApiResponse } from "../Database/util";
import commonService from "../../../../../Services/commonService";
import {
  getHeaderPayload,
  getMonthCardsData,
  getSelectedValue,
  manipulateCPUMemoryCardData,
  manipulateSummaryCardsData,
} from "../helper/utils";
import { RICoverageCost } from "../Database/constant";
import { downloadExcel } from "../../../../../Utils/commonUtils";
import { UNUSED_COST_CARD } from "../helper/constant";
import store from "../../../../../store";

export const getSummaryCardsData = async ({ selected, name = "", header = {} }) => {
  try {
    const payload = getTotalAccountsPayload({ service: selected?.toUpperCase() });
    getHeaderPayload(payload, header);
    const res = await commonService.getSummaryCards(payload);
    return {
      name,
      data: manipulateSummaryCardsData(res?.data?.data, selected) || [],
    };
  } catch (error) {
    return error;
  }
};

export const getDataTransferSummaryCards = async ({ selected, name = "" }) => {
  try {
    const res = await commonService.getDataSummarySummaryCards(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return {
      name,
      data: res?.data?.data || {},
    };
  } catch (error) {
    return error;
  }
};

export const getCudosDatabaseCards = async ({
  selected,
  name = "",
  manipulateCardsData = getMonthCardsData,
}) => {
  try {
    const cudosCardsData = await commonService.getCudosDatabaseCards(
      getPayload(selected?.toUpperCase())
    );
    return { name, data: manipulateCardsData(cudosCardsData?.data?.data) };
  } catch (error) {
    return error;
  }
};

export const getRecommendationSummary = async ({ selected, name = "" }) => {
  try {
    const cudosCardsData = await commonService.getRecommendationSummary(
      getPayload(selected?.toUpperCase())
    );
    return { name, data: cudosCardsData?.data?.data };
  } catch (error) {
    return error;
  }
};

export const getHighlightCardData = async ({ selected, name = "" }) => {
  try {
    const payload = getPayload(selected?.toUpperCase());
    const cudosCardsData = await commonService.getHighlightCardData(payload);

    return {
      name,
      data:
        manipulateSummaryCardsData(
          cudosCardsData?.data?.data,
          selected?.toUpperCase() === "RDS" ? "rds_highlights" : selected?.toUpperCase()
        ) || [],
    };
  } catch (error) {
    return error;
  }
};

export const getCostByRegion = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostRegion(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByInstanceType = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(graphPayload, header);
    const instanceTypeData = await commonService.getCostByInstanceTypeDatabase(graphPayload);
    const data = manipulateApiResponse(instanceTypeData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByCluster = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostCluster(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByNameSpace = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByNameSpace(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByPurchase = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByPurchase(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByUsage = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByUsageType(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByStorageType = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByAccountsData = await commonService.getCostByStorageType(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTop20InstanceTableData = async ({ selected, name = "", header = {} }) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
    });
    getHeaderPayload(payload, header);
    const res = await commonService.getTop20CostUsage(payload);
    return { name, data: res };
  } catch (error) {
    return error;
  }
};

export const getDataTransferCostBreakdown = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    const { data: { data: tabArr = [] } = {} } = await commonService.getTopResourcesTabs(
      getPayload(selected?.toUpperCase())
    );

    const payload = getTotalAccountsPayload({
      service:
        tabArr?.find((_) => _?.value === configuration?.selectedTab)?.value || tabArr?.[0]?.value,
      reportGranularity,
    });
    let resp;
    if (tabArr?.length) {
      resp = await commonService.getTopResources(payload);
    }

    return { name, data: resp || {}, tabArr };
  } catch (error) {
    return error;
  }
};

export const exportCostByCluster = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByCluster(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByNamespace = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTotalCostByNamespace(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByRegion = async (
  selected = "",
  reportGranularity = "MONTHLY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportTotalCostByRegion(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByInstanceType = async (
  selected = "",
  reportGranularity = "MONTHLY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportCostByInstanceType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByPurchaseType = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByPurchaseTypeEKS(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByUsageType = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByUsageTypeEKS(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByStorageType = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByStorageTypeEKS(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTop20CostUsageTableData = async (
  selected = "",
  setExcelLoading = () => {},
  config = {}
) => {
  setExcelLoading(true);
  const payload = { ...getTotalAccountsPayload({ service: selected?.toUpperCase() }), ...config };
  try {
    const res = await commonService.exportTop20CostUsageTableData(payload);
    downloadExcel(res);
    return res;
  } catch (error) {
    return error;
  } finally {
    setExcelLoading(false);
  }
};

// export const EKS_SERVICE_CONSTANT = {
//   costByClusters: (service, granularity) => getCostByCluster(service, granularity),
//   costByNamespace: (service, granularity) => getCostByNameSpace(service, granularity),
//   costByRegions: (service, granularity) => getCostRegion(service, granularity),
//   costByInstanceType: (service, granularity) => getCostByInstanceType(service, granularity),
//   costByPurchaseType: (service, granularity) => getCostByPurchase(service, granularity),
// };

export const getCostEngine = async ({
  selected = "",
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const engineData = await commonService.getCostEngine(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    const data = manipulateApiResponse(engineData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByEngine = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    service: selected?.toUpperCase(),
    reportGranularity,
  });
  try {
    const res = await commonService.exportTotalCostByEngine(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getTotalAccountsData = async ({
  selected,
  name = "",
  reportGranularity = "DAILY",
  header = {},
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity: reportGranularity?.toUpperCase(),
      service: selected?.toUpperCase(),
    });
    getHeaderPayload(graphPayload, header);
    const costByAccountsData = await commonService.getTotalAccountsData(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByAccount = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportTotalCostByAccount(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByPricingType = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportCostByPricingType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostByService = async (
  selected = "",
  reportGranularity = "DAILY",
  selectedTab = ""
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    selected: selectedTab,
  });
  try {
    const res = await commonService.exportCostByService(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getRICoverageData = async ({ selected = "", name = "" }) => {
  try {
    const res = await commonService.getRiCoverageUsage(
      getTotalAccountsPayload({ service: selected?.toUpperCase() })
    );
    return {
      name,
      data: {
        stackedGraphData: res?.data?.data?.coverageList,
        graphData: {
          ...RICoverageCost,
          graphData: {
            data: [
              {
                label: "Reservation Covered Cost",
                value: res?.data?.data?.totalRdsReservationCost,
              },
              {
                label: "Remaining OnDemand Cost",
                value: res?.data?.data?.totalRdsOnDemandCost,
              },
            ],
          },
        },
        riCoverageData: res?.data?.data,
      },
    };
  } catch (error) {
    return error;
  }
};

export const getCostByPricingType = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByPricingType(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

/* Data Transfer APIs */
export const getCostByTransferRegion = async ({ name = "" }) => {
  try {
    const graphPayload = getTotalAccountsPayload();
    const costByTranferRegion = await commonService.GetCostByRegion(graphPayload);
    const data = manipulateApiResponse(costByTranferRegion)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostOfTypeByService = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    let servicesTabs;
    if (!configuration?.tabArr?.length) {
      servicesTabs = await commonService.GetServicesByType(getTotalAccountsPayload());
    }
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
      selected:
        configuration?.selectedTab ||
        configuration?.tabArr?.[0]?.value ||
        servicesTabs?.data?.data?.[0]?.value,
    });
    const costTypeService = await commonService.GetCostOfTypeByService(graphPayload);
    const data = manipulateApiResponse(costTypeService)?.data?.data;
    return {
      name,
      data,
      tabArr: configuration?.tabArr?.length ? configuration?.tabArr : servicesTabs?.data?.data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getCostByType = async ({ name = "" }) => {
  try {
    const graphPayload = getTotalAccountsPayload();
    const costByType = await commonService.GetCostByType(graphPayload);
    const data = manipulateApiResponse(costByType)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getCostByService = async ({ selected, name = "", reportGranularity = "MONTHLY" }) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByservice = await commonService.getCostByService(graphPayload);
    const data = manipulateApiResponse(costByservice)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTopBucketByOperationCost = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    let servicesTabs;
    if (!configuration?.tabArr?.length) {
      servicesTabs = await commonService.GetCudosOperationTypes(getTotalAccountsPayload());
    }
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
      selected:
        configuration?.selectedTab ||
        configuration?.tabArr?.[0]?.value ||
        servicesTabs?.data?.data?.[0]?.value,
    });
    const costServiceType = await commonService.GetTopS3OperationCostByType(graphPayload);
    const data = manipulateApiResponse(costServiceType)?.data?.data;
    return {
      name,
      data,
      tabArr: configuration?.tabArr?.length ? configuration?.tabArr : servicesTabs?.data?.data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTopBucketByStorageCost = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    let servicesTabs;
    if (!configuration?.tabArr?.length) {
      servicesTabs = await commonService.GetCudosStorageTypes(getTotalAccountsPayload());
    }
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
      selected:
        configuration?.selectedTab ||
        configuration?.tabArr?.[0]?.value ||
        servicesTabs?.data?.data?.[0]?.value,
    });
    const costServiceType = await commonService.GetTopS3StorageCostByType(graphPayload);
    const data = manipulateApiResponse(costServiceType)?.data?.data;
    return {
      name,
      data,
      tabArr: configuration?.tabArr?.length ? configuration?.tabArr : servicesTabs?.data?.data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getTopBucketByCost = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByservice = await commonService.getTopBucketByCost(graphPayload);
    const data = manipulateApiResponse(costByservice)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getCostByInterAZ = async ({ selected, name = "", reportGranularity = "MONTHLY" }) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByservice = await commonService.getCostByInterAZ(graphPayload);
    const data = manipulateApiResponse(costByservice)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getCostByInternetOut = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
}) => {
  try {
    const graphPayload = getTotalAccountsPayload({
      reportGranularity,
      service: selected?.toUpperCase(),
    });
    const costByservice = await commonService.getCostByInternetOut(graphPayload);
    const data = manipulateApiResponse(costByservice)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostOfServiceByType = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    let servicesTabs;
    if (!configuration?.tabArr?.length) {
      servicesTabs = await commonService.GetTypeByservices(getTotalAccountsPayload());
    }
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
      selected:
        configuration?.selectedTab ||
        configuration?.tabArr?.[0]?.value ||
        servicesTabs?.data?.data?.[0]?.value,
    });
    const costServiceType = await commonService.GetCostOfServiceByType(graphPayload);
    const data = manipulateApiResponse(costServiceType)?.data?.data;
    return {
      name,
      data,
      tabArr: configuration?.tabArr?.length ? configuration?.tabArr : servicesTabs?.data?.data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getRegionToRegion = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  configuration = {},
}) => {
  try {
    let options = await commonService.getRegions(
      getTotalAccountsPayload({ service: selected?.toUpperCase(), reportGranularity })
    );
    options = options?.data?.data?.map((item) => ({ label: item, value: item }));
    const selectedRegion = getSelectedValue(configuration?.selectedOption, options)?.value;
    const graphPayload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
      region: selectedRegion,
    });
    let data = null;
    if (selectedRegion) {
      const costServiceType = await commonService.getCostByRegionToRegion(graphPayload);
      data = manipulateApiResponse(costServiceType)?.data?.data;
    }
    return {
      name,
      data,
      options,
      selectedOption: selectedRegion,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};
export const getCostAccountsData = async ({ name = "" }) => {
  try {
    const graphPayload = getTotalAccountsPayload();
    const costByAccountsData = await commonService.GetCostByAccountData(graphPayload);
    const data = manipulateApiResponse(costByAccountsData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostOfTypeByService = async (
  selected = "",
  reportGranularity = "DAILY",
  { selectedTab = "" } = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    selected: selectedTab,
  });
  try {
    const res = await commonService.exportCostOfTypeByService(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportCostOfServiceByType = async (
  selected = "",
  reportGranularity = "DAILY",
  { selectedTab = "" } = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    selected: selectedTab,
  });
  try {
    const res = await commonService.exportCostOfServiceByType(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTopBucketByStorageCost = async (
  selected = "",
  reportGranularity = "DAILY",
  { selectedTab = "" } = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    selected: selectedTab,
  });
  try {
    const res = await commonService.exportTopBucketByStorageCost(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTopBucketByOperationCost = async (
  selected = "",
  reportGranularity = "DAILY",
  { selectedTab = "" } = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    selected: selectedTab,
  });
  try {
    const res = await commonService.exportTopBucketByOperationCost(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTopBucketByCost = async (selected = "", reportGranularity = "DAILY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportTopBucketByCost(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportByResources = async (selected, setExcelLoading = () => {}) => {
  setExcelLoading(true);

  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
    });
    const res = await commonService.exportByResources(payload);
    downloadExcel(res);
    return res;
  } catch (error) {
    return error;
  } finally {
    setExcelLoading(false);
  }
};

export const exportS3CostBreakUp = async (selected, setExcelLoading = () => {}) => {
  setExcelLoading(true);

  try {
    const payload = getTotalAccountsPayload();
    const res = await commonService.exportS3CostBreakUp(payload);
    downloadExcel(res);
    return res;
  } catch (error) {
    return error;
  } finally {
    setExcelLoading(false);
  }
};

export const getS3TableData = async ({ name = "" }) => {
  try {
    const payload = getTotalAccountsPayload();

    delete payload.numberOfRows;
    const response = await commonService.getS3CostBreakDown(payload);
    const {
      data: { data },
    } = response;
    const cardData = [];
    Object.keys(data?.columnData).forEach((individualOption) => {
      if (data?.columnData?.[individualOption] !== null) {
        cardData.push({ total: data?.columnData?.[individualOption], column: individualOption });
      }
    });
    return {
      name,
      data: {
        tableData: data?.costData,
        cardData,
        cardsKeyArr: Object.keys(data?.columnData),
      },
    };
  } catch (error) {
    return error;
  }
};

// s3

export const getCostByOperation = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByOperation(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByRegionToRegion = async (
  selected = "",
  reportGranularity = "MONTHLY",
  { selectedOption = "" } = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    region: selectedOption,
  });
  try {
    const res = await commonService.exportCostByRegionToRegion(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};
export const exportCostByInternetOut = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByInternetOut(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};
export const exportCostByInterAZ = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByInterAZ(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};
export const exportCostByOperation = async (selected = "", reportGranularity = "MONTHLY") => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
  });
  try {
    const res = await commonService.exportCostByOperation(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};
// dynamodbChanges API

export const getDynamoDBDataExport = async (selected) => {
  try {
    const payload = getPayload(selected?.toUpperCase());
    delete payload?.numberOfRows;
    const res = await commonService.getDatabaseDataExport(payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const getDynamoDBTableData = async ({ selected, name = "" }) => {
  try {
    const res = await commonService.getDatabaseData(getPayload(selected?.toUpperCase()));
    return {
      name,
      data: res?.data?.data,
    };
  } catch (error) {
    return error;
  }
};

// EC2

export const getTabData = async ({ selected, name = "" }) => {
  try {
    const res = await commonService.getCostType({ service: selected?.toUpperCase() });
    return {
      name,
      data: res?.data?.data,
    };
  } catch (error) {
    return error;
  }
};

export const getCostByOperatingSystem = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByOperatingSystem(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const getCostByProcessor = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByProcessor(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByOperatingSystem = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportTotalCostByOperatingSystem(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const exportTotalCostByProcessor = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportTotalCostByProcessor(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getOnDemandSplitCard = async ({ selected, name = "" }) => {
  try {
    const res = await commonService.getOnDemandSplit(getPayload(selected?.toUpperCase()));
    return {
      name,
      data: res?.data?.data || {},
    };
  } catch (error) {
    return error;
  }
};

export const getUnusedRiSpCost = async ({ selected, name = "" }) => {
  try {
    const res = await commonService.getUnusedRiSpCost(getPayload(selected?.toUpperCase()));
    return {
      name,
      data: {
        ...UNUSED_COST_CARD,
        value:
          res?.data?.data?.UnusedRiSpCost || res?.data?.data?.UnusedRiSpCost === 0
            ? res?.data?.data?.UnusedRiSpCost
            : UNUSED_COST_CARD?.value,
      },
    };
  } catch (error) {
    return error;
  }
};

// Lambda

export const getCostByProcessorArchitecture = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByProcessorArchitecture(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportTotalCostByProcessorArchitecture = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportTotalCostByProcessorArchitecture(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

// Fargate

export const getCostByCPUAndMemory = async ({
  selected,
  name = "",
  reportGranularity = "MONTHLY",
  header = {},
}) => {
  try {
    const payload = getTotalAccountsPayload({
      service: selected?.toUpperCase(),
      reportGranularity,
    });
    getHeaderPayload(payload, header);
    const regionData = await commonService.getCostByCPUAndMemory(payload);
    const data = manipulateApiResponse(regionData)?.data?.data;
    return {
      name,
      data,
      showLabels: "1",
    };
  } catch (error) {
    return error;
  }
};

export const exportCostByCPUAndMemory = async (
  selected = "",
  reportGranularity = "DAILY",
  config = {}
) => {
  const graphPayload = getTotalAccountsPayload({
    reportGranularity,
    service: selected?.toUpperCase(),
    ...config,
  });
  try {
    const res = await commonService.exportCostByCPUAndMemory(graphPayload);
    return res;
  } catch (err) {
    return err;
  }
};

export const getCPUAndMemoryCardData = async ({ selected, name = "", header = {} }) => {
  try {
    const payload = getTotalAccountsPayload({ service: selected?.toUpperCase() });
    getHeaderPayload(payload, header);
    const res = await commonService.getCPUAndMemoryCard(payload);
    return {
      name,
      data: manipulateCPUMemoryCardData(res?.data?.data, selected?.toUpperCase()) || [],
    };
  } catch (error) {
    return error;
  }
};

export const exportPollingTop20CostUsageTableData = async (
  selected = "",
  setExcelLoading = () => {},
  config = {}
) => {
  setExcelLoading(true);
  try {
    const { dispatch } = config;
    const payload = { ...getTotalAccountsPayload({ service: selected?.toUpperCase() }), ...config };
    delete payload.dispatch;
    const key = `${payload.service}_${payload.year}_${payload.month}`;
    const files = store?.getState()?.FileLoaderReducer?.files;
    if (files[key]) {
      dispatch({ type: "DELETE_FILE", payload: key });
    }
    const response = await commonService.exportPollingTop20CostUsageTableData(payload);
    const id = response?.data?.data?.id || "";
    const fileName = response?.data?.data?.fileName;
    dispatch({
      type: "UPDATE_FILES",
      payload: {
        ...payload,
        fileName,
        percentage: 0,
        id,
        startTime: Date.now(),
      },
    });
    return response;
  } catch (error) {
    return error;
  } finally {
    setExcelLoading(false);
  }
};
